import React, { useEffect } from 'react'
import Hide from './Hide'
import { Typography } from '@material-ui/core'
import { getFromLocalStorage } from 'utils/localStorage'

function Script({ c, setAnswers, answers, inWork, selectedAnswers, setSelectedAnswers, pensId }) {

    useEffect(() => {
        const savedData = getFromLocalStorage(pensId, true)
        if(savedData){
            setSelectedAnswers(savedData?.selectedAnswers || [])
        }
    },[])

    return (
        <>
            <Typography className={c.sequenceQuestionsHeading}>
                Соблюдайте следующую последовательность вопросов:
            </Typography>

            {/*Выводим вопрос с ответами*/}
            {
                !!Object.keys(answers).length && selectedAnswers
                    ? answers.questions.map((item, index) => {
                        return (
                            <Hide
                                ind={index}
                                key={item.id}
                                otvety={item.answers}
                                inWork={inWork}
                                setAnswers={setAnswers}
                                answers={answers}
                                question={item.title}
                                subText={item.text}
                                keyToSave={pensId}
                                c={c}
                                selectedAnswers={selectedAnswers}
                                setSelectedAnswers={setSelectedAnswers}
                            />
                        )
                    })
                    : 'Не удалось найти опрос для данного ОМСУ и гражданина'
            }

            <Typography style={{ textAlign: 'center', marginTop: '14px' }} >
                {answers.text_goodbye}
            </Typography>

        </>
    )
}

export default Script
