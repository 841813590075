import { toast } from 'react-toastify'

export const errorToast = (message, uniqueId) => {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
        toastId: uniqueId ? uniqueId : null
    });
};

