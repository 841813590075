import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    wrapHeader: {
        paddingTop: 33,
    },
    headers: {
        display: 'flex',
        marginBottom: 15,
    },
    vp_headingPage: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 36,
        lineHeight: '43px',
        color: theme.black,
    },
    fieldsContent: {
        display: 'flex'
    },
    nameAndQuestions: {
        width: '40%'
    },
    typeAndGeography: {
        width: '60%'
    },
    containerEditScript: {
        paddingBottom: 50,
    },
    loadPage: {
        height: 'calc(100vh - 77px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));