import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    sectionField: {
        marginBottom: 15,
    },
    wrapSelect: {
        width: '100%',
        marginBottom: 15,
    },
    headingNameAndQuestions: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '24px',
        color: '#333333',
    },
    label: {
        display: 'inline-block',
        fontFamily: 'Rubik',
        fontSize: 14,
        lineHeight: '17px',
        color: '#333333',
        marginBottom: 10
    },
    labelBold: {
        fontWeight: 500,
    },
    add: {
        display: 'inline-flex',
        alignItems: 'center',
        fontFamily: 'Rubik',
        fontSize: 14,
        color: '#1056E3',
        marginBottom: 18,
        marginRight: 20,
        cursor: 'pointer',

        '& img': {
            marginRight: 14,
        }
    },
    buttonCreate: {
        width: 200,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#53BB63',
        fontSize: 14,
        color: '#fff',
        transition: 'all .3s',

        '&:hover': {
            background: '#61DB73',
        }
    },
    buttonClose: {
        width: 200,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#E41D37',
        fontSize: 14,
        color: '#fff',
        marginRight: 20,
        transition: 'all .3s',

        '&:hover': {
            background: '#FF556F',
        }
    },
    error: {
        color: 'red'
    },
    buttonsQA: {
        display: 'flex',
        alignItems: 'center'
    }
}));