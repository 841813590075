import React                            from 'react';
import { useHistory }                   from 'react-router-dom';
import FieldFilter                      from '../FieldFilter/FieldFilter';
import Button                           from '@material-ui/core/Button';
import SelecForFilter                   from '../../Components/SelectForFilter/SelectForFilter';
import AnimateLoader                    from '../../css/svg/AnimateLoader';
import plus                             from '../../css/img/plus.svg';
import { useStyles }                    from './style';

const NameAndQuestions = ({ state, handleBlur, handleBlurMulti, handleValueSelect, setState, createOrEditScript, load, errors, editComponent = false, citizenTypesOptions }) => {

    const classes = useStyles();
    const history = useHistory();

    const addAnswer = (index) => () => { //добавление ответа
        const questions = [...state.questions];

        questions[index].answers.push({title: '', order_by: questions[index].answers.length + 1});
        setState({
            ...state,
            questions
        })
    };

    const addQuestion = () => { // добавление вопроса
        setState({
            ...state,
            questions: [...state.questions, {
                order_by: state.questions.length + 1,
                title: '',
                text: '',
                answers: [{title: '', order_by: 1}]
            }]
        })
    };

    return (
        <div>
            <h3 style={{marginRight: 8}} className={classes.headingNameAndQuestions}>Тип скрипта:</h3>
            <div className={classes.wrapSelect}>
                <SelecForFilter
                    errorData={errors.type_id && !state.type_id}
                    readonly
                    noLabel
                    value={state.type_id}
                    options={citizenTypesOptions}
                    handleValueSelect={handleValueSelect('type_id')}
                />
                { errors.type_id && !state.type_id ?
                    <small className={classes.error}>{errors.type_id}</small> : null }
            </div>

            <h3 className={classes.headingNameAndQuestions}>Наименование скрипта:</h3>
            <div className={classes.sectionField} style={{marginBottom: 8}}>
                <FieldFilter
                    redBorder={Boolean(errors.nameScript) && !state.nameScript}
                    key={state.nameScript}
                    value={state.nameScript}
                    onBlur={handleBlur}
                    name={'nameScript'}
                    label={'Название'}
                />
                { errors.nameScript && !state.nameScript ?
                    <small className={classes.error}>{ errors.nameScript }</small> : null }
            </div>

            <div className={classes.sectionField}>
                <FieldFilter
                    redBorder={Boolean(errors.textScript) && !state.textScript}
                    key={state.textScript}
                    value={state.textScript}
                    onBlur={handleBlur}
                    multiline
                    name={'textScript'}
                    label={'Скрипт разговора'}
                />
                { errors.textScript && !state.textScript ?
                    <small className={classes.error}>{ errors.textScript }</small> : null }
            </div>

            <h3 style={{marginTop: 15}} className={classes.headingNameAndQuestions}>Вопросы:</h3>

            {
                state.questions.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className={classes.sectionField}>
                                <span className={`${classes.label} ${classes.labelBold}`}>Вопрос {index + 1}</span>
                                <FieldFilter
                                    placeholder={'Введите текст...'}
                                    key={item.title}
                                    value={item.title}
                                    onBlur={handleBlurMulti(index)}
                                    name={'title'}
                                    noLabel
                                />
                            </div>

                            <div className={classes.sectionField}>
                                <span className={classes.label}>Скрипт ответа на вопрос</span>
                                <FieldFilter
                                    placeholder={'Введите текст...'}
                                    key={item.text}
                                    value={item.text}
                                    onBlur={handleBlurMulti(index)}
                                    name={'text'}
                                    noLabel
                                />
                            </div>

                            {
                                item.answers.sort((a, b) => a.order_by - b.order_by).map((elem, indexLevel2) => {
                                    return (
                                        <div className={classes.sectionField} key={indexLevel2}>
                                            {
                                                indexLevel2 === 0 ?
                                                <span className={classes.label}>Варианты ответов на вопросы (опционально)</span> : null
                                            }

                                            <FieldFilter
                                                placeholder={'Введите текст...'}
                                                key={elem.title}
                                                value={elem.title}
                                                onBlur={handleBlurMulti(index, indexLevel2)}
                                                name={'answers'}
                                                noLabel
                                            />
                                        </div>
                                    )
                                })
                            }
                            <div className={classes.buttonsQA}>
                                <span className={classes.add} onClick={addAnswer(index)}>
                                    <img src={plus} alt=""/>Добавить ответ
                                </span>

                                {
                                    index + 1 === state.questions.length ?
                                        <span className={classes.add} onClick={addQuestion}>
                                            <img src={plus} alt=""/>Добавить вопрос
                                        </span>  : null
                                }
                            </div>
                        </div>
                    )
                })
            }

            <div className={classes.buttonsBlock}>
                <Button
                    variant="contained"
                    className={classes.buttonClose}
                    disableElevation
                    onClick={() => history.push('/admin/scripts')}
                >
                    Отмена
                </Button>

                <Button
                    disabled={load.loadPostScript}
                    variant="contained"
                    className={classes.buttonCreate}
                    disableElevation
                    onClick={createOrEditScript}
                    startIcon={load.loadPostScript ? <AnimateLoader color={'#fff'} /> : null}
                >
                    { load.loadPostScript ? 'Подождите...' : editComponent ? 'Сохранить изменения' : 'Создать' }
                </Button>
            </div>
        </div>
    )
};

export default NameAndQuestions;