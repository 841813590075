import React from 'react';

const Active = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0L17.217 1.826C17.674 1.928 18 2.333 18 2.802V12.789C18 14.795 16.997 16.669 15.328 17.781L9 22L2.672 17.781C1.002 16.668 0 14.795 0 12.79V2.802C0 2.333 0.326 1.928 0.783 1.826L9 0ZM13.452 7.222L8.502 12.171L5.674 9.343L4.26 10.757L8.503 15L14.867 8.636L13.452 7.222Z" fill="#53BB63"/>
        </svg>
    )
};

export default Active;