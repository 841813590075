import React, { useRef, useState } from 'react'
import { TextField, Button, Typography, CircularProgress } from '@material-ui/core'
import { useStyles } from './styles'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Redirect } from 'react-router-dom'
import CRM from '../../css/svg/CRM.svg'
import { useAuthActions } from '../../Context/AdminAuthContext/AdminAuthProvider';
import { getFromLocalStorage } from '../../utils/localStorage';
import MaskedInput              from 'react-text-mask';
import { Grid } from '@material-ui/core';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            mask={['8','(',/\d/, /\d/, /\d/,')', /\d/, /\d/,/\d/, '-', /\d/, /\d/,'-',/\d/, /\d/]}
            placeholder={'8(999)999-99-99'}
            showMask
        />
    );
}

function Login() {

    const { loginUser } = useAuthActions();

    const login = useRef();
    const password = useRef();
    const c = useStyles();
    const [isFetching, setIsFetching] = useState(false);
    const [error, setErrors] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onAccept = async (e) => {
        e.preventDefault();
        setErrors('');
        setIsFetching(true);

        let phoneFormat = login.current.value.match(/\d+/g).join('').slice(1);

        let res = await loginUser(
            phoneFormat,
            password.current.value
        );
        if(res.token){
            return <Redirect to='/admin/citizens' />
        }
        setIsFetching(false);
        setErrors('Ошибка авторизации')
    };


    if (getFromLocalStorage('token')) {
        return <Redirect to='/admin/citizens' />
    }

    return (
        <Grid item xs={12}>
            {/* <img src={CRM} className={c.image} style={{ margin: '0 auto' }} width='141' height='28' />  */}

            <form onSubmit={onAccept} >

                <Typography className={c.title} >
                    Вход
                </Typography>

                {error
                    ? <div style={{ fontSize: '13px', color: '#FF3300', marginTop: '10px', marginBottom: '10px' }} >{error}</div>
                    : null
                }

                <TextField
                    color='primary'
                    inputRef={login}
                    fullWidth
                    variant='filled'
                    label='Телефон'
                    className={c.textField}
                    InputProps={{
                        inputComponent: TextMaskCustom,
                    }}
                />

                <TextField
                    color='primary'
                    inputRef={password}
                    label='Пароль'
                    fullWidth
                    variant='filled'
                    margin='normal'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility style={{ color: '#004B92' }} /> : <VisibilityOff style={{ color: '#004B92' }} />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    className={c.textField}
                />

                {/*   <Typography className={c.forgetPassword}>
                    Забыли пароль?
                </Typography> */}

                <Button
                    variant="contained"
                    color='primary'
                    className={c.button}
                    type='submit'
                    fullWidth
                    disabled={isFetching}
                >
                    {isFetching
                        ? <CircularProgress style={{ color: 'white' }} />
                        : 'Вход'
                    }
                </Button>
            </form>

        </Grid>
    )
}

export default Login
