import React from 'react'
import { CloudRow } from 'ui/CloudRow/CloudRow'

function InfoCloud({ c, fullInfo }) { // господи Боже. c - это стили
    return (
        <div className={c.infoCloud}>
            <div className={c.threeColumns}>
                <div>
                    <CloudRow title='Регион'>
                        {fullInfo?.region?.name}
                    </CloudRow>
                    <CloudRow title='УИК гражданина'>
                        {fullInfo?.uik_number}
                    </CloudRow>
                </div>

                <div>
                    <CloudRow title='ИНН организации'>
                        ***
                    </CloudRow>
                    <CloudRow title='КПП организации'>
                        ***
                    </CloudRow>
                </div>
            </div>
        </div>
    )
}

export default InfoCloud
