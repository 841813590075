import { toRole } from './toRole';

export const toRoleCurator = (arrRole) => {
    if(toRole(arrRole) !== 'curator') return false;

    let role = [];
    arrRole.forEach(elem => {
        role.push(elem.system_name)
    });

    if(role.join('').includes('curator_fed_okrug')) return 'curator_fed_okrug';
    if(role.join('').includes('curator_omsu')) return 'curator_omsu';
    if(role.join('').includes('curator_region')) return 'curator_region';

    return 'curator'
};