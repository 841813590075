import React, { useState, useEffect } from 'react'
import LoadingDialog from '../LoadingDialog/LoadingDialog';
import CardTitleVoluenteer from '../CardTitleVoluenteer/CardTitleVoluenteer';
import InfoCloudVolunteer from '../InfoCloudVolunteer/InfoCloudVolunteer';
import CommentHistory from './CommentHistory';
import ClaimsTable from './ClaimsTable'
import Statisctics from './Statisctics'
import {
    getFullVolonteerInfo, getVolunteerCardList,
    getCardStatistics, getCardStatus,
    getCommentVolunteer
} from '../../API/Volonteer'
import { useModalStyles } from './styles'

function VolonteerCard({ id, dataTbody, setDataTbody, areaOptions, regionsOptions, omsuOptions, fetchOmsu, fetchRegions }) {
    const c = useModalStyles();

    const [loadCard, setLoadCard] = useState(true);
    const [error, setError] = useState(false);

    const [data, setData] = useState({
        fullInfo: {},
        statistics: [],
        tableList: [],
        status: [],
        cardList: [],
        comments: []
    });

    const [page, setPage] = useState(1);

    const [filterState, setFilterState] = useState({
        status: null,
        fio: '',
        countCall: '',
        taskId: '',
    });

    useEffect(() => {
        let mounted = true;
        (async () => {
            try {
                let fullInfo = await getFullVolonteerInfo(id); // заменить на id
                let statistics = await getCardStatistics(id); // заменить на id
                let status = await getCardStatus();
                let cardList = await getVolunteerCardList(id, page, 3); //17 заменить на id
                let comments = await getCommentVolunteer(id); //// заменить на id
                if (mounted) {
                    setData({ ...data, fullInfo, statistics, status, cardList, comments });
                    setLoadCard(false);
                }
            } catch (e) {
                setError(e);
                setLoadCard(false);
            }
        })();

        return () => {
            mounted = false;
        }
    }, []);

    const handleChangePagination = async (event, value) => { //обработчик для пагинации
        setPage(value);
        let cardList = await getVolunteerCardList(id, value, 3, filterState); //1 заменить на id
        setData({ ...data, cardList });
    };

    const handleValueSelect = (name) => (event, val) => { //обработчик для селектов фильтра
        setFilterState({
            ...filterState,
            [name]: val,
        })
    };

    const handleBlur = (event) => { //обработчки на инпуты в фильтрах
        setFilterState({
            ...filterState,
            [event.target.name]: event.target.value
        })
    };

    if (loadCard) {
        return <LoadingDialog />
    }

    if (error) {
        return (
            <div style={{
                width: '100%',
                height: 400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <span style={{ color: 'red' }}>{error}</span>
            </div>
        )
    }

    return (
        <div>

            <CardTitleVoluenteer
                data={data}
                setData={setData}
                dataTbody={dataTbody}
                setDataTbody={setDataTbody}
            />

            <InfoCloudVolunteer
                areaOptions={areaOptions}
                data={data.fullInfo}
                regionsOptions={regionsOptions}
                omsuOptions={omsuOptions}
                fetchOmsu={fetchOmsu}
                fetchRegions={fetchRegions}
                dataTbody={dataTbody}
                setDataTbody={setDataTbody}
            />

            <div className={c.tablesWrap} >
                {/* <Grid container spacing={2} className={c.filterFieldContainer}>
                    <Grid item xs={4}>
                        <FieldFilter
                            key={filterState.fio}
                            value={filterState.fio}
                            onBlur={handleBlur}
                            rounded
                            name={'fio'}
                            label={'ФИО пенсионера'}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <SelectForFilter
                            readonly
                            label={'Текущий статус'}
                            value={filterState.status}
                            options={data.status}
                            rounded
                            handleValueSelect={handleValueSelect('status')}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FieldFilter
                            type={'number'}
                            key={filterState.countCall}
                            value={filterState.countCall}
                            onBlur={handleBlur}
                            rounded
                            name={'countCall'}
                            label={'Количество звонков'}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FieldFilter
                            type={'number'}
                            key={filterState.countCall}
                            value={filterState.countCall}
                            onBlur={handleBlur}
                            rounded
                            name={'countCall'}
                            label={'ID задачи'}
                        />
                    </Grid>
                </Grid> */}

                <ClaimsTable
                    handleChangePagination={handleChangePagination}
                    status={data.status}
                    page={page}
                    tableData={data.cardList}
                    c={c}
                />

                <Statisctics statistics={data.statistics} />
            </div>

            {
                data.comments.length
                    ? <CommentHistory comments={data.comments} />
                    : <div className={c.wrapComments}>
                        <h5 className={c.commentHeading}>Комментарии отсутствуют</h5>
                    </div>
            }
        </div>
    )
}

export default VolonteerCard;