import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    scriptsWrap: {
        paddingBottom: 50,

        '& table thead th:first-child': {
            width: 50
        },

        '& table thead th:last-child': {
            width: 50
        }
    },
    wrapHeader: {
        paddingTop: 33,
    },
    headers: {
        display: 'flex',
        marginBottom: 42,
    },
    vp_headingPage: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 36,
        lineHeight: '43px',
        color: theme.black,
    },
    button: {
        width: 170,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#53BB63',
        border: '1px solid #53BB63',
        fontSize: 14,
        color: '#fff',
        marginLeft: 25,
        transition: 'all .3s',

        '&:hover': {
            background: '#61DB73',
        }
    },
    iconDelete: {
        display: 'inline-block',

        '&:before': {
            content: "''",
            display: 'inline-block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        }
    },
    buttonConfirm: {
        minWidth: 87,
        color: '#000',
        '&:disabled': {
            background: '#fff!important',
            height: '36px!important'
        }
    }
}));