import React, { useState, useEffect,
    useContext, useMemo}                         from 'react';
import { useParams, useHistory,
    useLocation }                       from 'react-router-dom';
import queryString                      from 'query-string';
import Fade                             from '@material-ui/core/Fade';
import TablesAll                        from '../../Components/TablesAll/TablesAll';
import FilterBlockPollingStation        from '../../Components/FilterBlocks/FilterBlockPollingStation';
import { postPollingStationList }       from '../../API/PollingStation';
import Button                           from '@material-ui/core/Button';
import FilterIcon                       from '../../css/svg/FilterIcon';
import { useStyles }                    from './style';
import {FilterContext}                  from '../../Context/FilterContext/FilterContext';
import { useMediaQuery } from '@material-ui/core';

const PollingStation = () => {
    const classes = useStyles();
    const { fetchOmsu, omsuOptions, areaOptions, fetchRegions, regionsOptions } = useContext(FilterContext);

    const history = useHistory();
    const location = useLocation();
    const { idPage = "1" } = useParams();

    const [load, setLoad] = useState(true);
    const [error, setError] = useState(false);
    const [dataTbody, setDataTbody] = useState(null);
    const [openCollapse, setOpenCollapse] = useState(true);
    const [nav, setNav] = useState({
        page: idPage ? +idPage : 1,
        selectPage: 10,
    });
    const [filterState, setFilterState] = useState({ //coстояние для селектов и инпутов филтра
        area: null,
        region: null,
        omsu: null,
        address: '',
        phone: '',
        numberUik: ''
    });

    const nameThead = ['id', 'Федеральный округ', 'Регион', 'Муниципальное образование', 'Наименование УИК', 'Номер ТИК', 'Адрес помещения УИК', 'Телефон УИК', 'Адрес помещения для голосования', 'Телефон помещения для голосования'];

    useEffect(() => { //установка значений в селект при первой загрузке с квери параметрами
        setFilterState(prevFilterState => ({
            ...prevFilterState,
            area: helperStateFilter("filters[area_id]", areaOptions),
            address: queryString.parse(location.search)["filters[address_uik]"] || '',
            phone: queryString.parse(location.search)["filters[phone_uik]"] || '',
            numberUik: queryString.parse(location.search)["filters[number_uik]"] || '',
        }));
    },[]);

    useEffect(() => {
        setError(false);
        setLoad(true);
        let queryStringFilters = queryString.parse(location.search);

        setDataTbody(null);

        postPollingStationList(idPage, nav.selectPage, queryStringFilters).then((res) => {
            setDataTbody(res);
            setLoad(false);
        }).catch(e =>{
            setError(e);
            setLoad(false);
        });
    }, [idPage, nav.selectPage, location.search]);

    useEffect(() => { // при изменении округа меняем регионы
        if(filterState.area) {
            fetchRegions({"filters[area_id]": filterState.area.id}).then((res) => {

                const regionFind = helperStateFilter("filters[region_id]", res); //если есть query параметры

                setFilterState(prevState => ({
                    ...prevState,
                    region: regionFind ? regionFind : null,
                }))
            });
        }
    }, [filterState.area]);

    useEffect(() => { // при изменении региона меняем omsu
        if(filterState.region) {
            fetchOmsu({"filters[region_id]": filterState.region.id}).then((res) => {
                const omsuFind = helperStateFilter("filters[omsu_id]", res); //если есть query параметры

                setFilterState(prevState => ({
                    ...prevState,
                    omsu: omsuFind ? omsuFind : null
                }))
            })
        }
    }, [filterState.region]);

    const helperStateFilter = (nameFilter, filterOptions) => { //при наличии query-параметра находим в списке селекта нужный элемент
        const objFilters = queryString.parse(location.search);
        if(Object.keys(objFilters).length && nameFilter in objFilters) {
            return filterOptions.find(elem => elem.id.toString() === objFilters[nameFilter].toString());
        }
        return null;
    };

    const helperTdrender = (data) => {
        return [data.id, data.omsu.region.area.name, data.omsu.region.name, data.omsu.name, `Избирательная комиссия №${data.number_uik}`, data.number_uik, data.address_uik, <span>{data.phone_uik}</span>, data.vote_address, <span>{data.phone_vote_address}</span>];
    };

    const handleChangePagination = (event, value) => { //обработчик для пагинации
        const url = location.search
            ? `/admin/pollingstation/${value}${location.search}`
            : `/admin/pollingstation/${value}`;

        history.push(url);

        setNav({
            ...nav,
            page: value
        });
    };

    const handleChangeSelectPagination = (event) => { //обработчик селекта пагинации
        const url = location.search
            ? `/admin/pollingstation${location.search}`
            : `/admin/pollingstation`;

        history.push(url);

        setNav({
            ...nav,
            selectPage: event.target.value,
            page: 1,
        });
    };

    const handleValueSelect = (name) => (event, val) => { //обработчик для селектов фильтра
        setFilterState({
            ...filterState,
            [name]: val,
        })
    };

    const paramsData = () => {
        let paramsObject = {};
        if(filterState.area) paramsObject["filters[area_id]"] = filterState.area.id;
        if(filterState.region) paramsObject["filters[region_id]"] = filterState.region.id;
        if(filterState.omsu) paramsObject["filters[omsu_id]"] = filterState.omsu.id;
        if(filterState.address) paramsObject["filters[address_uik]"] = filterState.address;
        if(filterState.phone) paramsObject["filters[phone_uik]"] = filterState.phone;
        if(filterState.numberUik) paramsObject["filters[number_uik]"] = filterState.numberUik;

        return paramsObject;
    };

    const resetFilter = () => {
        setFilterState({
            ...filterState,
            omsu: null,
            area: null,
            region: null,
            address: '',
            phone: '',
            numberUik: ''
        });
        fetchRegions();
        history.push('/admin/pollingstation');
    };

    const handleBlur = (event) => { //обработчки на инпуты в фильтрах
        setFilterState({
            ...filterState,
            [event.target.name]: event.target.value
        })
    };

    const isSmallScreen = useMediaQuery('(max-width: 770px)')

    return (
        <Fade timeout={800} in>
            <div className={classes.pollingstation}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>Реестр избирательных участков</h3>

                        <Button
                            variant="contained"
                            className={`${classes.buttonFilter} ${!openCollapse ? classes.buttonFilterClose : ''}`}
                            disableElevation
                            endIcon={isSmallScreen ? null  : <FilterIcon openCollapse={openCollapse} />}
                            onClick={() => setOpenCollapse(!openCollapse)}
                        >
                            {isSmallScreen ? <FilterIcon openCollapse={openCollapse} />  : ('Фильтры')}
                        </Button>
                    </div>
                </div>

                <FilterBlockPollingStation
                    areaOptions={areaOptions}
                    regionsOptions={regionsOptions}
                    omsuOptions={omsuOptions}
                    handleValueSelect={handleValueSelect}
                    handleBlur={handleBlur}
                    openCollapse={openCollapse}
                    filterState={filterState}
                    resetFilter={resetFilter}
                    paramsData={paramsData}
                />

                <TablesAll
                    error={error}
                    otherFunctionOpenModal={() => {}}
                    nameThead={nameThead}
                    load={load}
                    dataTbody={dataTbody}
                    helperTdrender={helperTdrender}
                    handleChangePagination={handleChangePagination}
                    page={+idPage}
                    selectPage={nav.selectPage}
                    handleChangeSelectPagination={handleChangeSelectPagination}
                />
            </div>
        </Fade>
    )
};

export default PollingStation;