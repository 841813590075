import React, { useState, useEffect, useContext }           from 'react';
import { useHistory, useParams }                            from 'react-router-dom';
import NameAndQuestions                                     from '../../Components/NameAndQuestions/NameAndQuestions';
import TypeAndGeography                                     from '../../Components/TypeAndGeography/TypeAndGeography';
import Fade                                                 from '@material-ui/core/Fade';
import CircularProgress                                     from '@material-ui/core/CircularProgress';
import { fetchFullInfoScript, updateScript }                from '../../API/Scripts';
import { useHookAddanEditScript }                           from '../../HOC/useHookAddanEditScript/useHookAddanEditScript';
import { FilterContext }                                    from '../../Context/FilterContext/FilterContext';
import {ScriptContext}                                      from '../../Context/ScriptsContext/ScriptsContext';
import { errorToast }                                       from '../../utils/ErrorToast';
import { useStyles }                                        from './style';

const EditScript = () => {
    const classes = useStyles();

    const { id } = useParams();
    const history = useHistory();

    const { regionsOptions } = useContext(FilterContext);
    const { citizenTypesOptions } = useContext(ScriptContext);

    const [loadPage, setLoadPage] = useState(true);

    const {
        state,
        setState,
        load,
        errors,
        handleBlur,
        handleBlurMulti,
        changeHandler,
        handleValueSelect,
        handleChangeRadio,
        postScript,
        addNewTypeFailReson,
        addNewTypeVote
    } = useHookAddanEditScript(updateScript, loadPage);

    useEffect(() => {
        (async function () {
            try {
                const res = await fetchFullInfoScript(id);
               /*  let omsuOptions = []; */
                let regions = [];

                if(res.regions.length) {
                    const idArr = Array.from(new Set(res.regions.map(i => i.id))); //массив из id выбранных регионов
                    regions = regionsOptions.filter(item => idArr.includes(item.id)); //выбираем регионы чтобы сделать их чекнутыми
/* 
                    for(let id of idArr) { // по id регионов подгружаем их омсу
                        const res = await getOmsuPoll(id);
                        omsuOptions = omsuOptions.concat(res);
                    }

                    omsuOptions = omsuOptions.concat(res.omsu); //добавляем полученные омсу в общий список
                    omsuOptions.sort((prev, next) => prev.id - next.id); //сортируем по id */
                }

                setState({
                    ...state,
                    nameScript: res.title,
                    textScript: res.text,
                    text_goodbye: res.text_goodbye,
                    questions: res.questions.length ? res.questions : [
                        {
                            order_by: 1,
                            title: '',
                            text: '',
                            answers: [{title: '', order_by: 1}]
                        }
                    ],
                    voteTypes: res.vote_types,
                    failReason: res.fail_reasons,
                    regions: regions,
                    type_id: res.type,
                    phone_type_city: res.phone_type_city,
                    phone_type_mobile: true.phone_type_mobile
                });
                setLoadPage(false)
            } catch (err) {
                history.push('/admin/scripts');
                errorToast(`[Ошибка]: ${err}`);
                throw err;
            }
        })();
    },[]);

    if(loadPage) {
        return (
            <div className={classes.loadPage}>
                <CircularProgress disableShrink />
            </div>
        )
    }

    return (
        <Fade timeout={800} in={!loadPage}>
            <div className={classes.containerEditScript}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>Редактирование скрипта</h3>
                    </div>
                </div>

                <div className={classes.fieldsContent}>
                    <div className={classes.nameAndQuestions}>
                        <NameAndQuestions
                            state={state}
                            citizenTypesOptions={citizenTypesOptions}
                            handleBlur={handleBlur}
                            handleBlurMulti={handleBlurMulti}
                            handleValueSelect={handleValueSelect}
                            setState={setState}
                            createOrEditScript={postScript}
                            load={load}
                            errors={errors}
                            editComponent
                        />
                    </div>

                    <div className={classes.typeAndGeography}>
                        <TypeAndGeography
                            errors={errors}
                            handleBlur={handleBlur}
                            changeHandler={changeHandler}
                            state={state}
                            setState={setState}
                            addNewTypeFailReson={addNewTypeFailReson}
                            addNewTypeVote={addNewTypeVote}
                            load={load}
                            regionsOptions={regionsOptions}
                            handleChangeRadio={handleChangeRadio}
                        />
                    </div>
                </div>
            </div>
        </Fade>
    )
};

export default EditScript;
