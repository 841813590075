import instance from './instance'
import { removeFromLocalStorage } from '../utils/localStorage'
import { errorToast } from '../utils/ErrorToast'


export const getCallResults = async () => {
    try {
        let res = await instance.get('/api/call_results')
        return res
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token')
        }
        errorToast(err.message, 'callManToast')
    }
}

export const getVoteTypes = async () => {
    try {
        let res = await instance.get('/api/vote_types')
        return res
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token')
        }
        errorToast(err.message, 'voteManToast')
    }
}

export const getCallBackReasons = async () => {
    try {
        let res = await instance.get('/api/call_back_reasons')
        return res
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token')
        }
        errorToast(err.message, 'cbManToast')
    }
};

export const getFailReasons = async () => {
    try {
        let res = await instance.get('/api/fail_reasons');
        return res
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token')
        }
        errorToast(err.message, 'failManToast')
    }
}
