/* eslint-disable */
import instance from './instance';
import { removeFromLocalStorage } from '../utils/localStorage';

export const postVolonteerList = async (page = 1, perPage = 10, filters) => {
    try {
        let res = await instance
            .get(
                `/api/volunteers`,
                {
                    params: {
                        page: page,
                        page_size: perPage,
                        ...filters
                    }
                });
        return res.data
    } catch (err) {
        console.log('ОШИБКА', err.response.data.errors);

        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const getFullVolonteerInfo = async (id) => {
    try {
        let res = await instance.get(`/api/volunteers/${id}`);
        return res.data
    } catch (err) {

        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message + ' in /api/volunteers/id';
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const getVolunteerCardList = async (id, page = 1, page_size = 3, filters = {}) => {
    try {
        let res = await instance.get(`/api/volunteers/${id}/cards`, {
            params: { page, page_size, ...filters }
        });
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message + ' in /api/volunteers/id/cards';
        } else {
            throw err.response.data.errors.detail;
        }
    }
};

export const getCardStatistics = async (id) => {
    try {
        let res = await instance
            .get(
                `/api/volunteers/${id}/statistics`
            );
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message + ' in /api/volunteers/id/statistics';
        } else {
            throw err.response.data.errors.detail;
        }
    }
};

export const getCardStatus = async () => {
    try {
        let res = await instance.get('/api/card_statuses');
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message + ' in /api/card_statuses';
        } else {
            throw err.response.data.errors.detail;
        }
    }
};

export const getCommentVolunteer = async (id) => {
    try {
        let res = await instance.get(`/api/volunteers/${id}/comments`);
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token')
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message + ' in /api/volunteers/id/comments';
        } else {
            throw err.response.data.errors.detail;
        }
    }
};

export const changeStatus = async (idVolunteers, newStatus) => {

    const body = new FormData();
    body.append('volunteer', idVolunteers);
    body.append('status', newStatus);

    try {
        let res = await instance.patch('/api/moderation/status', body);
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }
    }
};

export const fetchFileCsvVolunteers = async (body) => {
    try {
        let res = await instance.post('/api/volunteers/export', body);
        return res.data
    } catch (err) {
        console.log(err)
    }
};
