import instance                             from './instance';
import { removeFromLocalStorage }           from '../utils/localStorage';

export const postNewTypeReasons = async (body) => { // получение базовых неуспешных типов звонков
    try {
        let res = await instance.post('/api/fail_reasons', body);
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const postNewTypeVote = async (body) => { // получение базовых успешных типов звонков
    try {
        let res = await instance.post('/api/vote_types', body);
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const getOmsuPoll = async (id) => { //получение омсу для создания скрипта
    try {
        let res = await instance.get('/api/poll/omsu', {
            params: {
                "filters[region_id]": id
            }
        });
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const postNewScript = async (body) => { // создание нового скрипта
    try {
        let res = await instance.post('/api/poll', body);
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const fetchFullInfoScript =  async (id) => { //получение полной информации по скрипту
    try {
        let res = await instance.get(`/api/poll/${id}`);
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const updateScript = async (body, id) => { // создание нового скрипта
    try {
        let res = await instance.patch(`/api/poll/${id}`, body);
        return res.data
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};
