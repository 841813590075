import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    citizen: {
      paddingBottom: 50,
    },
    wrapHeader: {
        paddingTop: 33,
    },
    headers: {
        display: 'flex',
        marginBottom: 26,
    },
    vp_headingPage: {
        fontFamily: 'Rubik', 
        fontWeight: 500,
        fontSize: 36,
        lineHeight: '43px',
        color: theme.black,
        position: 'relative'
    },
    buttonFilter: {
        width: 147,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#00A4D8',
        border: '1px solid #00A4D8',
        fontSize: 14,
        color: '#fff',
        marginLeft: 'auto',
        transition: 'all .3s',

        '&:hover': {
            background: '#00B8F0',
        },

        '@media(max-width: 550px)':{
            width:'auto'
        }
    },
    buttonFilterClose: {
        background: '#fff',
        border: '1px solid #00A4D8',
        color: '#00A4D8',

        '&:hover': {
            background: '#FBFBFB',
        }
    },
    wrapButtonBlue: {
        flex: 1,
        marginLeft: 40,

        '@media(max-width: 550px)':{
            marginLeft: 8   
        }
    },
    importButton:{
        width: 185,
        height: 45,
        textTransform: 'none',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0,164,216,.1)',
        fontSize: 14,
        color: '#00A4D8',
        transition: 'all .3s',

        '&:hover': {
            background: 'rgba(0,164,216,.15)',
        },

        '& > div':{    
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500
        },
        
        '& svg':{    
            marginRight: 8
        },

        '@media(max-width: 550px)':{
            width:'auto',
            minWidth: 57,
            '& svg':{    
                marginRight: 8
            },
        }
    },
    blueButtonsWrapper:{
        display: 'flex  '
    },
    exportButtons:{
        display: 'flex',
        '& > *:first-child':{
            marginRight: 8
        },
        
    },
    buttonBlue: {
        width: 185,
        height: 45,
        textTransform: 'none',
        borderRadius: 6,
        background: 'rgba(0,164,216,.1)',
        fontSize: 14,
        color: '#00A4D8',
        transition: 'all .3s',

        '&:hover': {
            background: 'rgba(0,164,216,.15)',
        },

        '@media(max-width: 550px)':{
            width:'auto'
        }
    },
    wrapSpanEdit: {
        width: 55
    },
    editSpan: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    taskButton: {
        width: 'calc(50% - 5px)',
        height: 48,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#004B92',
        fontSize: 14,
        color: '#fff',
        transition: 'all .3s',

        '&:hover': {
            background: '#005EB6',
        }
    },
    vp_anchorOriginTopRightRectangle: {
        position: 'absolute',
        right: -26,
        top: -9,
        fontFamily: 'SF Pro Display!important',
        fontSize: 15,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#E41D37',
        color: '#fff',
        width: 26,
        height: 26,
        borderRadius: '50%',
        cursor: 'pointer',
    }
}));