import React, {
    useState, useEffect,
    useContext,
    useCallback
} from 'react';
import {
    useParams, useHistory,
    useLocation
} from 'react-router-dom';
import queryString from 'query-string';
import Fade from '@material-ui/core/Fade';
import TablesAll from '../../Components/TablesAll/TablesAll';
import {
    postCitizensList,
    fetchFileCsvCitizens,
    getVolunteerStat,
    getVolunteerCallStat
} from '../../API/Citizens';
import Button from '@material-ui/core/Button';
import FilterIcon from '../../css/svg/FilterIcon';
import FilterBlockCitizens from '../../Components/FilterBlocks/FilterBlockCitizens';
import { FilterContext } from '../../Context/FilterContext/FilterContext';
import { useStyles } from './style';
import ViewCitizen from '../../Components/ViewCitizen/ViewCitizen';
import CardCitizensEditPhone from '../../Components/CardCitizensEditPhone/CardCitizensEditPhone';
import DialogAdmin from '../../Components/DialogAdmin/DialogAdmin';
import DialogConfirmData from '../../Components/DialogConfirmData/DialogConfirmData';
import AnimateLoader from '../../css/svg/AnimateLoader';
import Unload from '../../css/svg/Unload';
import EditPencil from '../../css/svg/EditPencil';
import { useAuthState } from '../../Context/AdminAuthContext/AdminAuthProvider';
import { toRole } from '../../utils/toRole';
import { useMediaQuery } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import instance from 'API/instance';
import { ExportDialog } from 'Common/ExportDialog/ExportDialog';

const Citizen = () => {
    const classes = useStyles();
    const { omsuOptions, voteTypeOptions, statusCardOptions, regionsOptions } = useContext(FilterContext);
    const hasPhoneOptions = [{ id: 1, name: 'Номер внесён' }, { id: 0, name: 'Номер не внесён' }, { id: null, name: 'Все' }];
    const { volunteer, ...other } = useAuthState();

    const history = useHistory();
    const location = useLocation();
    const { idPage = "1", id } = useParams();

    const [openDialog, setOpenDialog] = useState(false);
    const [idCard, setIdCard] = useState(null);
    const [error, setError] = useState(false);
    const [forciblyFetch, setForciblyFetch] = useState(false);
    const searchParamsFromURI = location.search;

    const [load, setLoad] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [dataTbody, setDataTbody] = useState(null);
    const [openCollapse, setOpenCollapse] = useState(true);
    const [nav, setNav] = useState({
        page: idPage ? +idPage : 1,
        selectPage: 10,
    });

    const isSmallScreen = useMediaQuery('(max-width: 550px)')
    const [isAlbumOrientation, setIsAlbumOrientation] = useState(window.innerHeight < window.innerWidth)

    const detectScreenOrientation = useCallback((e) => {
        if(e.target.innerHeight < e.target.innerWidth){
            if(!isAlbumOrientation){
                setIsAlbumOrientation(true)
            }
        }else{
            if(isAlbumOrientation){
                setIsAlbumOrientation(false)
            }
        }
    },[isAlbumOrientation])

    useEffect(() => {
        window.addEventListener("resize", detectScreenOrientation)
    
        return () => window.removeEventListener("resize", detectScreenOrientation)
    },[detectScreenOrientation])

    const [filterState, setFilterState] = useState({ //coстояние для селектов и инпутов филтра
        // omsu: null,
        vote_type: null,
        status_card: null,
        region: null,
        snils: '',
        idTask: '',
        availabilityPhone: null,
        uik_numbers: ''
    });


    const nameThead = isSmallScreen && !isAlbumOrientation ? ['ID','УИК', 'Статус'] : ['id', 'Регион', 'Избирательный участок( номер )', 'ФИО', 'Совершен звонок', 'Вид голосования', 'Статус активности'];
    if (!volunteer) nameThead.push('');

    useEffect(() => { //установка значений в селект при первой загрузке с квери параметрами
        setFilterState({
            ...filterState,
            region: helperStateFilter("filters[region_code]", regionsOptions),
            vote_type: helperStateFilter("filters[vote_type_id]", voteTypeOptions),
            status_card: helperStateFilter("filters[card_status_id]", statusCardOptions),
            snils: queryString.parse(location.search)["filters[snils]"] || '',
            name: queryString.parse(location.search)["filters[name]"] || '',
            surname: queryString.parse(location.search)["filters[surname]"] || '',
            patronymic: queryString.parse(location.search)["filters[patronymic]"] || '',
            idTask: queryString.parse(location.search)["filters[citizen_id]"] || '',
            uik_numbers: Array.isArray(queryString.parse(location.search)["filters[uik_numbers]"]) ? queryString.parse(location.search)["filters[uik_numbers]"].join(',') : queryString.parse(location.search)["filters[uik_numbers]"] || '',
            availabilityPhone: helperStateFilter("filters[has_phone]", hasPhoneOptions),
        })
        /* eslint-disable-next-line */
    }, []);

    const getData = async () => {
        setError(false);
        setLoad(true);

        let queryStringFilters = queryString.parse(location.search);

        let snils = queryStringFilters["filters[snils]"]; //делаем снилс без тире
        if (snils) snils = snils.match(/\d+/g).join('');
        queryStringFilters["filters[snils]"] = snils;

        if (queryStringFilters["filters[card_status_id]"]) {
            queryStringFilters["filters[card_status_id]"] = [queryStringFilters["filters[card_status_id]"]]
        }

        // delete queryStringFilters["filters[region_id]"];
        setDataTbody(null);

        postCitizensList(idPage, nav.selectPage, queryStringFilters).then((res) => {

            setDataTbody(res);
            setLoad(false);

        }).catch(e => {

            setError(e);
            setLoad(false);
        })
    }


    useEffect(() => { //отрабатывает при первой загрузке при пагинации, выбора кол-ва строк на стрнаице и при применении и сброса фильтров
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPage, nav.selectPage, location.search, forciblyFetch]);

    const helperStateFilter = (nameFilter, filterOptions) => { //при наличии query-параметра находим в списке селекта нужный элемент
        const objFilters = queryString.parse(location.search);
        if (Object.keys(objFilters).length && nameFilter in objFilters) {
            if (nameFilter === 'filters[region_code]') {
                return filterOptions.find(elem => elem.code.toString() === objFilters[nameFilter].toString());
            }
            return filterOptions.find(elem => elem.id.toString() === objFilters[nameFilter].toString());
        }
        return null;
    };

    const helperTdrender = (data) => { //возвращает массив в нужном порядке для расположения ячеек таблицы
        const fio = `${data.name ? data.name : ''} ${data.patronymic ? data.patronymic : ''} ${data.surname}`;
        const arrRender = isSmallScreen && !isAlbumOrientation
        ? [data.id, data.uik_number || '-', data.card_status?.name || '-']
        : [
            data.id,
            data?.region?.name || 'Не указано',
            data.uik_number,
            fio,
            data.made_call ? 'Да' : 'Нет',
            data.vote_type ? data.vote_type.name : 'Не указано',
            data.card_status ? data.card_status.name : '-'
        ];

        if (!volunteer) arrRender.push(<div className={classes.wrapSpanEdit}><span onClick={openDialogEditPhoneNumber(data.id)} className={classes.editSpan}><EditPencil /></span></div>);
        return arrRender;
    };

    const handleChangePagination = (event, value) => { //обработчик для пагинации
        const url = location.search
            ? `/admin/citizens/${value}${location.search}`
            : `/admin/citizens/${value}`;

        history.push(url);

        setNav({
            ...nav,
            page: value
        });
    };

    const handleChangeSelectPagination = (event) => { //обработчик селекта пагинации
        const url = location.search
            ? `/admin/citizens${location.search}`
            : `/admin/citizens`;

        history.push(url);

        setNav({
            ...nav,
            selectPage: event.target.value,
            page: 1,
        });
    };

    const handleValueSelect = (name) => (event, val) => { //обработчик для селектов фильтра
        setFilterState({
            ...filterState,
            [name]: val,
        })
    };

    const handleBlur = (event) => { //обработчки на инпуты в фильтрах
        setFilterState({
            ...filterState,
            [event.target.name]: event.target.value
        })
    };

    const paramsData = () => { // данные который пойдут в query-параметры для фильтров
        let paramsObject = {};
        if (filterState.region) paramsObject["filters[region_code]"] = filterState.region.code;
        if (filterState.name) paramsObject["filters[name]"] = filterState.name.toUpperCase();
        if (filterState.surname) paramsObject["filters[surname]"] = filterState.surname.toUpperCase();
        if (filterState.patronymic) paramsObject["filters[patronymic]"] = filterState.patronymic.toUpperCase();
        if (filterState.vote_type) paramsObject["filters[vote_type_id]"] = filterState.vote_type.id;
        if (filterState.status_card) paramsObject["filters[card_status_id]"] = [filterState.status_card.id];
        if (filterState.snils) paramsObject["filters[snils]"] = filterState.snils;
        if (filterState.idTask) paramsObject["filters[citizen_id]"] = filterState.idTask;
        if (filterState.uik_numbers) paramsObject["filters[uik_numbers]"] = filterState.uik_numbers.split(',');
        if (filterState.availabilityPhone) paramsObject["filters[has_phone]"] = filterState.availabilityPhone.id;
        return paramsObject;
    };

    const resetFilter = () => { //сброс фильтров
        setFilterState({
            ...filterState,
            region: null,
            // omsu: null,
            vote_type: null,
            status_card: null,
            snils: '',
            idTask: '',
            name: '',
            surname: '',
            patronymic: '',
            uik_numbers: '',
            availabilityPhone: null,
        });
        history.push('/admin/citizens');
    };

    /* const saveAsCsv = async () => { //сохраняем данные в формате csv

        let queryStringFilters = queryString.parse(location.search);

        let formData = new FormData();
        queryStringFilters["filters[region_code]"] && formData.append("filters[region_code]", filterState.region.code);
        queryStringFilters["filters[name]"] && formData.append("filters[name]", filterState.name);
        queryStringFilters["filters[surname]"] && formData.append("filters[surname]", filterState.surname);
        queryStringFilters["filters[patronymic]"] && formData.append("filters[patronymic]", filterState.patronymic);
        queryStringFilters["filters[vote_type_id]"] && formData.append("filters[vote_type_id]", filterState.vote_type.id);
        queryStringFilters["filters[card_status_id]"] && formData.append("filters[card_status_id][]", filterState.status_card.id);
        queryStringFilters["filters[snils]"] && formData.append("filters[snils]", filterState.snils.match(/\d+/g).join(''));
        queryStringFilters["filters[citizen_id]"] && formData.append("filters[citizen_id]", filterState.idTask);
        queryStringFilters["filters[uik_numbers]"] && formData.append("filters[uik_numbers]", filterState.uik_numbers.split(','));
        queryStringFilters["filters[has_phone]"] && formData.append("filters[has_phone]", filterState.availabilityPhone.id);

        setButtonLoading(true);

        try {
            const { path } = await fetchFileCsvCitizens(formData);
            window.location.href = path
        } catch (e) {
            console.log('Ошибка загрузки файла', e)
        } finally {
            setButtonLoading(false);
        }
    }; */

    const openDialogEditPhoneNumber = (id) => (event) => {
        event.stopPropagation();
        setIdCard(id);
        setOpenDialog(true);
    };

    const handleDailyExport = async () => {
        try{
            const { data } = await instance
            .get('/api/citizens/export_success_daily')

            const a = document.createElement('a');
            a.href = data.path;
            a.download = data.path.split('/').slice(-1);

            a.click()
        }catch(e){
            console.error(e)
        }
    }

    return (
        <Fade timeout={800} in>
            <div className={classes.citizen}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>
                            Задачи
                            {
                                volunteer && volunteer.citizen && volunteer.citizen.id ?
                                    <span
                                        onClick={() => {
                                            history.push(
                                                `/admin/citizens/${idPage || '1'}/${volunteer.citizen.id}${searchParamsFromURI || ''}`,
                                            )
                                        }}
                                        className={classes.vp_anchorOriginTopRightRectangle}
                                    >1</span> : null
                            }
                        </h3>

                        {
                            toRole(other.roles) === 'admin' &&(
                                <div className={classes.exportButtons}>
                                    <ExportDialog
                                        StartIcon={Unload}
                                        types={[
                                            { title: 'Количество авторизаций', onClick: (from, to, type_id) => getVolunteerStat({ from, to, type_id }), withTypes: true },
                                            { title: 'Количество звонков', onClick: (from, to) => getVolunteerCallStat({ from, to }) },
                                        ]}
                                    >
                                        Выгрузить в .csv
                                    </ExportDialog>
                                    <div className={classes.wrapButtonBlue}>
                                        <Button
                                            variant="contained"
                                            className={`${classes.buttonFilter} ${classes.buttonFilterClose}`}
                                            disableElevation
                                            onClick={handleDailyExport}
                                        >
                                            {isSmallScreen ? buttonLoading ? <AnimateLoader /> : <GetAppIcon /> : buttonLoading ? 'Подождите...' : 'Экспорт'}
                                        </Button>
                                    </div>
                                </div>
                            )
                                
                        }

                        <Button
                            variant="contained"
                            className={`${classes.buttonFilter} ${!openCollapse ? classes.buttonFilterClose : ''}`}
                            disableElevation
                            endIcon={isSmallScreen ? null  : <FilterIcon openCollapse={openCollapse} />}
                            onClick={() => setOpenCollapse(!openCollapse)}
                        >
                            {isSmallScreen ? <FilterIcon openCollapse={openCollapse} />  : ('Фильтры')}
                        </Button>
                    </div>
                </div>

                <FilterBlockCitizens
                    regionsOptions={regionsOptions}
                    omsuOptions={omsuOptions}
                    hasPhoneOptions={hasPhoneOptions}
                    voteTypeOptions={voteTypeOptions}
                    statusCardOptions={statusCardOptions}
                    handleValueSelect={handleValueSelect}
                    handleBlur={handleBlur}
                    openCollapse={openCollapse}
                    filterState={filterState}
                    resetFilter={resetFilter}
                    paramsData={paramsData}
                />

                <TablesAll
                    error={error}
                    nameThead={nameThead}
                    load={load}
                    cardId={id}
                    pushToModal={(pensId) => history.push(
                        `/admin/citizens/${idPage || '1'}/${pensId}${searchParamsFromURI || ''}`,
                    )}
                    dataTbody={dataTbody}
                    helperTdrender={helperTdrender}
                    handleChangePagination={handleChangePagination}
                    page={+idPage}
                    selectPage={nav.selectPage}
                    handleChangeSelectPagination={handleChangeSelectPagination}
                />

                <ViewCitizen
                    pensId={id}
                    getData={getData}
                    key={id}
                />

                <DialogAdmin
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    widthDialog={556}
                    setIdCard={setIdCard}
                >
                    <CardCitizensEditPhone id={idCard} setOpenDialog={setOpenDialog} />
                </DialogAdmin>

                <DialogConfirmData setForciblyFetch={setForciblyFetch} />
            </div>
        </Fade>
    )
};

export default Citizen;