import React, { useLayoutEffect, useContext, useState } from 'react'
import {
    Switch, Route,
    Redirect, useRouteMatch
} from 'react-router-dom'
import { Container } from '@material-ui/core'
import Navbar from '../../Components/Navbar/Navbar'
import Citizen from '../Citizen/Citizen';
import Volunteers from '../Volunteers/Volunteers';
import PollingStation from '../PollingStation/PollingStation';
import LoaderForEnterAdminPage from '../../Components/LoaderForEnterAdminPage/LoaderForEnterAdminPage';
import Users from '../Users/Users';
import Organization from '../Organization/Organization';
import Scripts from '../Scripts/Scripts';
import AddScript from '../AddScript/AddScript';
import EditScript from '../EditScript/EditScript';
import { useManualActions } from '../../Context/ManualContext/ManualProvider'
import { FilterContext } from '../../Context/FilterContext/FilterContext';
import { ScriptContext } from '../../Context/ScriptsContext/ScriptsContext';
import {
    useAuthActions,
    useAuthState
} from '../../Context/AdminAuthContext/AdminAuthProvider';
import { getFromLocalStorage } from '../../utils/localStorage';
import { toRole } from '../../utils/toRole';
import { Footer } from 'Components/Footer/Footer';

const AdminPage = React.memo(props => {

    const [globalLoader, setGlobalLoader] = useState(true);

    const { getUserInfo } = useAuthActions();
    const { volunteer, fio, id, ...other } = useAuthState();
    const { getAllManuals } = useManualActions();
    const { fetchVoteType, fetchCardStatuses, fetchArea, fetchRegions } = useContext(FilterContext);
    const { fetchCitizenTypes } = useContext(ScriptContext);
    let { path } = useRouteMatch();

    //console.log('other = ', other)

    useLayoutEffect(() => {
        (async function () {
            try {
                if (id > 0) {
                    await fetchArea();
                    await fetchRegions();
                    await fetchVoteType();
                    await fetchCardStatuses();
                    await getAllManuals();
                    await fetchCitizenTypes();
                    setGlobalLoader(false);
                }
            } catch (e) {
                console.log(e);
                setGlobalLoader(false)
            }
        })();
    }, [id]);

    if (id === -1 && !globalLoader) {
        return <Redirect to='/admin_login' />
    }

     if (!getFromLocalStorage('token')) {
        return <Redirect to='/admin_login' />
    }

    if (globalLoader) {
        return <LoaderForEnterAdminPage />
    }

    return (
        <>
            <Navbar volunteer={volunteer} other={other} fio={fio} />
            <Container  maxWidth={false}>
                <Switch>

                    <Route exact path={'/admin'}><Redirect to={`${path}/citizens`}/></Route>

                    <Route exact path={[`${path}/citizens/:idPage?`,
                                        `${path}/citizens/:idPage/:id?`]} component={Citizen} />

                    <Route exact path={`${path}/volunteers/:idPage?`}>
                        {toRole(other.roles) === 'volunteer'
                            ? <Redirect to={`${path}/citizens`} />
                            : <Volunteers />}
                    </Route>

                    <Route exact path={`${path}/pollingstation/:idPage?`}>
                        {toRole(other.roles) === 'volunteer' || toRole(other.roles) === 'curator'
                            ? <Redirect to={`${path}/citizens`} />
                            : <PollingStation />}
                    </Route>

                    <Route exact path={`${path}/users/:idPage?`} component={Users}>
                        {toRole(other.roles) === 'volunteer' || toRole(other.roles) === 'curator'
                            ? <Redirect to={`${path}/citizens`} />
                            : <Users />}
                    </Route>

                    <Route exact path={`${path}/organizations/:idPage?`}>
                        {toRole(other.roles) === 'volunteer' || toRole(other.roles) === 'curator'
                            ? <Redirect to={`${path}/citizens`} />
                            : <Organization />}
                    </Route>

                    <Route exact path={`${path}/scripts/:idPage?`}>
                        {toRole(other.roles) === 'volunteer' || toRole(other.roles) === 'curator'
                            ? <Redirect to={`${path}/citizens`} />
                            :  <Scripts /> }
                    </Route>

                    <Route exact path={`${path}/script/add`}>
                        {toRole(other.roles) === 'volunteer' || toRole(other.roles) === 'curator'
                            ? <Redirect to={`${path}/citizens`} />
                            : <AddScript />}
                    </Route>

                    <Route exact path={`${path}/script/edit/:id`}>
                        {toRole(other.roles) === 'volunteer' || toRole(other.roles) === 'curator'
                            ? <Redirect to={`${path}/citizens`} />
                            : <EditScript />}
                    </Route>
                </Switch>
            </Container>
            <Footer />
        </>
    )
});

export default AdminPage;
