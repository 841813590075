export const toDate = (date, check) => {
    let config = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour:'2-digit',
        minute: '2-digit',
    };

    if(check === 'noTime') {
        delete config.hour;
        delete config.minute;
    }

    return new Intl.DateTimeFormat('ru-RU', config).format(new Date(date));
};