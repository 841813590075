import 'date-fns'
import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from "date-fns/locale/ru"
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TodayIcon from '@material-ui/icons/Today';
import {
    MuiPickersUtilsProvider,
    DatePicker,
    TimePicker,
} from '@material-ui/pickers'

import { Grid, InputAdornment, IconButton, Typography } from '@material-ui/core'

function DateTimePicker({ c,selectedDate, setSelectedDate }) {

    const handleDateChange = date => {
        setSelectedDate(date)
    };

    return (
        <>
            <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
                <Grid style={{ marginBottom: '12px' }}>
                    <Typography style={{ marginTop: '30px',marginBottom: '17px',fontSize: '20px', fontWeight: '500' }} >
                        Выберите дату и время звонка
                    </Typography>
                    <DatePicker
                        disableToolbar
                        /* InputAdornmentProps={{ position: "start" }} */
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <TodayIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="inline"
                        format="dd.MM.yyyy"
                        id="datePicker"
                        className={c.picker}
                        value={selectedDate}
                        onChange={handleDateChange}
                    />

                    <TimePicker
                        /* InputAdornmentProps={{ position: "start" }} */
                        id="timePicker"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <AccessTimeIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        ampm={false}
                        className={c.picker}
                        value={selectedDate}
                        onChange={handleDateChange}
                        style={{ marginLeft: '28px' }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>



        </>
    )
}

export default DateTimePicker
