import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useStyles } from './style';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectCheckboxSearch = ({label, twoLineLabel, options, changeHandler, value, readonly, selectedCheck, errorData, gray, noLabel, ind, state, name}) => {

    const classes = useStyles({readonly, gray, noLabel, search: true});

    const returnValue = () => {
        if(!selectedCheck) return '';
        return  selectedCheck === 1 ? value[0].name : `Выбрано ${selectedCheck} из ${options.length}`;
    };

    return (
        <>
            <Autocomplete
                name={name}
                multiple
                noOptionsText={'Не найдено'}
                disableClearable
                value={value}
                options={options}
                getOptionLabel={(option) => option.name}
                disableCloseOnSelect
                renderTags={() => null}
                style={{ width: '100%' }}
                classes={{
                    root: classes.vpAutocompleteRoot,
                    listbox: classes.vp_listbox
                }}
                renderOption={(option, { selected }) => {
                    return (
                        <React.Fragment>
                            <Checkbox
                                classes={{
                                    root: classes.rootCheckbox
                                }}
                                color={"primary"}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                disableRipple
                                style={{ marginRight: 8 }}
                                checked={state.some(elem => elem.id === option.id)}
                                onChange={changeHandler(option, name, ind)}
                            />
                            {option.name}
                        </React.Fragment>
                    )
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        error={Boolean(errorData)}
                        variant="filled"
                        margin="dense"//делает ниже
                        placeholder={returnValue()}
                        inputProps={{
                            ...params.inputProps,
                        }}
                        InputLabelProps={{
                            classes: {
                                root: twoLineLabel ? classes.vp_labelRootTwoLine : classes.vp_labelRoot,
                                shrink: classes.vp_shrink,
                                error: classes.vp_errorLabel
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true, //пропадает нижняя линия
                            classes: {
                                root: classes.vp_filedRoot,
                                focused: classes.vp_focused,
                                error: classes.vp_errorFiled,
                            },
                            readOnly: Boolean(readonly),
                        }}
                    />
                }
            />
        </>
    )
};

export default SelectCheckboxSearch;