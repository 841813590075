import React from 'react'

function EditPencil() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.243 15.9708H18V17.9708H0V13.7278L9.9 3.82779L14.142 8.07179L6.242 15.9708H6.243ZM11.313 2.41479L13.435 0.292787C13.6225 0.105316 13.8768 0 14.142 0C14.4072 0 14.6615 0.105316 14.849 0.292787L17.678 3.12179C17.8655 3.30931 17.9708 3.56362 17.9708 3.82879C17.9708 4.09395 17.8655 4.34826 17.678 4.53579L15.556 6.65679L11.314 2.41479H11.313Z" fill="#0848C0"/>
        </svg>
    )
}

export default EditPencil;
