import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
    wrapRightBlock: {
        display: 'flex',
        alignItems: 'center',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginBottom: '17px',
    },
    title: {
        fontWeight: '500',
        fontSize: '24px',
        color: theme.black,
    },
    statusTitle: {
        marginLeft: 5,
        fontSize: 14,
    },
    wrapStatusTitle: {
        fontSize: 14,
        marginRight: 23,
    },
    buttonStat: {
        textTransform: 'none',
        fontSize: 14,
        height: 35,
        marginLeft: 8,
        transition: 'all .3s'
    },
    lockedButton: {
        background: '#E41D37',
        color: '#fff',

        '&:hover': {
            background: '#FF203E'
        }
    },
    activeButton: {
        background: '#53BB63',
        color: '#fff',

        '&:hover': {
            background: '#5CD06E'
        }
    },
    rejectedButton: {
        background: '#AAA9A9',
        color: '#fff',

        '&:hover': {
            background: '#BDBCBC'
        }
    },
    moderationButton: {
        background: '#515990',
        color: '#fff',

        '&:hover': {
            background: '#606AAB'
        }
    },
    buttonStatusWrapper: {
        display: 'flex',
        marginLeft: 13
    }
}));