import React from 'react'
import { Typography } from '@material-ui/core'

function WelcomeText({ text }) {
    return (
        <>
            <Typography style={{ marginBottom: 15, marginTop: 15 }} >
                {text}
            </Typography>
        </>
    )
}

export default WelcomeText;
