import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    vpAutocompleteRoot: {
        '& .MuiFormControl-root': {
            margin: 0
        }
    },
    vp_filedRoot: {
        background: '#fff!important',
        border: '1px solid #e8e8e8',
        borderRadius: props => props.rounded ? 10 : 5,
        cursor: props => props.readonly ? 'pointer' : 'text',
        paddingTop: props => (props.readonly || props.noLabel) ? '0!important' : null,
        paddingLeft: props => (props.readonly || props.noLabel) ? '0!important' : null,

        '&:hover': {
            background: '#fff'
        },

        '& input': {
            boxSizing: props => (props.readonly || props.noLabel) && 'border-box',
            height: props => (props.readonly || props.noLabel) && 47,
            cursor: props => props.readonly ? 'pointer' : 'text',
            paddingLeft: props => (props.readonly || props.noLabel) && '12px!important',
            paddingTop: props => (props.readonly && !props.noLabel) && '23px!important',

            '&::selection': {
                background: 'transparent'
            }
        }
    },
    vp_labelRootTwoLine: {
        color: '#8A929D',
        fontSize: 14,
        marginTop: -5,
        width: '85%'
    },
    vp_labelRoot: {
        color: '#8A929D!important',
        fontSize: props => props.fz16 ? 16 : 14,
        paddingTop: 1
    },
    vp_shrink: {
        color: '#8A929D!important'
    },
    vp_focused: {
        backgroundColor: '#fff!important',
        border: `1px solid #8A929D`
    },
    vp_popper: {
        '& li': {
            color: 'tomato',
            fontSize: 15,
            textShadow: 'none',
            transition: 'all .25s',

            '&:hover': {
                background: '#BDCFFD'
            }
        }
    },
    vp_errorFiled: {
        border: '1px solid tomato'
    },

}));