import React, { useState, useEffect } from 'react'
import { Dialog, Typography, Button, CircularProgress, useMediaQuery } from '@material-ui/core'
import { useModalStyles } from './styles'
import { getFullCitizenInfo, getCitizenHistory, patchUpdateStatus, getCitizenComments, getCitizenQuestions, postMakeCall, postCitizenQuestions, getPollResultsForCard, /*postNewUik*/ } from '../../API/Citizens'
import InfoCloud from './InfoCloud'
import Commentary from './Commentary'
import History from './History'
import FailDialog from './FailDialog'
import CallBackDialog from './CallBackDialog'
import Script from './Script'
import CallBar from './CallBar'
import { useAuthState, useAuthActions } from '../../Context/AdminAuthContext/AdminAuthProvider'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import WelcomeText from './WelcomeText'
import DateTimePicker from './DateTimePicker';
import queryString from 'query-string';
import { isVolunteer } from '../../utils/roles';
import { successToast } from '../../utils/SuccessToast';
import CloseIcon from '@material-ui/icons/Close';
import { Container } from '@material-ui/core'
import { getFromLocalStorage, setToLocalStorage } from 'utils/localStorage'

export const CITIZEN_CARD_BREAKPOINT = 960

function ViewCitizen({ pensId, getData }) {

    const { volunteer, roles } = useAuthState();
    const isSmallScreen = useMediaQuery('(max-width: 960px)')
    const { getUserInfo } = useAuthActions();
    const hist = useHistory();
    const location = useLocation();
    const params = useParams();

    const [fullInfo, setFullInfo] = useState(null);
    const [history, setHistory] = useState([]);
    const [comments, setComments] = useState([]);
    const [failOpen, setFailOpen] = useState(false);
    const [callBackOpen, setCallBackOpen] = useState(false);
    const [answers, setAnswers] = useState({});
    const [showMap, setShowMap] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [callMade, setCallMade] = useState(() => !!localStorage.getItem(pensId));

    const isTypeSix = (volunteer?.citizen_type_id === 6) & (fullInfo?.assigned_to?.citizen_type_id === 6) 
    const hasNoPhone = !fullInfo?.phone

    const handleClose = () => {
        getData()
        let url = queryString.stringifyUrl({
            url: `/admin/citizens/${params.idPage || '1'}`,
            query: queryString.parse(location.search) || null
        });
        cleanData();
        hist.push(url)
    };

    const isAllQuestionsSelected = () => {
        if(answers.questions){
            
            const idsWithQuestions = answers.questions.reduce((acc, item, index) => {
                if(item.answers.length) acc.push(index)

                return acc
            },[])
            
            return idsWithQuestions.every((answerId) => Number.isFinite(selectedAnswers[answerId]))
        }

        return false
    }

    isAllQuestionsSelected()

    const [callInProgress, setCallInProgress] = useState(false)

    const [voteType, setVoteType] = useState(null); // заносим id успешного типа голосования
    const [reason, setReason] = useState(null); // заносим id неуспешного типа голосования
    const [callResult, setCallResult] = useState(null);  // 1-2 Успешно-Неуспешно
    const [success, setSuccess] = useState(null); // true/false
    const [isFetching, setIsFetching] = useState(false);

    const date = new Date(fullInfo ? fullInfo.date_recall : '');

    const makeCall = async () => {
        setCallInProgress(true)
        setTimeout(() => {
            setCallInProgress(false)
        },45000)
        await postMakeCall(fullInfo.id)
        localStorage.setItem(fullInfo.id, 'callMaded')
        setCallMade(true);
    };

    /* Условие, "Невозможно реактировать" */
    const cantEditExpr = !isVolunteer(roles) || (!volunteer) ||
        ((fullInfo && fullInfo.assigned_to) && (!!volunteer && !volunteer.citizen)) ||
        (fullInfo && fullInfo.card_status && fullInfo.card_status.is_end);


    /* Условие, "Вернитесь к предыдущей карточке" */
    const backtoPreviousExpr = (!!volunteer && !!volunteer.citizen && (volunteer.id != (fullInfo && fullInfo.assigned_to && fullInfo.assigned_to.id))) ||
        ((!!volunteer && !!volunteer.citizen) && (fullInfo && !fullInfo.assigned_to));

    /* Условие, "В работе" */
    const inWork = (!!volunteer && !!volunteer.citizen && !!volunteer.citizen.id && volunteer.citizen.id)
        ? (!!fullInfo && !!fullInfo.assigned_to && fullInfo.assigned_to.id) === (!!volunteer && !!volunteer.citizen && !!volunteer.citizen.id && volunteer.id) : false;
    //console.log('inWork', inWork);

    const c = useModalStyles();

    const getFullInfo = async () => {
        try{
            let res = await getFullCitizenInfo(pensId); //полная инфа о гражданине
            setFullInfo(res.data);
            let hsit = await getCitizenHistory(pensId);//ход решения
            setHistory(hsit.data);
            let comms = await getCitizenComments(pensId); //получение комментариев
            setComments(comms);
            let questions = await getCitizenQuestions(pensId); // вопросы (для волонтера)
            setAnswers(questions);
            if(!getFromLocalStorage(pensId, true)?.selectedAnswers){
                let result = await getPollResultsForCard(pensId); //ответы для вопросов
                if(result.length) {
                    setSelectedAnswers(result.map(i => i.answer_id));
                } else {
                    setSelectedAnswers(new Array(questions?.questions?.length || 0).fill(null)) //массив  из id выбранных ответов
                }
            }

            const storagedData = getFromLocalStorage(pensId, true)
            if(storagedData){
                setVoteType(storagedData?.success ? storagedData?.result : null)
                setReason(!storagedData?.success ? storagedData?.result : null)
                setCallResult(storagedData?.result)
                setSuccess(storagedData.success)
            }
        } catch (err) {
            throw err;
        }
    };

    const handleResultChange = (id, succ, result) => {

        const prevDataSaved = getFromLocalStorage(pensId, true) || {}

        if (succ) {
            setToLocalStorage({
                obj:{
                    key: fullInfo.id,
                    item:{
                        ...prevDataSaved,
                        reason: id,
                        success: succ,
                        result
                    }
                }
            })
            setVoteType(id);
            setReason(null)
        } else {
            setToLocalStorage({
                obj:{
                    key: fullInfo.id,
                    item:{
                        ...prevDataSaved,
                        reason: id,
                        success: succ,
                        result
                    }
                }
            })
            setReason(id);
            setVoteType(null)
        }
        setSuccess(succ);
        setCallResult(result)
    };

    const setCardInWorkMode = async () => {
        setIsFetching(true);
        await patchUpdateStatus(fullInfo.id, {
            in_work: true
        });
        try {
            await getUserInfo();
            await getFullInfo();
            setIsFetching(false)
        } catch{
            setIsFetching(false)
        }
    };

    const cleanData = async () => {
        setFullInfo(null);
        getUserInfo();
        setVoteType(null);
        setReason(null);
        setSuccess(null);
        setCallResult(null);
        setIsFetching(false);
        setShowMap(false)
    };

    useEffect(() => {
        if (pensId && !fullInfo) {
            getFullInfo();
        } else {
            setFullInfo(null);
            setHistory(null);
        }
    }, [pensId]);

    const onTopButtonClick = async () => { // клик на кнопку сохранить и отправить
        setIsFetching(true);
        if (selectedAnswers.some(i => i)) { //если есть выбранный ответ
            //отправка ответов на вопросы
            postCitizenQuestions(fullInfo.id, answers.id, {results: selectedAnswers.filter(i => i)});
        }
        /* if(uikValues.uik.id){
                postNewUik(fullInfo.id, uikValues)
            } */
        let dataObj = {
            success,
            call_result: callResult,
            vote_type: voteType,
            fail_reason: reason,
        };

        if (!success && reason === 5) {
            dataObj.recall = selectedDate.toISOString()
        }

        let res = await patchUpdateStatus(fullInfo.id, dataObj);

        if (res.data.id) {
            /* setOpen(false) */
            localStorage.removeItem(fullInfo.id)
            handleClose();
            cleanData();            
            successToast('Успешно')
        }
        setIsFetching(false)
    };

    const onAClick = e => {
        e.preventDefault();
        let path = hist.location.pathname;
        let foo = path.split('/').slice(0, 4);
        foo.push(volunteer.citizen.id);
        hist.push(foo.join('/'))
    };

    return (
        <Dialog
            onClose={handleClose}
            open={Boolean(pensId)}
            className={c.dialog}
            maxWidth={'lg'}
            scroll="paper"
            fullScreen={isSmallScreen}
            TransitionProps={{
                unmountOnExit: true,
                mountOnEnter: true
            }}
        >
            {isSmallScreen && (
                <div onClick={handleClose} className={c.closeSmall} >
                    <CloseIcon  />
                </div>
            )}
            {/*Шапка карточки*/}
            {Boolean(fullInfo) && (
                <div className={c.modal}>
                    {
                        isVolunteer(roles) && (
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={c.takeInProcess}
                                onClick={setCardInWorkMode}
                            >
                                Взять в работу
                            </Button>
                        )
                    }

                    {
                        inWork ?
                         <div className={c.inWorkButton}>В работе</div> : null
                    }

                    {cantEditExpr ?
                        <div className={c.cantTakeInProcess}>Нельзя взять в работу</div> :
                        null
                    }
                    {backtoPreviousExpr ?
                         <div className={c.backToPrevious}>
                             Вы не завершили обработку карточки
                             <span className={c.backText} onClick={onAClick}>
                                &nbsp;№{volunteer.citizen.id}
                            </span>
                         </div> :
                         null
                    }
                    {isFetching
                        ? <div className={c.loaderStatus}>
                            {<CircularProgress style={{ color: 'white' }} />}
                        </div>
                        : null
                    }

                    {/* Блок с ФИО телефоном, кнопками и номером id*/}
                    <Container>
                    <div className={c.titleRow}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: isSmallScreen ? '100%' : 'auto' }} >
                            <Typography className={c.title}>
                                {`${fullInfo.name} ${fullInfo.patronymic || ''} ${fullInfo.surname}`}
                            , {fullInfo?.birth_date ? `${fullInfo?.birth_date?.split('-')?.[0]} г.` || '' : ''}

                            </Typography>
                            <a style={{ fontWeight: 'bold', fontSize: '24px', textDecoration: 'none' }} href={`tel:${fullInfo.phone || ''}`}>
                                {fullInfo.phone ? '***' : 'Не указан'}
                            </a>

                            {
                                inWork && 

                                <Button
                                    color='primary'
                                    variant='contained'
                                    className={c.makeCallButt}
                                    onClick={makeCall}
                                    disabled={callInProgress || hasNoPhone}
                                >

                                    {callInProgress ? <div>Ожидайте входящий звонок... <CircularProgress size={15} style={{  marginLeft: 4, color: '#ffffff' }} /></div> : 'Позвонить'}
                                </Button>
                            }

                            <Typography>
                                <span style={{ fontWeight: 'bold' }}>Результат звонка:&nbsp;</span>
                                {fullInfo.vote_type ? fullInfo.vote_type.name : 'Не указан'}
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className={c.buttonRow}>
                                {(fullInfo.card_status && fullInfo.card_status.name === 'Успешно' && fullInfo.count_calls > 0)
                                    ? <Button
                                        variant='contained'
                                        className={c.greenButton}
                                        disableRipple
                                    >
                                        Кол-во звонков: {fullInfo.count_calls}
                                    </Button>
                                    : null
                                }
                                {(fullInfo.card_status && fullInfo.card_status.name !== 'Успешно' && fullInfo.count_calls > 0)
                                    ? <Button
                                        variant='contained'
                                        className={c.disabledButton}
                                        disableRipple
                                    >
                                        Кол-во звонков: {fullInfo.count_calls}
                                    </Button>
                                    : null
                                }
                                {!fullInfo.count_calls
                                    ? <Button
                                        variant='contained'
                                        className={c.disabledButton}
                                        disableRipple
                                    >
                                        Ожидает звонка
                                </Button>
                                    : null
                                }
                                {!fullInfo.card_status || fullInfo.card_status.name === 'Новая'
                                    ? <Button
                                        variant='contained'
                                        className={c.blueButton}
                                        disableRipple
                                    >
                                        Новая
                                </Button>
                                    : null
                                }

                                {(fullInfo.card_status && fullInfo.card_status.name === 'Успешно')
                                    ? <Button
                                        variant='contained'
                                        className={c.greenButton}
                                        disableRipple
                                    >
                                        Успешно
                                </Button>
                                    : null
                                }

                                {(fullInfo.card_status && fullInfo.card_status.name === 'Неуспешно')
                                    ? <Button
                                        variant='contained'
                                        className={c.redButton}
                                        disableRipple
                                    >
                                        Неуспешно
                                    </Button>
                                    : null
                                }

                                {(fullInfo.card_status && fullInfo.card_status.name === 'В обработке')
                                    ? <Button
                                        variant='contained'
                                        className={c.darkButton}
                                        disableRipple
                                    >
                                        В обработке
                                    </Button>
                                    : null
                                }
                                {(fullInfo.card_status && fullInfo.card_status.name === 'Повторная обработка')
                                    ? <Button
                                        variant='contained'
                                        className={c.darkButton}
                                        disableRipple
                                    >
                                        Повторная обработка
                                    </Button>
                                    : null
                                }

                                &nbsp;
                                <span style={{
                                    fontWeight: 500,
                                    fontSize: '24px',

                                }} > №&nbsp;{fullInfo.id}</span>
                            </div>

                            {fullInfo.date_recall && (
                                <span className={c.recallDate} >
                                    Дата звонка:&nbsp;
                                    {date.getDate() < 10 ? <>0{date.getDate()}</> : date.getDate()}.{date.getMonth() + 1 < 10 ? <>0{date.getMonth() + 1}</> : date.getMonth() + 1}.{date.getFullYear()}
                                &nbsp;Bремя звонка &nbsp;
                                    {date.getHours() < 10 ? <>0{date.getHours()}</> : date.getHours()}:{date.getMinutes() < 10 ? <>0{date.getMinutes()}</> : date.getMinutes()}
                                </span>
                            )}
                        </div>
                    </div>

                    <InfoCloud
                        c={c}
                        mapIsVisible={showMap}
                        toggleMap={() => setShowMap(!showMap)}
                        fullInfo={fullInfo}
                        countCalls={fullInfo.count_calls}
                    />

                    {/*если карточка в работе выводим текст приветствия*/}
                    {inWork ? <WelcomeText text={answers.text} /> : null}

                    {/*компонент с вопросами*/}
                    <Script
                        inWork={inWork}
                        pensId={pensId}
                        c={c}
                        setAnswers={setAnswers}
                        answers={answers}
                        selectedAnswers={selectedAnswers}
                        setSelectedAnswers={setSelectedAnswers}
                    />

                    {(!fullInfo.card_status || fullInfo.card_status.name === 'Новая') && !fullInfo.assigned_to
                        ? null
                        : <>
                            {fullInfo.card_status && fullInfo.card_status.is_end
                                ? null
                                : inWork && <>
                                    <CallBar
                                        hasNoPhone={hasNoPhone}
                                        selected={fullInfo.card_status}
                                        isEnd={fullInfo.card_status && fullInfo.card_status.is_end}
                                        callMade={callMade}
                                        isTypeSix={isTypeSix}
                                        pensId={pensId}
                                        setVoteType={setVoteType}
                                        setReason={setReason}
                                        c={c}
                                        isAllQuestionsSelected={isAllQuestionsSelected}
                                        handleResultChange={handleResultChange}
                                        voteTypes={answers.vote_types}
                                        failReasons={answers.fail_reasons}
                                    />
                                    {/* {voteType && voteType == 6 && success
                                        ? <UIKselect setUikValues={setUikValues} c={c} />
                                        : null
                                    } */}
                                    {reason && reason === 5 && !success
                                        ? <DateTimePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} c={c} />
                                        : null
                                    }
                                </>
                            }

                            {/* Блок с комментариями*/}
                            <Commentary c={c} comments={comments} id={fullInfo.id} getFullInfo={getFullInfo} />

                            {(voteType || reason)
                                ? <div style={{ width: " 100%" }}>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        className={c.saveButton}
                                        onClick={onTopButtonClick}
                                    >
                                        Сохранить изменения и закрыть
                                    </Button>
                                </div>
                                : null

                            }

                            {/* Компонент ход решения*/}
                            <History c={c} history={history} />

                            {failOpen
                                ? <FailDialog
                                    open={failOpen}
                                    setOpen={setFailOpen}
                                    getFullInfo={getFullInfo}
                                    pensId={fullInfo.id}
                                    setFullInfo={setFullInfo}
                                    c={c}
                                />
                                : null
                            }

                            {callBackOpen
                                ? <CallBackDialog
                                    open={callBackOpen}
                                    setOpen={setCallBackOpen}
                                    getFullInfo={getFullInfo}
                                    pensId={fullInfo.id}
                                    setFullInfo={setFullInfo}
                                    c={c}
                                />
                                : null
                            }
                        </>
                    }
                    </Container>
                </div>
            )}
        </Dialog>
    )
}

export default ViewCitizen;