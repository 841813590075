import React, { useState, useEffect,
    useContext}                         from 'react';
import { useParams, useHistory,
    useLocation }                       from 'react-router-dom';
import queryString                      from 'query-string';
import Fade                             from '@material-ui/core/Fade';
import TablesAll                        from '../../Components/TablesAll/TablesAll';
import FilterBlockOrganizations         from '../../Components/FilterBlocks/FilterBlockOrganizations';
import { postOrganizationTableList }    from '../../API/OrganizationTableList';
import FilterIcon                       from '../../css/svg/FilterIcon';
import Button                           from '@material-ui/core/Button';
import { useStyles }                    from './style';
import {FilterContext}                  from '../../Context/FilterContext/FilterContext';
import { useMediaQuery } from '@material-ui/core';

const Organization = () => {
    const classes = useStyles();
    const { fetchOmsu, omsuOptions, regionsOptions } = useContext(FilterContext);

    const history = useHistory();
    const location = useLocation();
    const { idPage = "1" } = useParams();

    const [load, setLoad] = useState(true);
    const [error, setError] = useState(false);
    const [dataTbody, setDataTbody] = useState(null);
    const [openCollapse, setOpenCollapse] = useState(true);
    const [nav, setNav] = useState({
        page: idPage ? +idPage : 1,
        selectPage: 10,
    });

    const [filterState, setFilterState] = useState({ //coстояние для селектов и инпутов филтра
        omsu: null,
        region: null,
        title: '',
        address: '',
        phone: ''
    });

    const nameThead = ['id', 'Федеральный округ', 'Регион', 'Муниципальное образование', 'Название', 'Кол-во волонтеров', 'Кол-во избрательных участков', 'Кол-во пенсионеров'];

    useEffect(() => { //установка значений в селект при первой загрузке с квери параметрами
        setFilterState({
            ...filterState,
            region: helperStateFilter("filters[region_id]", regionsOptions),
            phone: queryString.parse(location.search)["filters[phone]"] || '',
            title: queryString.parse(location.search)["filters[title]"] || '',
            address: queryString.parse(location.search)["filters[address]"] || '',
        })
    },[]);

    useEffect(() => {
        setError(false);
        setLoad(true);

        let queryStringFilters = queryString.parse(location.search);
        delete queryStringFilters["filters[region_id]"];

        setDataTbody(null);

        postOrganizationTableList(idPage, nav.selectPage, queryStringFilters).then((res) => {
            setDataTbody(res);
            setLoad(false);
        }).catch(e =>{
            setError(e);
            setLoad(false);
        })
    }, [idPage, nav.selectPage, location.search]);

    useEffect(() => { // при изменении округа меняем omsu
        if(filterState.region) {
            fetchOmsu({"filters[region_id]": filterState.region.id}).then((res) => {

                const omsuFind = helperStateFilter("filters[omsu_id]", res);

                setFilterState({
                    ...filterState,
                    omsu: omsuFind ? omsuFind : null
                })
            })
        }
    }, [filterState.region]);

    const helperStateFilter = (nameFilter, filterOptions) => { //при наличии query-параметра находим в списке селекта нужный элемент
        const objFilters = queryString.parse(location.search);
        if(Object.keys(objFilters).length && nameFilter in objFilters) {
            return filterOptions.find(elem => elem.id.toString() === objFilters[nameFilter].toString());
        }
        return null;
    };

    const helperTdrender = (data) => {
        return [data.id ? data.id : '-',
                data.omsu && data.omsu.region ? data.omsu.region.area.name : '-',
                data.omsu && data.omsu.region ? data.omsu.region.name : '-',
                data.omsu ? data.omsu.name : '-',
                data.title,
                '-', '-', '-']
    };

    const handleChangePagination = (event, value) => { //обработчик для пагинации
        const url = location.search
            ? `/admin/organizations/${value}${location.search}`
            : `/admin/organizations/${value}`;

        history.push(url);
        setNav({
            ...nav,
            page: value
        });
    };

    const handleChangeSelectPagination = (event) => { //обработчик селекта пагинации
        const url = location.search
            ? `/admin/organizations${location.search}`
            : `/admin/organizations`;

        history.push(url);

        setNav({
            ...nav,
            selectPage: event.target.value,
            page: 1,
        });
    };

    const handleValueSelect = (name) => (event, val) => { //обработчик для селектов фильтра
        setFilterState({
            ...filterState,
            [name]: val,
        })
    };

    const handleBlur = (event) => { //обработчки на инпуты в фильтрах
        setFilterState({
            ...filterState,
            [event.target.name]: event.target.value
        })
    };

    const paramsData = () => {
        let paramsObject = {};
        if(filterState.region && filterState.omsu) paramsObject["filters[region_id]"] = filterState.region.id;
        if(filterState.omsu) paramsObject["filters[omsu_id]"] = filterState.omsu.id;
        if(filterState.title) paramsObject["filters[title]"] = filterState.title;
        if(filterState.address) paramsObject["filters[address]"] = filterState.address;
        if(filterState.phone) paramsObject["filters[phone]"] = filterState.phone;

        return paramsObject;
    };

    const resetFilter = () => {
        setFilterState({
            ...filterState,
            region: null,
            omsu: null,
            title: '',
            address: '',
            phone: ''
        });

        history.push('/admin/organizations');
    };

    const isSmallScreen = useMediaQuery('(max-width: 550px)')

    return (
        <Fade timeout={800} in>
            <div className={classes.organizations}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>Реестр организаций</h3>

                        <Button
                            variant="contained"
                            className={`${classes.buttonFilter} ${!openCollapse ? classes.buttonFilterClose : ''}`}
                            disableElevation
                            endIcon={isSmallScreen ? null  : <FilterIcon openCollapse={openCollapse} />}
                            onClick={() => setOpenCollapse(!openCollapse)}
                        >
                            {isSmallScreen ? <FilterIcon openCollapse={openCollapse} />  : ('Фильтры')}
                        </Button>
                    </div>
                </div>

                <FilterBlockOrganizations
                    regionsOptions={regionsOptions}
                    omsuOptions={omsuOptions}
                    handleValueSelect={handleValueSelect}
                    handleBlur={handleBlur}
                    openCollapse={openCollapse}
                    resetFilter={resetFilter}
                    filterState={filterState}
                    paramsData={paramsData}
                />

                <TablesAll
                    error={error}
                    otherFunctionOpenModal={() => {}}
                    nameThead={nameThead}
                    load={load}
                    dataTbody={dataTbody}
                    helperTdrender={helperTdrender}
                    handleChangePagination={handleChangePagination}
                    page={+idPage}
                    selectPage={nav.selectPage}
                    handleChangeSelectPagination={handleChangeSelectPagination}
                />
            </div>
        </Fade>
    );
};

export default Organization;