import React from "react"
import { reducer } from "./ManualReducer"
import { getVoteTypes, getCallResults, getCallBackReasons, getFailReasons } from "../../API/Manual"
import { useHistory } from 'react-router-dom'
import { removeFromLocalStorage } from "../../utils/localStorage"

const ManualActionsContext = React.createContext()
const ManualStateContext = React.createContext()

export function ManualProvider({ children }) {
    const history = useHistory()

    const initialState = {
        voteTypes: [],
        callResults: [],
        callBackReasons: [],
        failReasons: []
    };

    const [state, dispatch] = React.useReducer(reducer, initialState)

    const getAllManuals = async (login, password) => {
        const voteTypes = Promise.resolve(getVoteTypes());
        const cResults = Promise.resolve(getCallResults())
        //const cbReason = Promise.resolve(getCallBackReasons())
        const failReason = Promise.resolve(getFailReasons())
        Promise.all([voteTypes, cResults, /*cbReason,*/ failReason])
            .then((resArr) => {
                dispatch({ type: 'SET_MANUALS', payload: resArr })
            })
            .catch(() => {
                removeFromLocalStorage('token')
                return history.push('/admin_login')
            })
    };

    const actions = {
        getAllManuals,
    };

    return (
        <ManualStateContext.Provider value={state}>
            <ManualActionsContext.Provider value={actions}>
                {children}
            </ManualActionsContext.Provider>
        </ManualStateContext.Provider>
    )

}

export function useManualState() {
    return React.useContext(ManualStateContext)
}

export function useManualActions() {
    return React.useContext(ManualActionsContext)
}