import React, { useContext }                        from 'react';
import NameAndQuestions                             from '../../Components/NameAndQuestions/NameAndQuestions';
import TypeAndGeography                             from '../../Components/TypeAndGeography/TypeAndGeography';
import Fade                                         from '@material-ui/core/Fade';
import { FilterContext }                            from '../../Context/FilterContext/FilterContext';
import { ScriptContext }                            from '../../Context/ScriptsContext/ScriptsContext';
import { postNewScript }                            from '../../API/Scripts';
import { useHookAddanEditScript }                   from '../../HOC/useHookAddanEditScript/useHookAddanEditScript';
import { useStyles }                                from './style';

const AddScripts = () => {
    const classes = useStyles();
    const { regionsOptions } = useContext(FilterContext);
    const { citizenTypesOptions } = useContext(ScriptContext);

    const {
        state,
        setState,
        load,
        errors,
        handleBlur,
        handleBlurMulti,
        changeHandler,
        handleValueSelect,
        handleChangeRadio,
        postScript,
        addNewTypeFailReson,
        addNewTypeVote
    } = useHookAddanEditScript(postNewScript);

    return (
        <Fade timeout={800} in>
            <div className={classes.containerAddScript}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>Создание скрипта</h3>
                    </div>
                </div>

                <div className={classes.fieldsContent}>
                    <div className={classes.nameAndQuestions}>
                        <NameAndQuestions
                            state={state}
                            citizenTypesOptions={citizenTypesOptions}
                            handleBlur={handleBlur}
                            handleBlurMulti={handleBlurMulti}
                            handleValueSelect={handleValueSelect}
                            setState={setState}
                            createOrEditScript={postScript}
                            load={load}
                            errors={errors}
                        />
                    </div>

                    <div className={classes.typeAndGeography}>
                        <TypeAndGeography
                            errors={errors}
                            handleBlur={handleBlur}
                            changeHandler={changeHandler}
                            state={state}
                            setState={setState}
                            addNewTypeFailReson={addNewTypeFailReson}
                            addNewTypeVote={addNewTypeVote}
                            load={load}
                            regionsOptions={regionsOptions}
                            handleChangeRadio={handleChangeRadio}
                        />
                    </div>
                </div>
            </div>
        </Fade>
    )
};

export default AddScripts;