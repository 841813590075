import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useManualState } from '../../Context/ManualContext/ManualProvider';
import { postNewTypeReasons, postNewTypeVote } from '../../API/Scripts';
import { successToast } from '../../utils/SuccessToast';
import { errorToast } from '../../utils/ErrorToast';

export const useHookAddanEditScript = (postFunc, loadPage = false) => {
    const { id = null } = useParams();
    const history = useHistory();

    const { failReasons, voteTypes } = useManualState();

    const [errors, setErrors] = useState({});
    const [load, setLoad] = useState({
        loadButtonFailReason: false,
        loadButtonVote: false,
        loadPostScript: false
    });

    const [state, setState] = useState({
        nameScript: '',
        textScript: '',
        text_goodbye: '',
        questions: [
            {
                order_by: 1,
                title: '',
                text: '',
                answers: [{ title: '', order_by: 1 }]
            }
        ],
        voteTypesOptions: [],
        voteTypes: [],
        failReasonOptions: [],
        failReason: [],
        addTypeFailReason: '',
        addTypeVote: '',
        close_card: 1,
        regions: [],
        omsu: [],
        omsuOptions: [],
        type_id: null,
        phone_type_mobile: false,
        phone_type_city: false
    });

    useEffect(() => { //кладем в состояние базовые успешные типы звонков
        if (voteTypes.length && !loadPage) {
            setState(prevState => ({ ...prevState, voteTypesOptions: voteTypes }));
        }
    }, [voteTypes, loadPage]);

    useEffect(() => { //кладем в состояние базовые неуспешные типы звонков
        if (failReasons.length && !loadPage) {
            setState(prevState => ({ ...prevState, failReasonOptions: failReasons }));
        }
    }, [failReasons, loadPage]);

    const handleBlur = (event) => { //обработчки на статические инпуты
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleChangeRadio = (event) => {
        setState({
            ...state,
            close_card: +event.target.value
        });
    };

    const handleBlurMulti = (index, indexLevel2 = null) => (event) => { //обработчки на динамические инпуты
        const questions = [...state.questions];

        if (indexLevel2 === null) {
            questions[index][event.target.name] = event.target.value;
        } else {
            questions[index][event.target.name][indexLevel2].title = event.target.value;
        }

        setState({ ...state, questions })
    };

    const changeHandler = (option, name) => async (event) => { //клик на чекбокс в селекте
        try {
            if (event.target.checked) {
                setState({
                    ...state,
                    [name]: [...state[name], option]
                })
            } else {
                setState({
                    ...state,
                    [name]: state[name].filter(elem => elem.id !== option.id),
                })
            }
        } catch (err) {
            throw err
        }
    };

    const handleValueSelect = (name) => (event, val) => { //обработчик для селекта типа
        setState({
            ...state,
            [name]: val,
        })
    };

    const addNewTypeFailReson = async () => { // добавление нового типа неуспешного звонка
        if (state.addTypeFailReason.trim()) {
            try {
                setLoad({ ...load, loadButtonFailReason: true });
                const body = new FormData();
                body.append('name', state.addTypeFailReason);
                body.append('close_card', Boolean(state.close_card));

                const res = await postNewTypeReasons(body);
                setState({
                    ...state,
                    failReason: [...state.failReason, res],
                    failReasonOptions: [...state.failReasonOptions, res],
                    addTypeFailReason: '',
                });
                setLoad({ ...load, loadButtonFailReason: false });
                successToast('Новый тип успешно добавлен')
            } catch (err) {
                setLoad({ ...load, loadButtonFailReason: false });
                throw err
            }
        }
    };

    const addNewTypeVote = async () => { //добавление нового типа успешного звонка
        if (state.addTypeVote.trim()) {
            try {
                setLoad({ ...load, loadButtonVote: true });
                const body = new FormData();
                body.append('name', state.addTypeVote);
                body.append('ordering', state.voteTypesOptions.length + 1);

                const res = await postNewTypeVote(body);
                setState({
                    ...state,
                    voteTypes: [...state.failReason, res],
                    voteTypesOptions: [...state.voteTypesOptions, res],
                    addTypeVote: '',
                });
                setLoad({ ...load, loadButtonVote: false });
                successToast('Новый тип успешно добавлен')
            } catch (err) {
                setLoad({ ...load, loadButtonVote: false });
                throw err
            }
        }
    };

    const postScript = async () => { //отправка данных на сервер для создания нового скрипта или редактирования существующего
        if (state.nameScript.trim() && state.textScript.trim() && state.text_goodbye.trim() && state.type_id) {
            const body = {};
            body.title = state.nameScript;
            body.text = state.textScript;
            body.text_goodbye = state.text_goodbye;
            body.phone_type_mobile = state.phone_type_mobile
            body.phone_type_city = state.phone_type_city
            if (state.regions.length) body.regions = state.regions.map(item => item.id);
            if (state.failReason.length) body.fail_reasons = state.failReason.map(item => item.id);
            if (state.voteTypes.length) body.vote_types = state.voteTypes.map(item => item.id);
            if (state.questions.filter(item => item.title && item.title.trim()).length) {
                const question = [...state.questions.filter(item => item.title && item.title.trim())]; //убираем вопросы без названий
                question.forEach((item, index) => {
                    question[index].answers = item.answers.filter(i => i.title); //убираем ответы без названий
                });
                body.questions = question;
            }
            if (state.type_id) body.type_id = state.type_id.id;
            setLoad({ ...load, loadPostScript: true });

            try {
                const res = await postFunc(body, id);
                if (res && res.id) {
                    successToast(id ? 'Скрипт успешно отредактирован' : 'Новый скрипт успешно создан');
                    history.push('/admin/scripts');
                } else if (res && res.errors) {
                    setLoad({ ...load, loadPostScript: false });
                    errorToast(`[Ошибка]: ${res.errors.detail}`);
                }
            } catch (err) {
                setLoad({ ...load, loadPostScript: false });
                errorToast(`[Ошибка]: ${err}`);
                throw err;
            }
        } else {
            setErrors({
                nameScript: !state.nameScript.trim() ? 'Поле не должно быть пустым' : false,
                textScript: !state.textScript.trim() ? 'Поле не должно быть пустым' : false,
                text_goodbye: !state.text_goodbye.trim() ? 'Поле не должно быть пустым' : false,
                type_id: !state.type_id ? 'Выберите тип скрипта' : false,
            })
        }
    };

    return {
        state,
        setState,
        load,
        errors,
        handleBlur,
        handleBlurMulti,
        changeHandler,
        handleChangeRadio,
        handleValueSelect,
        postScript,
        addNewTypeFailReson,
        addNewTypeVote
    }
};