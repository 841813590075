import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    users: {
      paddingBottom: 50,
    },
    wrapHeader: {
        paddingTop: 33,
    },
    headers: {
        display: 'flex',
        marginBottom: 42,
    },
    vp_headingPage: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 36,
        lineHeight: '43px',
        color: theme.black,
        flex: 1,
    },
    buttonFilter: {
        width: 147,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#00A4D8',
        fontSize: 14,
        color: '#fff',
        transition: 'all .3s',

        '&:hover': {
            background: '#00B8F0',
        }
    }
}));