import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead } from '@material-ui/core'
import { useModalStyles } from './styles'

function Statisctics ({ statistics }) {

    const c = useModalStyles();

    return (
        <div className={c.statisticTable}>
            <h3 className={c.tableHeading}>Статистика оператора</h3>
            <Table className={c.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Взятые заявки</TableCell>
                        <TableCell align="left">Отказные</TableCell>
                        <TableCell align="left">Успешные</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="left">{statistics.orders}</TableCell>
                        <TableCell align="left">{statistics.fail || 0}</TableCell>
                        <TableCell align="left">{statistics.success || 0}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}

export default Statisctics;
