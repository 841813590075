

export const reducer = (state, action) => {
    switch (action.type){
    case "SET_USER_INFO":
        return{
            ...state,
            ...action.data
        }

    case "SET_ERROR_AUTH":
        return{
            ...state,
            isLogged: false,
            error: true
        }
            
    case "CLEAR_ERROR":
        return {
            ...state,
            error: false
        }
    case "AUTH_FAILED":
        return{
            ...state,
            id: 0,
        }
    }
}