import { Button } from '@material-ui/core';
import React from 'react'
import { NavLink } from 'react-router-dom'
import { toRole } from 'utils/toRole';
import AdminStar from 'css/svg/AdminStar';


const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
};

export const DesktopNavbar = ({
    c,links,other, volunteer,logout,fio

}) => {

    const renderLinks = () => {
        switch(toRole(other.roles)) {
            case 'admin':
                return links;

            case 'volunteer':
                return links.filter(link => link.volunteerViews);

            case 'curator':
                return links.filter(link => link.curatorViews);

            default:
                return links;
        }
    };

    return (
        <>
            <div className={c.leftGroup}>
                <div className={c.logo} style={{ minWidth: 58 }} >
                    {/* <img src={logo} width="58" height="62" /> */}
                </div>
                {renderLinks().map(link =>
                    <NavLink
                        key={link.path}
                        to={link.path}
                        activeClassName={c.activeLink}
                        className={c.link}
                    >
                        {link.title}
                    </NavLink>
                )}
            </div>

            <div className={c.rightGroup}>
                <div className={c.username}>
                    <div className={c.vp_fio}>
                        {`${volunteer ? capitalize(volunteer.name) : ''} ${volunteer ? capitalize(volunteer.surname) : ''} ${!volunteer ? fio || 'Нет фио' : ''}`}

                        <Button
                            size="small"
                            className={c.buttonLogout}
                            onClick={logout}
                        >Выйти</Button>
                    </div>

                    <div className={`${c.vpRole} ${other.roles.length > 1 ? c.cursorPointer : ''}`}>
                        <span className={c.containerRole} >
                            {other.roles[0].name}
                            {other.roles[0].system_name === 'admin' ? <span className={c.wrapIcon}><AdminStar /> </span>: null }
                        </span>
                    </div>

                    
                </div>
            </div>
        </>
    )
}
