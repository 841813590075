import React                                from 'react'
import Table                                from '@material-ui/core/Table';
import TableBody                            from '@material-ui/core/TableBody';
import TableCell                            from '@material-ui/core/TableCell';
import Pagination                           from '@material-ui/lab/Pagination';
import TableHead                            from '@material-ui/core/TableHead';
import TableRow                             from '@material-ui/core/TableRow';

function ClaimsTable({ c, tableData, status, handleChangePagination, page }) {

    const toName = (row) => {
        return `${row.surname} ${row.name} ${row.patronymic}`
    };

    const toStatus = (row) => {
        return status.find(elem => elem.count_calls === row.card_status.count_calls).name;
    };

    return (
        <div className={c.claimsTable}>
            <h3 className={c.tableHeading}>Заявки оператора</h3>
            <Table className={c.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">ФИО пенсионера</TableCell>
                        <TableCell align="left">Текущий статус</TableCell>
                        <TableCell align="left">Количество звонков</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.entries.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="left">{toName(row)}</TableCell>
                            <TableCell align="left">{toStatus(row)}</TableCell>
                            <TableCell align="left">{row.count_calls}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {
                tableData.total_pages > 1 ?
                    <div className={c.paginWrap}>
                        <Pagination
                            shape="rounded"
                            count={tableData.total_pages}
                            page={page}
                            onChange={handleChangePagination}
                            classes={{
                                root: c.vp_pagRoot,
                                ul: c.vp_ul
                            }}
                        />
                    </div> : !tableData.entries.length
                                ? <div className={c.noData}>Данные отсутствуют</div>
                                : null
            }

        </div>
    )
}

export default ClaimsTable;
