import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    mainRoot: {
        width: '100%',
        margin: 0,
        background: '#fff',
        borderRadius: props => props.rounded ? 10 : 5,
    },
    vp_filedRoot: {
        background: '#fff',
        border: props => props.redBorder ? '1px solid tomato' : '1px solid #e8e8e8',
        borderRadius:  props => props.rounded ? 10 : 5,

        '& input': {
            height: props => props.noLabel && 48,
            boxSizing: props => props.noLabel && 'border-box',
            paddingTop: props => props.noLabel && 7,
        },

        '& textarea': {
            minHeight: 125
        },

        '& #newPhone, & #smsCode': {
            paddingTop: 17,
            paddingBottom: 12,
        },

        '& #phoneConfirm': {
            paddingTop: 17,
            paddingBottom: 12,
        },

        '&:hover': {
            background: '#fff'
        },

        '& input[type="number"]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },

        '& input[type="number"]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },

        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },

        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },

        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
    },
    vp_labelRoot: {
        color: '#8A929D',
        fontSize: 14,
        paddingTop: 1
    },
    vp_shrink: {
        color: '#8A929D!important'
    },

    vp_disabled: {
        color: theme.black
    }
}));