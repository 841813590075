import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    wrapFilter: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 16,
        paddingLeft: 8,
        paddingRight: 8,
        background: '#F8F8F8',
        borderRadius: 6,
        marginBottom:30,
    },/* 
    sectionFilter: {
        width: 'calc(25% - 16px)',
        marginRight: 8,
        marginLeft: 8,
        marginBottom: 16,
    }, */
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonApply: {
        width: 'calc(50% - 5px)',
        height: 48,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#004B92',
        fontSize: 14,
        color: '#fff',
        transition: 'all .3s',

        '&:hover': {
            background: '#005EB6',
        }
    },
    buttonReset: {
        width: 'calc(50% - 5px)',
        height: 48,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        borderColor: '#004B92',
        fontSize: 14,
        color: '#004B92',
        transition: 'all .3s',
    }
}));