import instance from './instance';
import {removeFromLocalStorage} from "../utils/localStorage";

export const fetchPollsList = async (page = 1, perPage = 10, filters = {}) => {
    try {
        let res = await instance
            .get(
                `/api/poll`,
                {
                    params: {
                        page: page,
                        page_size: perPage,
                        ...filters
                    }
                });
        return res.data
    } catch (err) {
        console.log('ОШИБКА', err.response.data.errors);
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const removeScript = async (id) => { // создание нового скрипта
    try {
        let res = await instance.delete(`/api/poll/${id}`);
        return res.data;
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

