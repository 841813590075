import React, {useState, useEffect }                        from 'react';
import { getFullCitizenInfo, changePhoneNumberInCard }      from '../../API/Citizens';
import LoadingDialog                                        from '../LoadingDialog/LoadingDialog';
import FieldFilter                                          from '../../Components/FieldFilter/FieldFilter';
import Button                                               from '@material-ui/core/Button';
import { useModalStyles }                                   from './style';

const CardCitizensEditPhone = ({ id, setOpenDialog }) => {

    const c = useModalStyles();
    const [loadCard, setLoadCard] = useState(true);

    const [data, setData] = useState({
        fullInfo: {},
        newPhone: '',
        dirty: false,
        loadSend: false,
    });

    useEffect(() => {
        (async () => {
            try {
                let fullInfo = await getFullCitizenInfo(id); // заменить на id
                setData({ ...data, fullInfo: fullInfo.data });
                setLoadCard(false);
            } catch (e) {
                console.log(e)
            }
        })()
    }, []);

    const handleBlur = (event) => { //обработчки на инпуты
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    };

    const edinPhone = async () => {
        setData({
            ...data,
            dirty: true
        });

        if(data.newPhone.length === 15) {
            setData({...data, loadSend: true});
            try {
                const res = await changePhoneNumberInCard(id, data.newPhone);
            } catch (e) {
                console.log(e)
            } finally  {
                setData({...data, loadSend: false});
                setOpenDialog(false)
            }
        }
    };

    if(loadCard) {
        return <LoadingDialog height={235}/>
    }

    return (
        <div>
            <p className={c.headingInput}>Текущий номер телефона</p>
            <p className={c.oldPhone}>{data.fullInfo.phone}</p>

            <p className={c.headingInput}>Новый номер телефона</p>
            <div className={c.sectionFilter}>
                <FieldFilter
                    key={data.newPhone}
                    value={data.newPhone}
                    onBlur={handleBlur}
                    name={'newPhone'}
                    maskInput={['8','(',/[1-9]/, /\d/, /\d/,')', /\d/, /\d/,/\d/, '-', /\d/, /\d/,'-',/\d/, /\d/]}
                    placeholdermask={'8(XXX)XXX-XX-XX'}
                />
            </div>
            {
                data.dirty && data.newPhone.length !== 15
                    ? <p className={c.error}>Номер телефона не корректный</p>
                    : null
            }
            <Button
                variant="contained"
                className={c.buttonSave}
                disableElevation
                onClick={edinPhone}
            >
                { data.loadSend ? 'Подождите...' : 'Сохранить изменения' }
            </Button>
        </div>
    )
};

export default CardCitizensEditPhone;