import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    typeAndGeography: {
        paddingLeft: 40,
        boxSizing: 'border-box'
    },
    headingTypeAndGeography: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '24px',
        color: '#333333',
        marginBottom: 10,
    },
    typeCallContainer: {
        display: 'flex',
        marginBottom: 40,
    },
    typeCall: {
        width: '50%',

        '&:first-child': {
            paddingRight: 14,
        },

        '&:last-child': {
            paddingLeft: 14,
        }
    },
    typeHeading: {
        fontFamily: 'Rubik',
        fontSize: 16,
        lineHeight: '19px',
        color: '#333333',
        fontWeight: 'normal',
        marginBottom: 10,
    },
    wrapSelectCheckbox: {
        marginBottom: 20,
    },
    sectionField: {
        marginBottom: 15,
    },
    buttons: {
        display: 'flex'
    },
    buttonClose: {
        width: 160,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#E41D37',
        fontSize: 14,
        color: '#fff',
        marginRight: 15,
        transition: 'all .3s',

        '&:hover': {
            background: '#FF556F',
        }
    },
    buttonAdd: {
        width: 160,
        height: 45,
        fontFamily: 'Rubik',
        fontSize: 14,
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#1056E3',
        color: '#fff',
        marginRight: 20,
        transition: 'all .3s',

        '&:hover': {
            background: '#2167F4',
        }
    },
    longField: {
        width: '60%',
        marginBottom: 17,
    },
    error: {
        color: 'red'
    },
    radioWrap: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        bottom: 8
    },
    radioLabel: {
        fontFamily: 'Rubik',
        fontSize: 14,
        color: '#333333',
        marginRight: 10,
    },
    label: {
        fontFamily: 'Rubik',
        fontSize: 14,
        color: '#333333',
    }

}));