import React from 'react';

const AdminStar = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.54635 0.344423C3.68914 -0.114808 4.31086 -0.114807 4.45365 0.344424L5.07092 2.32966C5.13477 2.53503 5.31792 2.67408 5.52457 2.67408H7.52209C7.98417 2.67408 8.17629 3.29197 7.80246 3.57579L6.18643 4.80273C6.01925 4.92966 5.94929 5.15465 6.01315 5.36002L6.63042 7.34526C6.77321 7.80449 6.27023 8.18636 5.8964 7.90254L4.28037 6.6756C4.11319 6.54867 3.88681 6.54867 3.71963 6.6756L2.1036 7.90254C1.72977 8.18636 1.22679 7.80449 1.36958 7.34525L1.98685 5.36002C2.05071 5.15465 1.98075 4.92966 1.81357 4.80273L0.197538 3.57579C-0.176289 3.29197 0.0158337 2.67408 0.477909 2.67408H2.47544C2.68208 2.67408 2.86523 2.53503 2.92908 2.32966L3.54635 0.344423Z" fill="#004B92"/>
        </svg>

    )
};

export default AdminStar;