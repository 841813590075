import React                            from 'react';
import SelectCheckbox                   from '../SelectCheckbox/SelectCheckbox';
import SelectCheckboxSearch             from '../SelectCheckbox/SelectCheckboxSearch';
import Button                           from '@material-ui/core/Button';
import AnimateLoader                    from '../../css/svg/AnimateLoader';
import FieldFilter                      from '../FieldFilter/FieldFilter';
import RadioGroup                       from '@material-ui/core/RadioGroup';
import Radio                            from '@material-ui/core/Radio';
import FormControlLabel                 from '@material-ui/core/FormControlLabel';
import { useStyles }                    from './style';
import StyledCheckbox                   from '../../Common/Checkbox'


const TypeAndGeography = ({ state, changeHandler, handleBlur, setState, addNewTypeFailReson, addNewTypeVote, load, regionsOptions, errors, handleChangeRadio }) => {

    const classes = useStyles();

    return (
        <div className={classes.typeAndGeography}>
            <h3 className={classes.headingTypeAndGeography}>Типы звонков:</h3>

            <div className={classes.typeCallContainer}>
                <div className={classes.typeCall}>
                    <h5 className={classes.typeHeading}>Типы успешных звонков</h5>

                    <div className={classes.wrapSelectCheckbox}>
                        <SelectCheckbox
                            readonly
                            name={'voteTypes'}
                            label={'Выберите тип из списка'}
                            value={state.voteTypes}
                            options={state.voteTypesOptions}
                            selectedCheck={state.voteTypes.length}
                            changeHandler={changeHandler}
                            state={state.voteTypes} //выбраные роли
                        />
                    </div>

                    <h5 className={classes.typeHeading}>Добавить тип</h5>

                    <div className={classes.sectionField}>
                        <FieldFilter
                            noLabel
                            placeholder={'Не более 70 символов'}
                            key={state.addTypeVote}
                            value={state.addTypeVote}
                            onBlur={handleBlur}
                            name={'addTypeVote'}
                            maxlength={70}
                        />
                    </div>

                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            className={classes.buttonClose}
                            disableElevation
                            onClick={() => setState({...state, addTypeVote: ''})}
                        >
                            Сбросить
                        </Button>

                        <Button
                            disabled={load.loadButtonVote}
                            variant="contained"
                            className={classes.buttonAdd}
                            disableElevation
                            startIcon={load.loadButtonVote ? <AnimateLoader color={'#fff'} /> : null}
                            onClick={addNewTypeVote}
                        >
                            { load.loadButtonVote ? 'Подождите...' : 'Добавить' }
                        </Button>
                    </div>
                </div>

                <div className={classes.typeCall}>
                    <h5 className={classes.typeHeading}>Типы неуспешных звонков</h5>

                    <div className={classes.wrapSelectCheckbox}>
                        <SelectCheckbox
                            readonly
                            name={'failReason'}
                            label={'Выберите тип из списка'}
                            value={state.failReason}
                            options={state.failReasonOptions}
                            selectedCheck={state.failReason.length}
                            changeHandler={changeHandler}
                            state={state.failReason} //выбраные роли
                        />
                    </div>

                    <h5 className={classes.typeHeading}>Добавить тип</h5>

                    <div className={classes.sectionField}>
                        <FieldFilter
                            noLabel
                            placeholder={'Не более 70 символов'}
                            key={state.addTypeFailReason}
                            value={state.addTypeFailReason}
                            onBlur={handleBlur}
                            name={'addTypeFailReason'}
                            maxlength={70}
                        />
                    </div>

                    <div className={classes.radioWrap}>
                        <span className={classes.radioLabel}>Автозакрытие:</span>
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={state.close_card}
                            onChange={handleChangeRadio}
                        >
                            <FormControlLabel
                                classes={{
                                    label: classes.label
                                }}
                                value={1}
                                control={<Radio color="primary" size={'small'} />}
                                label="Да"
                            />

                            <FormControlLabel
                                classes={{
                                    label: classes.label
                                }}
                                value={0}
                                control={<Radio color="primary" size={'small'} />}
                                label="Нет"
                            />
                        </RadioGroup>
                    </div>

                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            className={classes.buttonClose}
                            disableElevation
                            onClick={() => setState({...state, addTypeFailReason: '', close_card: 1})}
                        >
                            Сбросить
                        </Button>

                        <Button
                            disabled={load.loadButtonFailReason}
                            variant="contained"
                            className={classes.buttonAdd}
                            disableElevation
                            startIcon={load.loadButtonFailReason ? <AnimateLoader color={'#fff'} /> : null}
                            onClick={addNewTypeFailReson}
                        >
                            { load.loadButtonFailReason ? 'Подождите...' : 'Добавить' }
                        </Button>
                    </div>
                </div>
            </div>

            <h3 className={classes.headingTypeAndGeography}>Выберите регион:</h3>

            <div className={classes.longField}>
                <SelectCheckboxSearch
                    noLabel
                    name={'regions'}
                    label={'Регион'}
                    value={state.regions}
                    options={regionsOptions}
                    selectedCheck={state.regions.length}
                    changeHandler={changeHandler}
                    state={state.regions} //выбраные роли
                />
            </div>

            {/* <h3 className={classes.headingTypeAndGeography}>Выберите ОМСУ:</h3>
            <div className={classes.longField}>
                <SelectCheckboxSearch
                    noLabel
                    name={'omsu'}
                    label={'ОМСУ'}
                    value={state.omsu}
                    options={state.omsuOptions}
                    selectedCheck={state.omsu.length}
                    changeHandler={changeHandler}
                    state={state.omsu} //выбраные роли
                />
            </div> */}

            <h3 className={classes.headingTypeAndGeography}>Текст прощания:</h3>

            <div className={classes.longField}>
                <FieldFilter
                    redBorder={Boolean(errors.text_goodbye) && !state.text_goodbye}
                    multiline
                    placeholder={'Введите текст...'}
                    key={state.text_goodbye}
                    value={state.text_goodbye}
                    onBlur={handleBlur}
                    name={'text_goodbye'}
                    noLabel
                />
                { errors.text_goodbye && !state.text_goodbye ?
                    <small className={classes.error}>{ errors.text_goodbye }</small> : null }
            </div>

            <h3 className={classes.headingTypeAndGeography}>Допустимые номера телефонов:</h3>

            <div className={classes.longField}>
            <FormControlLabel
                control={
                    <StyledCheckbox
                        onChange={(e) => setState(prevState => ({
                                                    ...prevState,
                                                    phone_type_city: !state.phone_type_city
                                                }))}
                        checked={state.phone_type_city}
                        color="primary"
                    />
                }
                label={'Городские'}
            />
            </div>

        </div>
    )
};

export default TypeAndGeography;