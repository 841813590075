import { makeStyles } from '@material-ui/styles'

export const useModalStyles = makeStyles(theme => ({
    tablesWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: 32,
        paddingBottom: 90
    },
    filterFieldContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 24
    },
    sectionFilter: {
        width: 'calc(33% - 10px)'
    },
    claimsTable: {
        width: 'calc(50% - 12px)'
    },
    statisticTable: {
        width: 'calc(50% - 12px)'
    },
    tableHeading: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '24px',
        color: theme.black,
    },
    commentHeading: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '24px',
        color: theme.black,
        marginBottom: 24,
    },
    table: {
        tableLayout: 'fixed',

        '& tr': {

            background: 'rgba(0, 164, 216,.05)',
        },
        '& tr:nth-child(2n)': {
            background: 'white',
        },
        '& td': {
            border: '1px solid #E5E8EA',
            borderBottom: 'none',
            borderTop: 'none',
            fontSize: '12px',
            height: 80,
            boxSizing: 'border-box'
        },
        '& td:first-child': {
            borderLeft: 'none',
        },
        '& td:last-child': {
            borderRight: 'none',
        },
        '& th': {
            paddingTop: 0,
            fontSize: '12px',
            color:theme.darkGray,
            lineHeight: '14px',
            paddingBottom: '5px',
            fontWeight: '500',
            paddingLeft: '10px',
            height: '50px',
            background: 'white',
            '&:hover': {
                background: 'white',
                cursor: 'default',
                filter: 'brightness(100%)'
            }

        },
        width: '426px',
    },
    vp_ul: {
        '& .Mui-selected': {
            background: '#DB001B',
            color: '#fff'
        },

        '& li': {
            '&:first-child': {
                '& button': {
                    '&:after': {
                        content: "'Предыдущая'",
                        marginLeft: 10
                    }
                }
            },

            '&:last-child': {
                '& button': {
                    '&:before': {
                        content: "'Следующая'",
                        marginRight: 10
                    }
                }
            }
        }
    },
    paginWrap: {
        marginTop: 24
    },
    steps: {
        display: 'flex',
        flexDirection: 'column'
    },
    oneSteps: {
        display: 'flex',

        '&:last-child': {
            '& > div:nth-child(2)': {
                paddingBottom: 0
            }
        }
    },
    leftOneStepContent: {
        width: '190px',
        position: 'relative',
        paddingRight:  40,
        paddingBottom: 40,

        '&:before': {
            content: "''",
            position: 'absolute',
            right: 0,
            top: 0,
            width: 14,
            height: 14,
            borderRadius: '100%',
            background: theme.brightBlue,
            zIndex: 5,
        },

        '&:after': {
            content: "''",
            position: 'absolute',
            right: '0.43rem',
            top: 0,
            width: 1,
            height: '100%',
            background: theme.brightBlue,
            zIndex: 2,
        },
    },
    userFio: {
        fontSize: 14,
        lineHeight: '17px',
        fontWeight: 500,
        color: theme.black,
        marginBottom: 8,
    },
    dateStep: {
        fontSize: 14,
        lineHeight: '17px',
        fontWeight: 'normal',
        color: '#8A929D'
    },
    rightOneStepContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: 30,
        paddingBottom: 40,

        '& span': {
            marginBottom: 8
        }
    },
    textContent: {
        fontFamily: 'Rubik',
        fontSize: 14,
        lineHeight: '20px',
        color: theme.black
    },
    wrapComments: {
        padding: '24px 40px 40px 40px',
        margin: 0 -40,
        boxShadow: '0px -6px 11px rgba(0, 0, 0, 0.1)',
    },
    noData: {
        fontFamily: 'Rubik',
        fontSize: 14,
        color: theme.black,
        padding: '15px 0',
        textAlign: 'center',
    }
}));