import React from 'react'

function FilterIcon({ openCollapse }) {
    return (
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 12L7 12L7 10L11 10L11 12ZM18 0L18 2L-1.74846e-07 2L0 -1.57361e-06L18 0ZM15 7L3 7L3 5L15 5L15 7Z" fill={openCollapse ? 'white' : '#00A4D8'}/>
        </svg>
    )
}

export default FilterIcon;