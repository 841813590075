import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    wrapperInfoCloudVolunteer: {
        background: 'rgba(247, 245, 245, 0.8)',
        borderRadius: 6,
        padding: '16px',
        height: '100%',
    },
    infoLine: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 13,
    },
    leftPart: {
        width: '44%',
        marginRight: 24,
        display: 'flex',
        alignItems: 'center'
    },
    rightPart: {
        width: '55%',
        display: 'flex',
        alignItems: 'center',
        '& > span':{
            minWidth: 162
        }
    },
    label: {
        flexBasis: 80,
        whiteSpace: 'nowrap',
        fontFamily: 'Rubik',
        fontSize: 18,
        color: theme.black,
        marginRight: 18,
        alignSelf: 'flex-start',
        paddingTop: 14,
    },
    sectionFilter: {
        width: '100%'
    },
    boldText: {
        paddingLeft: 50,
        fontFamily: 'Rubik',
        fontSize: 18,
        fontWeight: 500,
        color: theme.black,
        marginRight: 5,
        alignSelf: 'flex-start',
        paddingTop: 14,
    },
    normalText: {
        fontFamily: 'Rubik',
        fontSize: 18,
        color: theme.black,
        alignSelf: 'flex-start',
        paddingTop: 14,
    },
    buttonBlue: {
        width: 'auto',
        height: 34,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#00A4D8',
        fontSize: 15,
        color: '#fff',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        transition: 'all .3s',

        '&:hover': {
            background: '#00B5EC',
        },

        '&:disabled': {
            color: '#fff'
        }
    },
    buttonSendPassword: {
        width: 175
    },
    buttonSaveChange: {
        width: 207
    },
    errorMessage: {
        color: 'tomato'
    },
    vpIcon: {
        cursor: 'pointer'
    },
    vp_alert: {
        alignItems: 'center',

       '& .MuiAlert-message': {
           display: 'flex',
           flex: 1,
           alignItems: 'center',
           justifyContent: 'space-between'
       }
    }
}));