import React                            from 'react';
import Pagination                       from '@material-ui/lab/Pagination';
import FormControl                      from '@material-ui/core/FormControl';
import Select                           from '@material-ui/core/Select';
import MenuItem                         from '@material-ui/core/MenuItem';
import { useStyles }                    from './style';

const TablePagination = ({ count, page, handleChangePagination, handleChangeSelectPagination, selectPage, total }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapPagination}>
            <Pagination
                shape="rounded"
                count={count}
                page={page}
                onChange={handleChangePagination}
                classes={{
                    root: classes.vp_pagRoot,
                    ul: classes.vp_ul
                }}
            />

            <div>
                Записи с { ((page-1) * selectPage) + 1 } по { (page) * selectPage } из {total}
            </div>

            <div className={classes.selectPage}>
                <span className={classes.titleSelect}>строк</span>
                <FormControl variant="outlined" classes={{root: classes.myFormControl}} >
                    <Select
                        value={selectPage}
                        onChange={handleChangeSelectPagination}
                        classes={{
                            root: classes.vp_rootSelect
                        }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    )
};

export default TablePagination;