import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    heading: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '28px',
        color: theme.black,
        marginBottom: 24,
    },
    headingFields: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
        color: theme.black,
        marginBottom: 16,
    },
    sectionFilter: {
        marginBottom: 8
    },
    buttonBlock: {
        marginTop: 30,
    },
    buttons: {
        width: 295,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        fontSize: 14,
        color: '#fff',
        transition: 'all .3s',
    },
    buttonSendCode: {
        background: '#53BB63',

        '&:disabled': {
            color: '#fff',
        },

        '&:hover': {
            background: '#5ED470',
        }
    },
    buttonEnter: {
        background: '#FF954D',
        marginTop: 10,

        '&:hover': {
            background: '#FFA467',
        }
    },
    errorMessage: {
        color: 'tomato',
        marginBottom: 5,
        display: 'inline-block'
    }
}));