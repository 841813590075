import { makeStyles } from '@material-ui/styles';


export const useStyles = makeStyles(theme => ({
    title:{
        textAlign: 'center',
        width: '100%',
        fontWeight: '500',
        fontSize: '18px',
        marginTop: '8px',
        marginBottom: '25px'
    },
    textField: {
        background: 'rgba(12, 84, 160,.1)',
        borderRadius: '5px',
        '& *::before':{
            display: 'none'
        },
        '& *::after':{
            display: 'none'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink':{
            fontWeight: '500'
        }
    },
    forgetPassword:{
        fontSize: '17px',
        marginTop: '10px',
        '&:hover':{
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    button:{
        marginTop: '32px',
        height: '52px',
        textTransform: 'none',
        borderRadius: '10px',
        boxShadow: 'none',
        '&:hover':{
            boxShadow: 'none'
        },
        '& > span':{
            fontSize: '17px'
        }

    }
}))