export const setToLocalStorage = (params) => {

    if (params.obj) {
        const { item, key } = params.obj;
        let serialItem = JSON.stringify(item);
        localStorage.setItem(key, serialItem)
    } else if (params.key && params.value) {
        const { key, value } = params;
        localStorage.setItem(key, value)
    } else {
        /* eslint-disable-next-line */
        throw 'Неверно передан обьект в хранилище!'
    }
};

export const getFromLocalStorage = (key, withSerialize) => {
    try {
        if(withSerialize){
            let item = localStorage.getItem(key) || ''
            return JSON.parse(item)
        }
        return localStorage.getItem(key)
    } catch (err) {
        
        return ''
    }
};

export const removeFromLocalStorage = (key) => {
    try{
        localStorage.removeItem(key)
    }catch(err){
        console.error(`Невозможно удалить ${key} из хранилища`)
    }
};