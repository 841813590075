import React from 'react'
import instance from './instance';
import { Redirect } from 'react-router-dom'
import { removeFromLocalStorage } from '../utils/localStorage';
import { errorToast } from '../utils/ErrorToast';

export const postCitizensList = async (page = 1, perPage = 10, filters) => {
    const modifiedFilters = JSON.parse(JSON.stringify(filters))
    let uikIds = modifiedFilters['filters[uik_numbers]'] || []

    if (!Array.isArray(uikIds)) {
        uikIds = uikIds.split(',')
    }

    modifiedFilters['filters[uik_numbers]'] = uikIds


    try {
        let res = await instance
            .get(
                `/api/citizens`,
                {
                    params: {
                        page: page,
                        page_size: perPage,
                        ...modifiedFilters
                    }
                });
        return res.data
    } catch (err) {
        console.log('ОШИБКА', err.response.data.errors);
        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login'
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

export const fetchFileCsvCitizens = async (body) => {
    try {
        let res = await instance.post('/api/cards/export', body);
        return res.data
    } catch (err) {
        console.log(err)
    }
};

export const getFullCitizenInfo = async (id) => {
    try {
        return await instance.get(`/api/citizens/${id}`);
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token')
        }
        errorToast(err.message, 'pensToast');
        return <Redirect to='/admin_login' />
    }
};

export const getCitizenHistory = async (id) => {
    try {
        return await instance.get(`/api/card_history/citizen/${id}`);
    } catch {
        removeFromLocalStorage('token');
        return <Redirect to='/admin_login' />
    }
};

export const patchUpdateStatus = async (id, resultObj) => {
    try {
        return await instance.patch(`/api/citizens/${id}`, resultObj);
    } catch (err) {
        if (err.response.status === 401) {
            removeFromLocalStorage('token')
        }
        console.log(err.response?.data)
        errorToast(err.response?.data?.errors?.detail || 'Ошибка', 'patchToast');
        return { data: {} }
    }
};

export const getCitizenComments = async (id) => {
    let res = await instance.get(`/api/card_comments/citizen/${id}`);
    return res.data
};

export const addCitizenComment = async (id, text) => {
    try {
        let res = await instance.post(`/api/card_comments/citizen/${id}`, { text: text });
        return res.data
    }
    catch (err) {
        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            errorToast(err.message, 'commentToast')
        } else {
            errorToast(err.response.data.errors.detail, 'commentToast')
        }
    }
};

export const getCitizenQuestions = async (id) => { //получить вопросы для карточки
    try {
        let res = await instance.get(`/api/poll/for_card/${id}`);
        return res.data
    } catch (err) {
        return 0
    }
};

export const getPollResultsForCard = async (id) => {
    try {
        let res = await instance.get(`/api/poll/for_card/${id}/results`);
        return res.data
    } catch (err) {
        errorToast(err.response.data.errors.detail, 'patchToast');

        if (err.response.status === 401) {
            removeFromLocalStorage('token');
            window.location.href = '/admin_login';
        }

        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }


    }
};

export const postCitizenQuestions = async (cititzenId, pollId, questions) => { //отправка ответов на сервер
    try {
        let res = await instance.post(`/api/poll/${pollId}/${cititzenId}`, questions);
        console.log('Отправка ответов');
        return res.data
    } catch (err) {
        console.log(err.message);
        return 0
    }
};

export const postMakeCall = async (id) => {
    try {
        let res = await instance.post(`/api/citizens/${id}/call`);
        return res.data.result
    } catch (err) {
        if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            errorToast(err.message, 'makeCallToast')
        } else {
            errorToast(err.response.data.errors.detail, 'makeCallToast')
        }
        return 0
    }
};

export const postNewUik = async (id, uikValues) => {
    try {
        let res = await instance
            .patch(`/api/citizens/${id}?uik_id=${uikValues.uik.id}&current_address=${uikValues.newAddress}`);
        return res.data
    } catch {
        return 0
    }
};

export const changePhoneNumberInCard = async (id, newPhone) => {
    let phoneFormat = newPhone.match(/\d+/g).join('').slice(1);

    const body = new FormData();
    body.append('phone', phoneFormat);

    try {
        let res = await instance.patch(`/api/citizens/${id}`, body);
        return res.data
    } catch (e) {
        errorToast(e.message, 'phoneToast');
        console.log(e)
    }
};


export const getVolunteerStat = async ({ from, to, type_id }) => {
    try {
        const res = await instance.post(
            '/api/volunteers/export_stats', { from, to, type_id }
        )
        const a = document.createElement('a');
        a.href = `/export/csv/${res.data.path}`;
        a.download = res.data.path.split('/').slice(-1);

        a.click()
    } catch (e) {
        throw e
    }
}


export const getVolunteerCallStat = async ({ from, to }) => {
    try {
        const res = await instance.post(
            '/api/volunteers/export_call_made', { from, to }
        )
        const a = document.createElement('a');
        a.href = `/export/csv/${res.data.path}`;
        a.download = res.data.path.split('/').slice(-1);

        a.click()
    } catch (e) {
        throw e
    }
}