import { makeStyles } from '@material-ui/styles'

export const useNavbarStyle = makeStyles(theme => ({
    navbarWrapper: {
        position: 'relative',
        top: '0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        left: '0',
        height: '77px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)'
    },
    navbarContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    leftGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    logo: {
        width: '86px',
        borderRight: '1px solid #E5E8EA',
        marginRight: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    link: {
        height: '100%',
        marginRight: '48px',
        fontSize: '17px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '.3s ease',
        color: theme.black,
        textDecoration: 'none',
        borderBottom: `2px solid transparent`,
        padding: '0px 4px',

        '&:hover': {
            fontSize: '17px',
            borderBottom: `2px solid ${theme.red}`,
            transition: '.3s ease',
            textShadow: '0 0 0 #333,  0 0 0 #333,  0 0 0 #333'
        },
    },
    activeLink: {
        color: theme.black,
        fontSize: '17px',
        fontWeight: '500',
        transition: '.3s ease',
    },
    rightGroup: {
        paddingLeft: 12,
        paddingRight: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        //maxWidth: '190px',
        //width: "100%",
        height: '100%',
        marginLeft: 'auto',
        borderLeft: '1px solid #E5E8EA'
    },
    username: {
        fontSize: '17px',
        color: theme.black,
        letterSpacing: '-0.0041em',
        marginRight: '17px',
    },
    userIcon: {
        marginRight: '18px'
    },
    vp_fio: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontSize: 15,
        lineHeight: '18px',
        color: '#191A1D',
        marginBottom: 2,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    vpRole: {
        fontFamily: 'Rubik',
        fontSize: 15,
        lineHeight: '18px',
        color: '#004B92',
        position: 'relative'
    },
    buttonLogout: {
        textTransform: 'none',
        color: '#E41D37',
        fontWeight: 'bold',
        fontSize: 11,
        marginLeft: 5,
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    listRoles: {
        minWidth: '100%',
        listStyle: 'none',
        background: '#fff',
        border: '1px solid #00A4D8',
        borderRadius: 5,
        textAlign: 'center',
        position: 'absolute',
        top: 25,
        right: 0,
        zIndex: 10,

        '& li': {
            padding: '8px 10px',
            whiteSpace: 'nowrap',
            cursor: 'default',

            '&:not(:last-child)': {
                borderBottom: '1px solid #00A4D8',
            }

        }
    },
    containerRole: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize'
    },
    wrapIcon: {
        marginLeft: 5
    },
    mobileNavbar:{
        width: '100%',
        display: "flex",
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    mobileFio:{
        padding: '8px 16px'
    }
}));