import instance from './instance';
import { removeFromLocalStorage } from '../utils/localStorage';

export const postPollingStationList = async (page = 1, perPage = 10, filters) => {
    try {
        let res = await instance
            .get('/api/uik', {
                    params: {
                        page: page,
                        page_size: perPage,
                        ...filters
                    }
                });
        return res.data
    }catch(err){
        console.log('ОШИБКА', err.response.data.errors);

        if (err.response.status === 401) {
            removeFromLocalStorage('token')
        }

        if(err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
            throw err.message;
        } else {
            throw err.response.data.errors.detail
        }
    }
};

