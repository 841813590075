import { makeStyles } from '@material-ui/styles'

export const useModalStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: '100%',
            transition: '.3s ease',
            borderRadius: '20px',
        },
        transition: '.3s ease',
        position: 'relative',
        overflowX: 'hidden'
    },
    textField: {
        width: '270px',
    },
    title: {
        fontWeight: '500',
        fontSize: '24px',
        color: theme.black,
        marginRight: 45
    },
    modal: {
        paddingTop: 40,
        position: 'relative',
        borderRadius: '20px',

        overflowX: 'clip'
    },
    subTitle: {
        fontSize: '18px',
        marginTop: '23px',
        marginBottom: '16px',
        color: theme.black,
        fontWeight: '500',
    },
    titleRow: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginBottom: '17px',
        marginTop: '39px',

        '@media(max-width: 960px)':{
            flexDirection: 'column',
            flexWrap: 'wrap',
        }
    },

    saveButton: {
        height: 45,
        background: '#00A4D8',
        boxShadow: 'none',
        textTransform: 'none',
        borderRadius: '6px',
        margin: '25px 0 0px 77%',

        '@media(max-width: 875px)':{
            margin: '16px 0 16px 0 ',
            width: '100%'
        }
    },

    infoCloud: {
        background: 'rgba(247, 245, 245, 0.8)',
        padding: '16px 32px',
        position: 'relative',
        height: '100%',
        '& *': {
            fontSize: '14px'
        }
    },
    makeCallButt: {
        background: '#53BB63',
        height: '28px',
        width: 'fit-content',
        maxWidth: '300px',
        marginTop: '8px',
        marginBottom: '3px',
        textTransform: 'none',
        '& > span': {
            fontSize: '14px',
            color: 'white',
            height: '19px'
        },
        '&:hover': {
            background: '#53BB63',
            filter: 'brightness(90%)',
        },

        '@media(max-width: 770px)':{
            maxWidth: '100%',
            width: '100%',
            height: 'auto'
        }
    },
    line: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: '15px',
    },
    column: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-start',
        marginRight: '3px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginBottom: '4px'
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        position: 'relative',
        marginBottom: '4px',
        '& > button': {
            marginRight: '16px',
            boxShadow: 'none',
        },
        '& > button:last-child': {
            marginRight: '0px',
            boxShadow: 'none',
        },

        '@media(max-width: 560px )':{
            flexDirection: 'column',
            alignItems: 'flex-start',

            '& > button':{
                marginBottom: 8
            }
        }
    },
    closeSmall:{
        position: 'absolute',
        background: 'rgba(0,0,0,.1)',
        right: 24,
        padding: 4,
        borderRadius: '100%',
        zIndex: 999,
        top: 91,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    littleTitle: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    threeColumns: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        '& > div': {
            paddingRight: '8px',
        },

        '@media(max-width: 460px)':{
            flexDirection: 'column'
        }
    },
    commentarySection: {
        margin: '0px -40px',
        width: 'calc(100% + 80px)',
    },
    uikfield: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px'
        }
    },
    makeCall: {
        background: theme.brightBlue,
        height: '52px',
        width: '299px',
        margin: '0 14px',
        transition: '.2s ease',
        '& > span': {
            fontWeight: '500px',
            fontSize: '17px',
            textTransform: 'none',
            color: '#fff'
        },
        '&:hover': {
            background: theme.brightBlue,
            filter: 'brightness(105%)',
            transition: '.2s ease'
        }
    },
    sendComment: {
        background: theme.brightBlue,
        height: '45px',
        width: '139px',
        transition: '.2s ease',
        '& > span': {
            fontWeight: '500px',
            fontSize: '14px',
            textTransform: 'none',
            color: '#fff'
        },
        '&:hover': {
            background: theme.brightBlue,
            filter: 'brightness(105%)',
            transition: '.2s ease'
        }
    },
    disabledButton: {
        boxShadow: 'none',
        height: '34px',
        cursor: 'default',
        background: theme.disabled,
        '&:hover': {
            cursor: 'default',
            boxShadow: 'none',
            background: theme.disabled,
        },
        '& > span': {
            boxShadow: 'none',
            fontSize: '15px',
            color: 'white',
            textTransform: 'none',
        }
    },
    blueButton: {
        boxShadow: 'none',
        cursor: 'default',
        background: theme.brightBlue,
        height: '34px',
        transition: '.2s ease',
        '& > span': {
            fontWeight: '500px',
            fontSize: '14px',
            textTransform: 'none',
            color: '#fff'
        },
        '&:hover': {
            boxShadow: 'none',
            cursor: 'default',
            background: theme.brightBlue,
            transition: '.2s ease'
        }
    },
    greenButton: {
        boxShadow: 'none',
        cursor: 'default',
        background: theme.green,
        height: '34px',
        transition: '.2s ease',
        '& > span': {
            fontWeight: '500px',
            fontSize: '14px',
            textTransform: 'none',
            color: '#fff'
        },
        '&:hover': {
            cursor: 'default',
            boxShadow: 'none',
            background: theme.green,
            transition: '.2s ease'
        }
    },
    orangeButton: {
        cursor: 'default',
        background: theme.orange,
        height: '34px',
        boxShadow: 'none',
        transition: '.2s ease',
        '& > span': {
            fontWeight: '500px',
            fontSize: '14px',
            textTransform: 'none',
            color: '#fff'
        },
        '&:hover': {
            boxShadow: 'none',
            cursor: 'default',
            background: theme.orange,
            transition: '.2s ease'
        }
    },
    darkButton: {
        boxShadow: 'none',
        cursor: 'default',
        background: theme.purple,
        height: '34px',
        transition: '.2s ease',
        '& > span': {
            fontWeight: '500px',
            fontSize: '14px',
            textTransform: 'none',
            color: '#fff'
        },
        '&:hover': {
            boxShadow: 'none',
            cursor: 'default',
            background: theme.purple,
            transition: '.2s ease'
        }
    },
    redButton: {
        boxShadow: 'none',
        cursor: 'default',
        background: theme.red,
        height: '34px',
        transition: '.2s ease',
        '& > span': {
            fontWeight: '500px',
            fontSize: '14px',
            textTransform: 'none',
            color: '#fff'
        },
        '&:hover': {
            boxShadow: 'none',
            cursor: 'default',
            background: theme.red,
            transition: '.2s ease'
        }
    },
    nextButton: {
        color: 'white',
        textTransform: 'none',
        background: theme.brightBlue,
        height: '52px',
        marginTop: '20px',
        width: '234px',
        float: 'right',
        boxShadow: 'none',
    },
    picker: {
        '& > svg': {
            color: '#00A4D8',
        },
        '& ::before': {
            display: 'none',
        },
        '& ::after': {
            display: 'none'
        },
        '& input': {
            paddingLeft: '24px',
            paddingRight: '24px',
            border: '1px solid #E5E8EA',
            borderRadius: '13px',
            paddingTop: '2px',
            paddingBottom: '2px',
            color: '#00A4D8',
            maxWidth: '90px',
        },
        '& .MuiInputAdornment-positionStart': {
            marginRight: '0'
        },
        '& #timePicker': {
            width: '41px'
        },
        '& button': {
            color: '#00A4D8',
        }
    },
    failButton: {
        color: 'white',
        boxShadow: 'none',
        textTransform: 'none',
        background: theme.red,
        height: '52px',
        marginTop: '20px',
        width: '234px',
        float: 'right'
    },
    callBackButton: {
        color: 'white',
        boxShadow: 'none',
        textTransform: 'none',
        background: theme.orange,
        height: '52px',
        marginTop: '20px',
        float: 'right'
    },
    history: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'noWrap'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '150px'
    },
    showMapButton: {
        padding: '6px 22px',
        width: '152px',
        maxHeight: '28px',
        position: 'absolute',
        right: '16px',
        top: '11px',
        background: 'rgba(0, 164, 216,.1)',
        marginLeft: '5px',
        '& > span': {
            fontSize: '11px',
            color: theme.brightBlue,
            textTransform: 'none',
            fontWeight: '500'
        }
    },
    addressrow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    dotContainer: {
        width: '90px',
        textAlign: 'center',
        display: 'flex',
        paddingLeft: '28px',
        paddingRight: '28px',
        justifyContent: 'center',
        marginTop: '5px',
    },
    scriptButton: {
        background: '#53BB63',
        width: 'auto',
        height: '40px',
        border: '1px solid #E5E8EA',
        borderRadius: '6px',
        marginRight: '16px',
        fontWeight: 'normal',
        transition: 'background .3s ease',

        '&.Mui-disabled.MuiButton-root': {
            background: '#53BB63!important',
        },

        '&:hover': {
            background: '#60D872',
        },

        '& > span': {
            fontSize: '16px',
            fontWeight: 'normal',
            color: 'white',
            textTransform: 'none'
        }
    },
    disabled: {
        '&.MuiButton-root': {
            background: '#F1F3F4!important'
        }
    },
    disabledScriptButton: {
        background: 'white',
        width: 'auto',
        marginRight: '16px',
        transition: '.4s ease',
        height: '40px',
        border: '1px solid #E5E8EA',
        borderRadius: '6px',
        fontWeight: 'normal',

        '&:hover': {
            background: '#F4F4F4',
            transition: '.3s ease',
        },
        '& > span': {
            fontSize: '16px',
            color: 'black',
            textTransform: 'none'
        }
    },
    dot: {
        textAlign: 'center',
        width: '13px',
        height: '13px',
        background: theme.brightBlue,
        borderRadius: '50%',
        marginBottom: '-13px',

    },
    dotline: {
        width: '1px',
        background: theme.brightBlue,
        height: 'calc(100% + 42px)',
        marginBottom: '-10px',
        position: 'relative',
        right: '6.5px'
    },
    itemContainer: {
        marginBottom: '35px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'noWrap',
    },
    fieldTitle: {
        fontSize: '14px',
        color: '#8A929D',
        marginBottom: '4px'
    },
    takeInProcess: {
        height: '65px',
        position: 'absolute',
        top: '0',
        left: '0',
        transition: '.4s ease',
        background: '#53BB63',
        color: 'white',
        textTransform: 'none',
        fontWeight: '500',
        fontSize: '20px',
        '&:hover':{
            background: '#53BB63',
            filter: 'brightness(115%)',
            transition: '.4s ease',
        }
    },
    inWorkButton: {
        height: '65px',
        position: 'absolute',
        top: '0',
        left: '0',
        transition: '.4s ease',
        background: '#AAA9A9',
        color: 'white',
        textTransform: 'none',
        width: '100%',
        zIndex: '5',
        display: 'flex',
        fontWeight: '500',
        fontSize: '20px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    cantTakeInProcess: {
        height: '65px',
        position: 'absolute',
        top: '0',
        left: '0',
        transition: '.4s ease',
        background: '#E4881D',
        color: 'white',
        textTransform: 'none',
        width: '100%',
        zIndex: '10',
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        fontSize: '20px',
        justifyContent: 'center'
    },
    backToPrevious: {
        height: '65px',
        position: 'absolute',
        top: '0',
        left: '0',
        transition: '.4s ease',
        background: '#E4881D',
        color: 'white',
        textTransform: 'none',
        width: '100%',
        zIndex: '7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '500',
        fontSize: '20px',

        '@media(max-width:470px)':{fontSize: 11}
    },
    backText: {
        cursor: 'pointer',
        color: '#0848C0',
        fontWeight: '500',
        fontSize: '21px',
        textDecoration: 'underline'
    },
    loaderStatus: {
        height: '65px',
        position: 'absolute',
        top: '0',
        left: '0',
        transition: '.4s ease',
        background: '#AAA9A9',
        color: 'white',
        textTransform: 'none',
        fontSize: '14px',
        width: '100%',
        zIndex: '15',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    recallDate: {
        height: '34px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        background: '#00A4D8',
        color: 'white',
        fontSize: '15px',
        marginRight: '137px',
        marginTop: '8px',
        padding: '0 5px',
        fontWeight: '500',
        

        '@media(max-width: 960px)':{
            width: '100%',
            marginRight: 'auto',
            padding: 8,
            display: 'block',
            height: 'auto'
        }
    },
    sequenceQuestionsHeading: {
        fontWeight: '500',
        fontSize: '18px',
        marginBottom: '8px',
        marginTop: 15,
    },
    buttonWrap: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '16px'
    },
    tooltip: {
        backgroundColor: '#000'
    }
}));