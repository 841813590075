import { Container } from '@material-ui/core'
import React from 'react'
import { MOBILE_VOTE_INSTRUCTION_REF, WEB_INSTRUCTION_REF } from '_constants'
import { useFooterStyles } from './styles'

export const Footer = () => {
    const classes = useFooterStyles()
    return (
        <footer className={classes.footerWrapper} >
            <Container className={classes.footer} >
                <div className={`${classes.footerLink} ${classes.copyright}`} >                    	
                    &#169; Сансет, 2021
                </div>
                <div className={classes.links} >
                    <a
                        href={WEB_INSTRUCTION_REF}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.footerLink}
                    >
                        Инструкция по работе в веб-версии
                    </a>
                    <a
                        href={MOBILE_VOTE_INSTRUCTION_REF}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.footerLink}
                    >
                        Инструкция по работе в мобильном приложении
                    </a>
                </div>
            </Container>
        </footer>
    )
}
