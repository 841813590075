import React                    from 'react';
import Dialog                   from '@material-ui/core/Dialog';
import CloseIcon                from '@material-ui/icons/Close';
import { useStyles }            from './style';

const DialogAdmin = ({ openDialog, setOpenDialog, children, widthDialog, setIdCard, disableBackdropClick }) => {

    const classes = useStyles({widthDialog});

    const handleClose = () => {
        if(setIdCard) setIdCard(null);
        setOpenDialog(false);
    };

    return (
        <Dialog
            disableBackdropClick={disableBackdropClick}
            open={openDialog}
            onClose={handleClose}
            classes={{
                root: classes.vp_dialogRoot,
                paper: classes.vp_paper
            }}
            scroll={'body'}
        >
            {
                !disableBackdropClick ?
                    <CloseIcon
                        onClick={handleClose}
                        className={classes.closeIcon}
                    /> : null
            }

            { children }
        </Dialog>
    );
};

export default DialogAdmin;