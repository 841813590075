export const downloadAsCsv = (fileAsString) => {
    let csvContent = "data:text/csv;charset=utf-8," + fileAsString;
    const encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "import.csv");
    document.body.appendChild(link);
    
    console.log('downloadAsCsv')
    link.click();
}


export const downloadAsFile = fileUrl => {
    const link = document.createElement("a")
    link.href = fileUrl
    link.download = fileUrl.split('/').slice(-1)[0]

    document.body.append(link)

    link.click()

    setTimeout(() => {document.body.removeChild(link)},3000)
}