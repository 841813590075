import React, { useReducer } from 'react';
import { FilterContext } from './FilterContext';
import { filterReducer } from './FilterContextReducer';
import instance from '../../API/instance';


const FilterContextState = ({ children }) => {
    const initialState = {
        area: [],
        regions: [],
        omsu: [],
        vote_type: [],
        status_card: [],
        status_volunteer: [],
        uik: []
    };

    const [state, dispatch] = useReducer(filterReducer, initialState);

    const fetchArea = async () => {
        try {
            let res = await instance.get('/api/area');
            dispatch({ type: 'FETCH_AREA', payload: res.data })
        } catch (err) {
            console.log('ОШИБКА', err);
        }
    };

    const fetchRegions = async (params) => {
        dispatch({ type: 'CLEAR_REGION' });
        try {
            let res = await instance.get('/api/region', { params });
            dispatch({ type: 'FETCH_REGIONS', payload: res.data });
            return res.data;
        } catch (err) {
            console.log('ОШИБКА', err);
        }
    };

    const fetchOmsu = async (params) => {
        dispatch({ type: 'CLEAR_OMSU' });
        try {
            let res = await instance.get('/api/omsu', { params });
            dispatch({ type: 'FETCH_OMSU', payload: res.data });
            return res.data
        } catch (err) {
            console.log('ОШИБКА', err);
        }
    };

    const fetchVoteType = async () => {
        try {
            let res = await instance.get('/api/vote_types');
            dispatch({ type: 'FETCH_VOTE_TYPE', payload: res.data })
        } catch (err) {
            console.log('ОШИБКА', err);
        }
    };

    const fetchCardStatuses = async () => {
        try {
            let res = await instance.get('/api/card_statuses');
            dispatch({ type: 'FETCH_STATUS_CARD', payload: res.data })
        } catch (err) {
            console.log('ОШИБКА', err);
        }
    };

    const fetchUIK = async (region, omsu) => {
        try {
            let res = await instance.get(
                `/api/uik?page=1&page_size=100&filters[region_id]=${region.id}&filters[omsu_id]=${omsu.id}`
            );
            dispatch({ type: 'FETCH_UIK', payload: res.data.entries })
        } catch (err) {
            console.log('ОШИБКА', err);
        }
    };

    const fetchStatusVolunteer = async () => {
        try {
            let res = await instance.get('/api/volunteer_statuses');
            dispatch({ type: 'FETCH_STATUS_VOLUNTEER', payload: res.data })
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <FilterContext.Provider value={{
            areaOptions: state.area,
            regionsOptions: state.regions,
            omsuOptions: state.omsu,
            voteTypeOptions: state.vote_type,
            statusCardOptions: state.status_card,
            volunteerStatusOptions: state.status_volunteer,
            uik: state.uik,
            fetchArea,
            fetchRegions,
            fetchOmsu,
            fetchVoteType,
            fetchCardStatuses,
            fetchStatusVolunteer,
            fetchUIK,

        }}>
            {children}
        </FilterContext.Provider>
    )
};

export default FilterContextState;