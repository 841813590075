import React, { useState, useEffect }               from 'react';
import { useHistory, useParams }                    from 'react-router-dom';
import TablesAll                                    from '../../Components/TablesAll/TablesAll';
import Dialog                                       from '@material-ui/core/Dialog';
import DialogActions                                from '@material-ui/core/DialogActions';
import DialogTitle                                  from '@material-ui/core/DialogTitle';
import Button                                       from '@material-ui/core/Button';
import Fade                                         from '@material-ui/core/Fade';
import DeleteForeverOutlinedIcon                    from '@material-ui/icons/DeleteForeverOutlined';
import IconButton                                   from '@material-ui/core/IconButton';
import AnimateLoader                                from '../../css/svg/AnimateLoader';
import { fetchPollsList, removeScript }             from '../../API/Polls';
import { successToast }                             from '../../utils/SuccessToast';
import { errorToast }                               from '../../utils/ErrorToast';
import { useStyles }                                from './style';

const Scripts = () => {
    const classes = useStyles();

    const history = useHistory();
    const { idPage = "1" } = useParams();

    const [dataRow, setDataRow] = useState({id: null, title: null});
    const [forcedReboot, setForcedReboot] = useState(false); //при изменении этого флага делаем загрузку данных таблицы
    const [open, setOpen] = useState(false); //отркрытие диалогового окна
    const [error, setError] = useState(false); // состояние для ошибок
    const [load, setLoad] = useState(true); //индикатор загрузки для таблицы
    const [loadForDelete, setLoadForDelete] = useState(false); // индикатор загрузки для кнопку удаления скрипта
    const [dataTbody, setDataTbody] = useState(null); //данные по таблице
    const [nav, setNav] = useState({
        page: idPage ? +idPage : 1,
        selectPage: 10,
    });

    const nameThead = ['id', 'Наименование', ''];

    useEffect(() => {
        setError(false);
        setLoad(true);
        setDataTbody(null);

        fetchPollsList(idPage, nav.selectPage).then((res) => {
            setDataTbody(res);
            setLoad(false);
        }).catch(e =>{
            setError(e);
            setLoad(false);
        })
    }, [idPage, nav.selectPage, forcedReboot]);

    useEffect(() => {
        if(dataRow.id) {
            setOpen(true);
        }
    }, [dataRow.id]);

    const handleChangePagination = (event, value) => { //обработчик для пагинации
        history.push(`/admin/scripts/${value}`);
        setNav({
            ...nav,
            page: value
        });
    };

    const handleChangeSelectPagination = (event) => { //обработчик селекта пагинации
        history.push('/admin/scripts');
        setNav({
            ...nav,
            selectPage: event.target.value,
            page: 1,
        });
    };

    const handleClose = () => {
        setOpen(false);
        setDataRow({id: null, title: null})
    };

    const clickOnDeleteIcon = (id, title) => (event) => {
        event.preventDefault();
        setDataRow({id, title})
    };

    const helperTdrender = (data) => { // формируем столбцв в строке таблицы
        return [
            data.id,
            data.title,
            <span
                className={classes.iconDelete}
                onClick={event => event.stopPropagation()}
            >
                <IconButton
                    onClick={clickOnDeleteIcon(data.id, data.title)}
                >
                    <DeleteForeverOutlinedIcon style={{color: 'red'}}/>
                </IconButton>
            </span>
        ];
    };

    const deleteScript = async () => { //клик на кнопку в диалоке удалить
        setLoadForDelete(true);
        try{
            const res = await removeScript(dataRow.id);
            if(res.success) {
                setForcedReboot(prevState => !prevState);
                handleClose();
                successToast('Скрипт успешно удалён');
            } else {
                errorToast('Ошибка удаления');
            }
            setLoadForDelete(false);
        } catch (err) {
            setLoadForDelete(false);
            errorToast(`[Ошибка]: ${err}`);
            throw err;
        }
    };

    return (
        <Fade timeout={800} in>
            <div className={classes.scriptsWrap}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>Реестр скриптов</h3>

                        <Button
                            variant="contained"
                            className={classes.button}
                            disableElevation
                            onClick={() => history.push('/admin/script/add')}
                        >
                            Добавить скрипт
                        </Button>
                    </div>
                </div>

                <TablesAll
                    error={error}
                    otherFunctionOpenModal={
                        (id) => history.push(`/admin/script/edit/${id}`)
                    }
                    nameThead={nameThead}
                    load={load}
                    dataTbody={dataTbody}
                    helperTdrender={helperTdrender}
                    handleChangePagination={handleChangePagination}
                    page={+idPage}
                    selectPage={nav.selectPage}
                    handleChangeSelectPagination={handleChangeSelectPagination}
                />

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Вы действительно хотите удалить скрипт «{dataRow.title}»?</DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            className={classes.buttonConfirm}
                        >
                            Отмена
                        </Button>
                        <Button
                            onClick={deleteScript}
                            color="primary"
                            className={classes.buttonConfirm}
                            disabled={loadForDelete}
                        >
                            { loadForDelete ? <AnimateLoader color={"darkgray"} /> : 'Удалить'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fade>

    )
};

export default Scripts;