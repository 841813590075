import React, { useState, useContext,
    useEffect }                             from 'react';
import SelecForFilter                       from '../SelectForFilter/SelectForFilter';
import FieldFilter                          from '../FieldFilter/FieldFilter';
import { FilterContext }                    from '../../Context/FilterContext/FilterContext';
import Tooltip                              from '@material-ui/core/Tooltip';
import Button                               from '@material-ui/core/Button';
import instance                             from '../../API/instance';
import { useStyles }                        from './style';

const CardConfirmData = ({ volunteer, phone, setOpenDialog, setForciblyFetch }) => {
    const classes = useStyles();
    const { fetchOmsu, omsuOptions, areaOptions, fetchRegions, regionsOptions } = useContext(FilterContext);

    const [load, setLoad] = useState(false);
    const [timer, setTimer] = useState(0);
    const [clearInt, setClearInt] = useState(null);
    const [state, setState] = useState({ //coстояние для селектов и инпутов филтра
        area: null,
        region: null,
        omsu: !volunteer.omsu ? null : true,
        phoneConfirm: volunteer.phone,
        smsCode: '',
        touchArea: false,
    });

    const [error,setError] = useState({
        phoneError: false,
        smsCodeError: false,
        omsuError: false,
    });

    useEffect(() => { // при изменении округа меняем регионы
        if(state.area && state.touchArea) {
            fetchRegions({"filters[area_id]": state.area.id}).then((res) => {
                setState(prevState => ({
                    ...prevState,
                    region: null,
                    omsu: null,
                }))
            });
        }
    }, [state.area]);

    useEffect(() => { // при изменении региона меняем omsu
        if(state.region) {

            if(!state.area) { //если первым выбрали регион подставляем ФО
                setState(prevState => ({
                    ...prevState,
                    area: areaOptions.find(elem => elem.id === state.region.area_id),
                }))
            }

            fetchOmsu({"filters[region_id]": state.region.id}).then((res) => {
                setState(prevState => ({
                    ...prevState,
                    omsu: null
                }))
            })
        }
    }, [state.region]);

    useEffect(()=> { // очищаем таймер после того как он дошел до нуля
        if(timer === 0) {
            clearInterval(clearInt);
        }
    }, [timer]);

    const startTime = () => { //запуск таймера
        setTimer(60);

        let clearTimer = setInterval(() => {
            setTimer(currentTimer => currentTimer - 1);
        }, 1000);
        setClearInt(clearTimer);
    };

    const handleValueSelect = (name) => (event, val) => { //обработчик для селектов фильтра
        setState({
            ...state,
            [name]: val,
            touchArea: name === 'area',
        });

        if(name === 'omsu') {
            setError({
                ...error,
                omsuError: false
            })
        }
    };

    const handleBlur = (event) => { //обработчки на инпуты в фильтрах
        setState({
            ...state,
            [event.target.name]: event.target.value
        });

        if(event.target.name === 'smsCode' && event.target.value.trim()) {
            setError({
                ...error,
                smsCodeError: false
            })
        }
    };

    const formatCheck = () => {
       return state.phoneConfirm.length > 10
            ? state.phoneConfirm.match(/\d+/g).join('').slice(1)
            : state.phoneConfirm;
    };

    const sendCode = async () => { //клик на "Отправить код"

       if(formatCheck().length === 10) { //если телефон заполнен верно
           setError({...error, phoneError: false });

           const body = new FormData();
           body.append('phone', formatCheck());

           try {
               startTime(); //запускаем таймер
               let res;

               if(phone.toString() === formatCheck().toString()) { // если телефон с данных совпадает с телефоном формы
                   res = await instance.post('/api/sms/send');
               } else { // если телефоны не совпадают
                   res = await instance.post('/api/user/phone/change', body);
               }
           } catch (err) {
               if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
                   setError({...error, phoneError:  err.message})
               } else {
                   setError({...error, phoneError:  err.response.data.errors.detail});
               }
           }
       } else {
           setError({...error, phoneError: 'Неккоректное значение' })
       }
    };

    const enterSendCode = async () => { // клик на "Войти"

        const body = new FormData();
        body.append('phone', formatCheck());
        body.append('code', state.smsCode);

        if((formatCheck().length === 10 && state.smsCode && state.omsu)) { //если правильный номер и введн код из смс
            try {
                setLoad(true);

                if(phone.toString() === formatCheck().toString()) { // если телефон с данных совпадает с телефоном формы
                    await instance.post('/api/sms/check', body);
                } else {
                    await instance.post('/api/user/phone/confirm', body);
                }

                if(!volunteer.omsu) {
                    const bodyOmsu = new FormData();
                    bodyOmsu.append('omsu_id', state.omsu.id);
                    await instance.patch('/api/volunteers/update', bodyOmsu);
                    setForciblyFetch(true);
                }

                clearInterval(clearInt);
                setOpenDialog(false);
            } catch (err) {
                setLoad(false);
                if (err.response.status === 404 || err.response.status === 500 || !err.response.data.errors) {
                    setError({...error, smsCodeError:  err.message})
                } else {
                    setError({
                        ...error,
                        smsCodeError: typeof err.response.data.errors.detail === 'object'
                            ? Object.values(err.response.data.errors.detail).join(' ')
                            : err.response.data.errors.detail
                    });
                }
            }
        } else {
            setError({
                ...error,
                phoneError: formatCheck().length !== 10 ? 'Неккоректное значение' : false,
                smsCodeError: !state.smsCode ? 'Поле не должно быть пустым' : false,
                omsuError: !state.omsu ? 'Выберите муниципальное образование' : false
            })
        }
    };

    return (
       <div>
           <h2 className={classes.heading}>Дозаполните Вашу информацию</h2>

           {
               !volunteer.omsu ?
                   <>
                       <h3 className={classes.headingFields}>Регион обслуживания</h3>
                       <div className={classes.sectionFilter}>
                           <SelecForFilter
                               fz16
                               readonly
                               label={areaOptions.length ? 'Федеральный округ' : 'Загрузка данных...'}
                               value={state.area}
                               options={areaOptions}
                               handleValueSelect={handleValueSelect('area')}
                           />
                       </div>

                       <div className={classes.sectionFilter}>
                           <SelecForFilter
                               fz16
                               label={regionsOptions.length ? 'Регион' : 'Загрузка данных...'}
                               value={state.region}
                               options={regionsOptions}
                               handleValueSelect={handleValueSelect('region')}
                           />
                       </div>

                       <Tooltip
                           title="Сначала выберите регион"
                           placement="top-start"
                           disableHoverListener={Boolean(state.region)}
                           disableFocusListener={Boolean(state.region)}
                       >
                           <div className={classes.sectionFilter} style={{marginBottom: 20}}>
                               <SelecForFilter
                                   errorData={error.omsuError}
                                   fz16
                                   disabled={!state.region || !omsuOptions.length}
                                   label={!state.region || omsuOptions.length
                                       ? 'Муниципальное образование'
                                       : 'Загрузка данных...'}
                                   value={state.omsu}
                                   options={omsuOptions}
                                   handleValueSelect={handleValueSelect('omsu')}
                               />
                               {error.omsuError ? <small className={classes.errorMessage}>{error.omsuError}</small> : null}
                           </div>
                       </Tooltip>
                   </> : null
           }

           <h3 className={classes.headingFields}>Введите номер телефона</h3>

           <div className={classes.sectionFilter}>
               <FieldFilter
                   redBorder={error.phoneError}
                   disabled={false}
                   key={state.phoneConfirm}
                   value={state.phoneConfirm}
                   onBlur={handleBlur}
                   name={'phoneConfirm'}
                   maskInput={['+', '7','(',/[1-9]/, /\d/, /\d/,')', /\d/, /\d/,/\d/, '-', /\d/, /\d/,'-',/\d/, /\d/]}
                   placeholdermask={'+7'}
               />
               {error.phoneError ? <small className={classes.errorMessage}>{error.phoneError}</small> : null}
           </div>

           <div className={classes.sectionFilter}>
               <FieldFilter
                   redBorder={error.smsCodeError}
                   type={'number'}
                   key={state.smsCode}
                   value={state.smsCode}
                   onBlur={handleBlur}
                   name={'smsCode'}
                   placeholder={'СМС-код'}
               />
               {error.smsCodeError ? <small className={classes.errorMessage}>{error.smsCodeError}</small> : null}
           </div>

           <div className={classes.buttonBlock}>
               <Button
                   disabled={timer > 0}
                   variant="contained"
                   className={`${classes.buttons} ${classes.buttonSendCode}`}
                   disableElevation
                   onClick={sendCode}
               >
                   { timer > 0 ? `Повторно отправить через ${timer} сек.` : 'Отправить код'}
               </Button>

               <Button
                   disabled={load}
                   variant="contained"
                   className={`${classes.buttons} ${classes.buttonEnter}`}
                   disableElevation
                   onClick={enterSendCode}
               >
                   { load ? 'Подождите...' : 'Войти' }
               </Button>
           </div>
       </div>
    )
};

export default CardConfirmData;