import {
    Button,
    useMediaQuery,
    Dialog,
    DialogTitle,
    TextField,
    DialogContent,
    Grid,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core'
import instance from 'API/instance'
import AnimateLoader from 'css/svg/AnimateLoader'
import Unload from 'css/svg/Unload'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import parse from 'date-fns/parse'
import React, { useCallback, useState } from 'react'
import { useExportDialogStyles } from './styles'

export const ExportDialog = React.memo(({
    buttonLoading = false, // статус загрузки
    StartIcon = Unload, // иконка кнопки
    children = 'Выгрузить', // имя кнопки
    dialogTitle = 'Выгрузка', // тайтл диалога
    types = [{ title: 'Обычная выгрузка', onClick: (f, t) => { }, noDate: false }], // типы выгрузки. {title: String, onClick: (dateFrom, dateTo) => {}}
}) => {

    const [dialogOpen, setDialogOpen] = useState(false)
    const [typesState, setTypesState] = useState(() => {
        return types.map((type) => ({ ...type, dateFrom: format(new Date(), 'yyyy-MM-dd'), dateTo: format(new Date(), 'yyyy-MM-dd') }))
    })
    const typesStateComparse = JSON.stringify(typesState)

    const [typesOptions, setTypesOptions] = useState(async () => {
        const {data} = await instance.get('/api/citizen_types')
        setTypesOptions(data || [])
    } )

    const [selectedType, setSelectedType] = useState('')

    const classes = useExportDialogStyles()

    const isSmallScreen = useMediaQuery('(max-width: 550px)')

    const handleOpenDialog = useCallback(() => { setDialogOpen(true) }, [])
    const handleCloseDialog = useCallback(() => { setDialogOpen(false) }, [])

    const handleDateChange = useCallback((e, index) => {
        let stateCopy = [...typesState]
        stateCopy[index][e.target.id] = e.target.value
        setTypesState(stateCopy)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typesStateComparse])

    return (
        <>
            <div className={classes.wrapButtonBlue}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonBlue}
                    disableElevation
                    startIcon={isSmallScreen ? null : buttonLoading ? <AnimateLoader /> : <StartIcon />}
                    onClick={handleOpenDialog}
                >
                    {isSmallScreen ? buttonLoading ? <AnimateLoader /> : <StartIcon /> : buttonLoading ? 'Подождите...' : children}
                </Button>
            </div>
            {console.log(typesOptions)}
            <Dialog
                scroll='paper'
                onClose={handleCloseDialog}
                aria-labelledby="simple-dialog-title"
                open={dialogOpen}
                maxWidth='md'
                fullWidth

            >
                <DialogTitle id="simple-dialog-title" className={classes.dialogTitle} >{dialogTitle || 'Выгрузка'}</DialogTitle>

                <div style={{ padding: '0px 24px 16px 24px' }} >
                    <Grid container spacing={2}>
                        {typesState.map((type, index) =>
                            <Grid container item md={6} xs={12} spacing={2} key={index}>
                                <Grid container justify='center' style={{ textAlign: 'center' }} item xs={12} spacing={1}>
                                    <Grid item xs={12} className={classes.typeTitle} >
                                        {type.title}
                                    </Grid>
                                    {!type.noDate && (
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="dateFrom"
                                                    label="Дата от"
                                                    type="date"
                                                    variant='outlined'
                                                    value={type.dateFrom}
                                                    onChange={(e) => handleDateChange(e, index)}
                                                    size='small'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="dateTo"
                                                    label="Дата до"
                                                    type="date"
                                                    size='small'
                                                    variant='outlined'
                                                    value={type.dateTo}
                                                    onChange={(e) => handleDateChange(e, index)}
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            {!!type.withTypes && (
                                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                    <div className={classes.selectPage}>
                                                        <span className={classes.titleSelect}>Тип: </span>
                                                        <FormControl variant="outlined" classes={{root: classes.myFormControl}} >
                                                            <Select
                                                                size='small'
                                                                value={selectedType}
                                                                onChange={(e) => setSelectedType(e.target.value)}
                                                                classes={{
                                                                    root: classes.vp_rootSelect
                                                                }}
                                                            >
                                                                {(Array.isArray(typesOptions) ? typesOptions : [] ).map((option) => <MenuItem value={option.id}>{option.name}</MenuItem>)}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </Grid>
                                
                                <Grid item xs={12} className={classes.wrapButton}>                                    
                                    <Button
                                        variant="contained"
                                        className={`${classes.buttonFilter} ${classes.buttonFilterClose}`}
                                        disableElevation
                                        disabled={
                                            !type.dateTo
                                            || !type.dateFrom
                                            || isAfter(
                                                parse(type.dateFrom, 'yyyy-MM-dd', new Date()),
                                                parse(type.dateTo, 'yyyy-MM-dd', new Date())
                                            )
                                        }
                                        onClick={() => type.onClick(type.dateFrom, type.dateTo, selectedType)}
                                    >
                                        Скачать
                                    </Button>
                                </Grid>

                            </Grid>
                        )}
                    </Grid>
                </div>

            </Dialog>
        </>
    )
})
