export const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_MANUALS':
            return {
                ...state,
                voteTypes: action.payload[0] ? action.payload[0].data : [],
                callResults: action.payload[1] ? action.payload[1].data : [],
                //callBackReasons: action.payload[2] ? action.payload[2].data : [],
                failReasons: action.payload[2] ? action.payload[2].data : [],
            }
    }
};