import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    tableContainer: {
        boxShadow: 'none',
        overflow: 'visible',

        '@media(max-width: 1150px)':{
            overflow: 'scroll'
        }
    },

    vp_th: {
        fontWeight: 'bold',
        fontSize: 12,
        color: 'rgba(41,54,73, .5)',
        lineHeight: '14px',
        position: 'relative',
        borderBottom: '1px solid #E5E8EA',
        paddingTop: 4,
        paddingBottom: 4,
    },
    vp_row: {
        fontFamily: 'Rubik',
        fontSize: 12,
        color: theme.black,

        '&:nth-child(2n)': {
            background: 'rgba(0,164,216,.05)'
        }

    },
    vp_td: {
        position: 'relative',
        boxSizing: 'border-box',
        borderRight: '1px solid #E5E8EA',

        '&:last-child': {
            borderRight: 0,
        }
    },
    noData: {
        paddingTop: 30,
        paddingBottom: 30,
        textAlign: 'center'
    }

}));