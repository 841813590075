import React from 'react'
import { Accordion as ExpansionPanel, AccordionSummary as ExpansionPanelSummary, Typography, AccordionDetails  as ExpansionPanelDetails, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getFromLocalStorage, setToLocalStorage } from 'utils/localStorage';

function Hide({ question, c, answers, inWork, subText, otvety, ind, selectedAnswers, setSelectedAnswers, keyToSave }) {

    const handleAnsw = (ind, id) => () => { //кладем id выбранного ответа в состояние-массив
        const arr = [...selectedAnswers];
        arr[ind] = id;
        setSelectedAnswers(arr)
        if(keyToSave){
            const prevSavedData = getFromLocalStorage(keyToSave,true) || {}
            setToLocalStorage({obj:{
                key: keyToSave,
                item: {
                    ...prevSavedData,
                    selectedAnswers: arr
                }
            }})
        }
    };

    return (
        <>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>{ind + 1}. {question}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    style={{display: 'flex', flexDirection: 'column'}}
                >
                    <div className={c.buttonWrap}>
                        {
                            otvety.sort((a, b) => a.order_by - b.order_by).map(item => {
                                return item.title ? (
                                    <Button
                                        disabled={!inWork}
                                        disableElevation
                                        key={item.id}
                                        color='primary'
                                        variant='contained'
                                        className={selectedAnswers.includes(item.id)  ?
                                            c.scriptButton : c.disabledScriptButton}
                                        onClick={handleAnsw(ind, item.id)}
                                        classes={{
                                            disabled: c.disabled
                                        }}
                                    >
                                        {item.title}
                                    </Button>
                                ) : null
                            })
                        }
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: subText || '' }} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </>
    )
}

export default Hide;
