import { makeStyles } from '@material-ui/styles'

export const useModalStyles = makeStyles(theme => ({
    headingInput: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
        color: theme.black,
        marginBottom: 16,
    },
    oldPhone: {
        marginBottom: 16,
    },
    buttonSave: {
        width: 295,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#53BB63',
        fontSize: 14,
        color: '#fff',
        marginTop: 32,
        transition: 'all .3s',

        '&:hover': {
            background: '#5CD06E',
        }
    },
    error: {
        color: 'tomato',
        position: 'relative',
        top: 12
    }
}));