import instance from './instance'

export const postLogin = async (body) => {
    let res = await instance.post('/api/login', body);
    return res
};

export const getUser = async () => {
    try {
        let res = await instance.get('/api/user/me');
        return res
    } catch (e) {
        if(e.response.status !== 200) {
            //выводим ошибку
        }
        console.error(e)
        return e.response.status
        
    }
};

