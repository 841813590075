import React, { useState, useEffect } from 'react';
import SelecForFilter from '../SelectForFilter/SelectForFilter';
import FieldFilter from '../FieldFilter/FieldFilter';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { toDate } from '../../utils/toDate';
import instance from '../../API/instance';
import AnimateLoader from '../../css/svg/AnimateLoader';
import { useStyles } from './style';
import { removeFromLocalStorage } from '../../utils/localStorage';

function InfoCloudVolunteer({ regionsOptions, data, fetchOmsu, fetchRegions, dataTbody, setDataTbody }) {

    const classes = useStyles();

    const [load, setLoad] = useState(false);
    const [loadSendPassword, setLoadSendPassword] = useState(false)

    const [state, setState] = useState({
        change: false,
        mobilizator_hash: data.mobilizator_hash || '',
        area: data.omsu && data.omsu.region.area ? data.omsu.region.area : null,
        region: data?.region ?? null,
        omsu: data.omsu ? data.omsu : null,
        address: data.address ? data.address : '',
        email: data.email ? data.email : '',
        phone: data.phone ? data.phone : '',
        touchArea: false,
        kpps: data.kpps?.join(',') || '',
        inns: data?.inns?.join(',') || '',
        uiks: data?.uik_numbers?.join(',') || ''
    });


    const [error, setError] = useState({
        omsuError: false,
        mobilizator_hash:false,
        addressError: false,
        emailError: false,
        phoneError: false,
        kppError: false,
        innError: false,
        uikError: false,
    });

    useEffect(() => { // загружаем данные в регионы и омсу при первом монтировании в зависимости от Фед. окр.
        fetchRegions();
    }, []);

    useEffect(() => { // при изменении региона меняем омсу
        if (state.region?.id) {
            fetchOmsu({ "filters[region_id]": state.region.id }).then(() => {
                setState(prevState => ({
                    ...prevState,
                    omsu: null,
                }))
            })
        }
    }, [state.region]);


    const handleValueSelect = (name) => (event, val) => { //обработчик для селектов фильтра
        setState({
            ...state,
            change: true,
            [name]: val,
            touchArea: name === 'area',
        })
    };

    const handleBlur = (event) => { //обработчки на инпуты в фильтрах
        setState({
            ...state,
            [event.target.name]: event.target.name === 'phone'
                ? event.target.value && event.target.value.trim().match(/\d+/g).join('').slice(1)
                : event.target.value.trim()
        })
    };

    const sendPassword = async () => { //отправить пароль
        const body = new FormData();
        body.append('user_id', data.user.id);
        setLoadSendPassword(true);

        try {
            const res = await instance.post('/api/user/password/generate_and_send', body);
            if (res.data === 'ok') {
                setError({ globalMessage: { success: true, message: 'Новый пароль отправлен на почту' } })
            } else {
                setError({ globalMessage: { success: false, message: 'Ошибка в отправке пароля' } })
            }
        } catch (err) {
            console.log(err)
            if (err?.response?.status === 401) {
                removeFromLocalStorage('token');
                window.location.href = '/admin_login'
            }

            if (err?.response?.status === 404 || err?.response?.status === 500 || !err?.response?.data.errors) {
                setError({ globalMessage: { success: false, message: err.message } })
            } else {
                setError({ globalMessage: { success: false, message: err?.response?.data.errors.detail } });
            }
        } finally {
            setLoadSendPassword(false);
        }
    };

    const saveChanges = async () => { //сохранить изменения
        setError({});

        const body = {}
        body['email'] = state.email || ''
        body['mobilizator_hash'] = state.mobilizator_hash || ''
        body['uik_numbers'] = state?.uiks?.split(',')?.filter((i) => !!i) || ''
        body['inns'] = state.inns || ''
        body['kpps'] = state.kpps || ''
        body['address'] = state.address || ''
        body['phone'] = state.phone || ''
        body['region'] = state.region?.id || ''        

        setLoad(true);

            try {
                await instance.patch(`/api/volunteers/${data.id}/update_info`, body);
                setError({ globalMessage: { success: true, message: 'Данные успешно сохранены' } })

                const newEntries = [...dataTbody.entries];
                const findVolonter = newEntries.find(vol => vol.id === data.id);
                findVolonter.email = state.email;
                findVolonter.phone = state.phone;

                setDataTbody({
                    ...dataTbody,
                    entries: newEntries,
                });

            } catch (err) {
                
                if (err?.response?.status === 401) {
                    removeFromLocalStorage('token');
                    window.location.href = '/admin_login'
                }

                if (err?.response?.status === 404 || err?.response?.status === 500 || !err?.response?.data.errors) {
                    setError({ globalMessage: { success: false, message: err.message } })
                } else {
                    setError({ globalMessage: { success: false, message: err?.response?.data?.errors?.detail } });
                }
            } finally {
                setLoad(false)
            }
    };

    function Alert(props) {
        return <MuiAlert elevation={3} variant="filled" {...props} />;
    }

    const closeMessage = () => {
        setError({})
    };

    const globalMessage = () => {
        if (Boolean(error.globalMessage) && !error.globalMessage.success) {
            return (
                <Alert severity="error" className={classes.vp_alert}>
                    { typeof error.globalMessage.message === 'object'
                        ? Object.values(error.globalMessage.message).join(' ; ')
                        : error.globalMessage.message
                    }
                    <CloseIcon className={classes.vpIcon} onClick={closeMessage} />
                </Alert>
            )
        } else if (Boolean(error.globalMessage) && error.globalMessage.success) {
            return (
                <Alert severity="success" className={classes.vp_alert}>
                    { error.globalMessage.message}
                    <CloseIcon className={classes.vpIcon} onClick={closeMessage} />
                </Alert>
            )
        }
        return null;
    };

    return (
        <div className={classes.wrapperInfoCloudVolunteer}>
            <div className={classes.infoLine}>
                <div className={classes.leftPart}>
                    <span className={classes.label}>Регион: </span>
                    <div className={classes.sectionFilter}>
                        <SelecForFilter
                            noLabel
                            value={state.region}
                            disabled={!regionsOptions.length}
                            options={regionsOptions}
                            rounded
                            handleValueSelect={handleValueSelect('region')}
                        />
                    </div>
                </div>

                <div className={classes.rightPart}>
                    <span className={classes.label}>Электронная почта:</span>

                    <div className={classes.sectionFilter}>
                        <FieldFilter
                            redBorder={error.emailError}
                            type={'email'}
                            rounded
                            key={state.email}
                            value={state.email}
                            onBlur={handleBlur}
                            name={'email'}
                            noLabel
                        />
                        {error.emailError ? <small className={classes.errorMessage}>Неккоректное значение email</small> : null}
                    </div>
                </div>

            </div>

            <div className={classes.infoLine}>
                <div className={classes.leftPart}>
                    <span className={classes.label}>УИК:</span>

                    <div className={classes.sectionFilter}>
                        <FieldFilter
                            redBorder={error.uikError}
                            key={state.uiks}
                            value={state.uiks}
                            onBlur={handleBlur}
                            name={'uiks'}
                            noLabel
                            rounded
                        />
                        {error.uikError ? <small className={classes.errorMessage}>Неккоректное значение УИК</small> : null}
                    </div>
                </div>

                <div className={classes.rightPart}>
                    <Button
                        disabled={loadSendPassword || load}
                        variant="contained"
                        className={`${classes.buttonBlue} ${classes.buttonSendPassword}`}
                        disableElevation
                        onClick={sendPassword}
                        startIcon={loadSendPassword ? <AnimateLoader color={'#fff'} /> : null}
                    >
                        {loadSendPassword ? 'Подождите...' : 'Отправить пароль'}
                    </Button>
                </div>
            </div>

            <div className={classes.infoLine}>
                <div className={classes.leftPart}>
                    <span className={classes.label}>ИНН:</span>

                    <div className={classes.sectionFilter}>
                        <FieldFilter
                            redBorder={error.innError}
                            key={state.inns}
                            value={state.inns}
                            onBlur={handleBlur}
                            name={'inns'}
                            rounded
                            noLabel
                        />
                        {error.innError ? <small className={classes.errorMessage}>Неккоректное значение ИНН</small> : null}
                    </div>
                </div>

                <div className={classes.rightPart}>
                    <span className={classes.label}>Номер телефона:</span>

                    <div className={classes.sectionFilter}>
                        <FieldFilter
                            redBorder={error.phoneError}
                            key={state.phone}
                            value={state.phone}
                            onBlur={handleBlur}
                            rounded
                            name={'phone'}
                            maskInput={['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                            placeholdermask={'+7(XXX)XXX-XX-XX'}
                            noLabel
                        />
                        {error.phoneError ? <small className={classes.errorMessage}>Неккоректное значение телефона</small> : null}
                    </div>
                </div>
            </div>

            <div className={classes.infoLine}>
                <div className={classes.leftPart}>
                    <span className={classes.label}>КПП:</span>

                    <div className={classes.sectionFilter}>
                        <FieldFilter
                            redBorder={error.kppError}
                            key={state.kpps}
                            value={state.kpps}
                            onBlur={handleBlur}
                            name={'kpps'}
                            noLabel
                            rounded
                        />
                        {error.kppError ? <small className={classes.errorMessage}>Неккоректное значение КПП</small> : null}
                    </div>
                </div>
                <div className={classes.rightPart}>
                    <Button
                        disabled={load || loadSendPassword}
                        variant="contained"
                        className={`${classes.buttonBlue} ${classes.buttonSaveChange}`}
                        disableElevation
                        onClick={saveChanges}
                        startIcon={load ? <AnimateLoader color={'#fff'} /> : null}
                    >
                        {load ? 'Подождите...' : 'Сохранить изменения'}
                    </Button>
                </div>
            </div>

            <div className={classes.infoLine}>
                <div className={classes.leftPart}>
                    <span className={classes.label}>HASH:</span>

                    <div className={classes.sectionFilter}>
                        <FieldFilter
                            redBorder={error.mobilizator_hash}
                            key={state.mobilizator_hash}
                            value={state.mobilizator_hash}
                            onBlur={handleBlur}
                            name={'mobilizator_hash'}
                            noLabel
                            rounded
                        />
                        {error.mobilizator_hash ? <small className={classes.errorMessage}>Неккоректное значение HASH</small> : null}
                    </div>
                </div>
            </div>



            { globalMessage()}
        </div>
    )
}

export default InfoCloudVolunteer;
