import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    vp_dialogRoot: {
        '& .MuiBackdrop-root': {
            background: 'rgba(0,0,0,.3)'
        }
    },
    vp_paper: {
        padding: 40,
        boxSizing: 'border-box',
        width: props => props.widthDialog,
        maxWidth: '100%',
        overflow: 'visible',
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: -40,
        cursor: 'pointer',
        color: '#fff',
        transition: 'all .3s',

        '&:hover': {
            transform: 'scale(1.1)'
        }
    }
}));