import React from 'react'
import { useHistory } from "react-router-dom";
import { useNavbarStyle } from './style'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMediaQuery } from '@material-ui/core';
import { DesktopNavbar } from './DesktopNavbar';
import { MobileNavbar } from './MobileNavbar';



export let links = [
    { title: 'Задачи', path: '/admin/citizens', volunteerViews: true, curatorViews: true },
    { title: 'Реестр операторов', path: '/admin/volunteers', volunteerViews: false, curatorViews: true },
    { title: 'Реестр УИК', path: '/admin/pollingstation', volunteerViews: false, curatorViews: false },
    { title: 'Реестр пользователей', path: '/admin/users', volunteerViews: false, curatorViews: false },
    { title: 'Реестр организаций', path: '/admin/organizations', volunteerViews: false, curatorViews: false },
    { title: 'Реестр скриптов', path: '/admin/scripts', volunteerViews: false, curatorViews: false },
];

function Navbar({ volunteer, fio, other }) {

    const c = useNavbarStyle();
    const history = useHistory();
    const isMdScreen = useMediaQuery('(max-width:1150px)');

    const logout = () => {

        if(volunteer && volunteer.citizen) {
            toast.info("Вы не завершили задачу");
            return false;
        }

        localStorage.removeItem('token');
        history.push('/admin_login')
    };

    

    return (
        <div className={c.navbarWrapper}>
            {isMdScreen
                ? <MobileNavbar 
                    classes={c}
                    links={links}
                    other={other}
                    volunteer={volunteer} 
                    fio={fio} 
                    logout={logout}
                />
                 
                : <DesktopNavbar 
                    c={c} 
                    links={links} 
                    other={other} 
                    volunteer={volunteer} 
                    logout={logout}
                    fio={fio}
                />
            }
            <ToastContainer />
        </div>
    )
}

export default Navbar
