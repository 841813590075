import React                                from 'react'
import { useHistory }                       from 'react-router-dom';
import Collapse                             from '@material-ui/core/Collapse';
import queryString                          from 'query-string';
import SelecForFilter                       from '../SelectForFilter/SelectForFilter';
import FieldFilter                          from '../FieldFilter/FieldFilter';
import Button                               from '@material-ui/core/Button';
import Tooltip                              from '@material-ui/core/Tooltip';
import { useStyles }                        from './style';
import { Grid } from '@material-ui/core';

const FilterBlockPollingStation = ({openCollapse, omsuOptions, handleValueSelect, handleBlur, filterState, resetFilter, areaOptions, regionsOptions, paramsData}) => {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Grid container spacing={3} className={classes.wrapFilter}>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <SelecForFilter
                        readonly
                        label={areaOptions.length ? 'Федеральный округ' : 'Загрузка данных...'}
                        value={filterState.area}
                        options={areaOptions}
                        handleValueSelect={handleValueSelect('area')}
                    />
                </Grid>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <SelecForFilter
                        label={regionsOptions.length ? 'Регион' : 'Загрузка данных...'}
                        value={filterState.region}
                        options={regionsOptions}
                        handleValueSelect={handleValueSelect('region')}
                    />
                </Grid>

                <Tooltip
                    title="Сначала выберите регион"
                    placement="top-start"
                    disableHoverListener={Boolean(filterState.region)}
                    disableFocusListener={Boolean(filterState.region)}
                >
                    <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                        <SelecForFilter
                            disabled={!filterState.region}
                            label={!filterState.region || omsuOptions.length
                                ? 'Муниципальное образование'
                                : 'Загрузка данных...'}
                            value={filterState.omsu}
                            options={omsuOptions}
                            handleValueSelect={handleValueSelect('omsu')}
                        />
                    </Grid>
                </Tooltip>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <FieldFilter
                        key={filterState.address}
                        value={filterState.address}
                        onBlur={handleBlur}
                        name={'address'}
                        label={'Адрес помещения УИК'}
                    />
                </Grid>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <FieldFilter
                        key={filterState.phone}
                        value={filterState.phone}
                        onBlur={handleBlur}
                        name={'phone'}
                        label={'Телефон УИК'}
                    />
                </Grid>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <FieldFilter
                        type={'number'}
                        key={filterState.numberUik}
                        value={filterState.numberUik}
                        onBlur={handleBlur}
                        name={'numberUik'}
                        label={'№ Терит. избир. комиссии'}
                    />
                </Grid>

                <Grid item md={3} sm={6} xs={12} className={`${classes.sectionFilter} ${classes.buttonContainer}`}>
                    <Button
                        variant="contained"
                        className={classes.buttonApply}
                        disableElevation
                        onClick={() => {
                            if(Object.keys(paramsData()).length) {
                                const url = queryString.stringifyUrl({
                                    url: '/admin/pollingstation',
                                    query: paramsData()
                                });
                                history.push(url)
                            }
                        }}
                    >
                        Применить
                    </Button>

                    <Button
                        variant="outlined"
                        className={classes.buttonReset}
                        disableElevation
                        onClick={resetFilter}
                    >
                        Сбросить
                    </Button>
                </Grid>
            </Grid>
        </Collapse>
    )
};

export default FilterBlockPollingStation;