import { makeStyles } from '@material-ui/styles'


export const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    main:{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '315px',
    },
    image:{
        height: '176px', 
        width: '131px', 
        marginBottom: '20px',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}))