import axios from 'axios'
import { getFromLocalStorage } from '../utils/localStorage'

const instance = axios.create({
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
});

instance.interceptors.request.use(
    config => {
        const token = getFromLocalStorage('token');

        if (token) {
            config.headers['jwt'] = `${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    config => {
        return config
    },
    error => {
        if (error?.response?.status === 503) {
            window.location.pathname = '/disconnected'
          }
        return Promise.reject(error)
    }
);

export default instance