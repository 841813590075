import React, { useState } from 'react'
import { useManualState } from '../../Context/ManualContext/ManualProvider'
import { FormControlLabel, Typography } from '@material-ui/core'
import StyledCheckbox from '../../Common/Checkbox'
import ReasonSelect from './CallDialog/ReasonSelect'
import Tooltip from '@material-ui/core/Tooltip';
import { getFromLocalStorage, setToLocalStorage } from 'utils/localStorage'

const SUCCESS = 1

function CallBar({ c, handleResultChange, isEnd, setVoteType, setReason, voteTypes, failReasons, callMade, pensId, isAllQuestionsSelected, isTypeSix, hasNoPhone }) {
    const { callResults } = useManualState(); //callResults - 2 чекбоксa успешно-неуспешно
    const [success, setSuccess] = useState(getFromLocalStorage(pensId, true)?.success || 0);
    const [chosen, setChosen] = useState(getFromLocalStorage(pensId, true)?.result || 0);
    const [subChosen, setSubChosen] = useState(getFromLocalStorage(pensId, true)?.reason || 0);


    const onResultChange = (succ, id) => {
        const prevDataSaved = getFromLocalStorage(pensId, true) || {}

        setToLocalStorage({
            obj:{
                key: pensId,
                item: {
                    ...prevDataSaved,
                    success: succ,
                    result: id,
                    reason: null
                }
            } 
        })
        setChosen(id);
        setVoteType(null);
        setSubChosen(null);
        setReason(null);
        setSuccess(succ) 
    };

    const EndStepSelect = (id) => {
        handleResultChange(id, success, chosen)
    };

    const getTooltipTitle = (item) => {
        if(item.id === SUCCESS){
            return ( !callMade && !isTypeSix ) ? "Необходимо осуществить звонок" : 'Необходимо ответить на вопросы скрипта'
        }else{
            return "Необходимо осуществить звонок"
        }
    }

    const getTooltipDisabled = (item) => {
        if(item.id === SUCCESS){
            return isAllQuestionsSelected()
        }else{
            return true
        }
    }

    const disabledTooltip = result => (callMade || isTypeSix || hasNoPhone) && getTooltipDisabled(result)
    
    return (
        <>
            {(!isEnd)
                ? <>
                    <Typography style={{ fontWeight: '500', fontSize: '20px', marginTop: '19px' }} >Укажите результат звонка</Typography>
                    <form >
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            {callResults && (
                                callResults.map((result, ind) =>
                                    <Tooltip
                                        key={ind}
                                        title={getTooltipTitle(result)}
                                        placement="top-start"
                                        classes={{
                                            tooltip: c.tooltip
                                        }}
                                        disableFocusListener={disabledTooltip(result)}
                                        disableHoverListener={disabledTooltip(result)}
                                        disableTouchListener={disabledTooltip(result)}
                                    >
                                        <FormControlLabel
                                            control={
                                                <StyledCheckbox
                                                    disabled={isEnd || ( !callMade && !isTypeSix ) || !getTooltipDisabled(result)}
                                                    onChange={() => onResultChange(result.is_succeeded, result.id)}
                                                    name={result.name}
                                                    checked={chosen === result.id}
                                                    color="primary"
                                                />
                                            }
                                            label={result.name}
                                        />
                                    </Tooltip>
                                )
                            )}
                        </div>
                    </form>
                </>
                : null
            }

            <ReasonSelect
                isEnd={isEnd}
                chosen={subChosen}
                setChosen={setSubChosen}
                success={success}
                handleResultChange={EndStepSelect}
                open={Boolean(chosen)}
                list={success ? voteTypes : failReasons}
                c={c}
            />
        </>
    )
}

export default CallBar;
