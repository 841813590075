import React from 'react'
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Collapse from '@material-ui/core/Collapse';
import SelecForFilter from '../SelectForFilter/SelectForFilter';
import FieldFilter from '../FieldFilter/FieldFilter';
import Button from '@material-ui/core/Button';
import { useStyles } from './style';
import { useAuthState } from '../../Context/AdminAuthContext/AdminAuthProvider';
import { toRole } from '../../utils/toRole';
import { Grid } from '@material-ui/core';

const OPERATOR_SYSTEM_NAME = 'volunteer'

const FilterBlockCitizens = ({ openCollapse, handleValueSelect, handleBlur, filterState, resetFilter, voteTypeOptions, statusCardOptions, regionsOptions, hasPhoneOptions, paramsData }) => {

    const classes = useStyles();
    const history = useHistory();
    const { roles } = useAuthState();

    return (
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Grid container spacing={2} className={classes.wrapFilter}>
                {
                    toRole(roles) !== 'volunteer' ?
                        <>
                            <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                                <SelecForFilter
                                    label={regionsOptions.length ? 'Регион' : 'Загрузка данных...'}
                                    value={filterState.region}
                                    options={regionsOptions}
                                    handleValueSelect={handleValueSelect('region')}
                                />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                                <FieldFilter
                                    maskInput={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
                                    placeholdermask={'###-###-### ##'}
                                    key={filterState.snils}
                                    value={filterState.snils}
                                    onBlur={handleBlur}
                                    name={'snils'}
                                    label={'Номер СНИЛС'}
                                />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                                <FieldFilter
                                    type={'number'}
                                    key={filterState.idTask}
                                    value={filterState.idTask}
                                    onBlur={handleBlur}
                                    name={'idTask'}
                                    label={'ID Задачи'}
                                />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                                <FieldFilter
                                    type={'text'}
                                    key={filterState.uik_numbers}
                                    value={filterState.uik_numbers}
                                    onBlur={handleBlur}
                                    name={'uik_numbers'}
                                    label={'УИК'}
                                />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                                <SelecForFilter
                                    readonly
                                    label={'Наличие номера телефона'}
                                    value={filterState.availabilityPhone}
                                    options={hasPhoneOptions}
                                    handleValueSelect={handleValueSelect('availabilityPhone')}
                                />
                            </Grid>


                        </> : null
                }

                {roles.every((i) => i.system_name !== OPERATOR_SYSTEM_NAME) && (
                    <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                        <SelecForFilter
                            readonly
                            label={voteTypeOptions.length ? 'Вид голосования' : 'Загрузка данных...'}
                            value={filterState.vote_type}
                            options={voteTypeOptions}
                            handleValueSelect={handleValueSelect('vote_type')}
                        />
                    </Grid>
                )}

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <SelecForFilter
                        readonly
                        label={statusCardOptions.length ? 'Статус активности' : 'Загрузка данных...'}
                        value={filterState.status_card}
                        options={statusCardOptions}
                        handleValueSelect={handleValueSelect('status_card')}
                    />
                </Grid>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <FieldFilter
                        type={'text'}
                        key={filterState.surname}
                        value={filterState.surname}
                        onBlur={handleBlur}
                        name={'surname'}
                        label={'Фамилия'}
                    />
                </Grid>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <FieldFilter
                        type={'text'}
                        key={filterState.name}
                        value={filterState.name}
                        onBlur={handleBlur}
                        name={'name'}
                        label={'Имя'}
                    />
                </Grid>

                <Grid item md={3} sm={6} xs={12} className={classes.sectionFilter}>
                    <FieldFilter
                        type={'text'}
                        key={filterState.patronymic}
                        value={filterState.patronymic}
                        onBlur={handleBlur}
                        name={'patronymic'}
                        label={'Отчество'}
                    />
                </Grid>

                {/*<div className={classes.sectionFilter}>
                    <SelecForFilter twoLineLabel label={'Есть ли необходимость в доставке продуктов и лекарств'} />
                </div>*/}

                <Grid item md={3} sm={6} xs={12} className={`${classes.sectionFilter} ${classes.buttonContainer}`}>
                    <Button
                        variant="contained"
                        className={classes.buttonApply}
                        disableElevation
                        onClick={() => {
                            if (Object.keys(paramsData()).length) {
                                const url = queryString.stringifyUrl({
                                    url: '/admin/citizens',
                                    query: paramsData(),
                                    options: {
                                        arrayFormat: 'comma'
                                    }
                                });
                                history.push(url)
                            }
                        }}
                    >
                        Применить
                    </Button>

                    <Button
                        variant="outlined"
                        className={classes.buttonReset}
                        disableElevation
                        onClick={resetFilter}
                    >
                        Сбросить
                    </Button>
                </Grid>
            </Grid>
        </Collapse>
    )
};

export default FilterBlockCitizens;