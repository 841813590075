import React                            from 'react';
import { toDate }                       from '../../utils/toDate';
import {useModalStyles}                 from './styles';

const CommentHistory = ({ comments }) => {

    const classes = useModalStyles();

    return (
        <div className={classes.wrapComments}>
            {
                comments.length ?
                    <>
                        <h5 className={classes.commentHeading}>Комментарии</h5>
                        <div className={classes.steps}>
                            {
                                comments.map(elem => {
                                    return (
                                        <div className={classes.oneSteps} key={elem.id}>
                                            <div className={classes.leftOneStepContent}>
                                                <p className={classes.userFio}>
                                                    {elem.user.fio ? elem.user.fio : 'ФИО не указано'}
                                                </p>
                                                <p className={classes.dateStep}>
                                                    {toDate(new Date(elem.inserted_at))}
                                                </p>
                                            </div>

                                            <div className={classes.rightOneStepContent}>
                                                <span className={classes.textContent}>
                                                    {elem.text}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </> :
                    null
            }
        </div>
    )
};


export default CommentHistory;