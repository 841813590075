import React                from 'react';
import CircularProgress     from '@material-ui/core/CircularProgress';

const LoadingDialog = ({ height }) => {
    return (
        <div style={{
            width: '100%',
            height: height ? height : 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CircularProgress size={60} disableShrink />
        </div>
    )
};

export  default LoadingDialog;