import { makeStyles } from "@material-ui/core";



export const useExportDialogStyles = makeStyles(theme => ({
    selectPage: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        maxWidth: 350,
        width: '100%',
        paddingRight: 15,
    },
    titleSelect: {
        fontWeight: 'normal',
        fontSize: 15,
        marginRight: 10
    },
    vp_rootSelect: {
        paddingTop: 7,
        paddingBottom: 8,
        width: '100%',
    },
    myFormControl: {
        width: '100%',
        maxWidth: 350,
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.primary}!important`,
            borderWidth: 1
        }
    },
    buttonFilter: {
        width: 147,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#00A4D8',
        border: '1px solid #00A4D8',
        fontSize: 14,
        color: '#fff',
        marginLeft: 'auto',
        transition: 'all .3s',

        '&:hover': {
            background: '#00B8F0',
        },

        '@media(max-width: 550px)': {
            width: 'auto'
        }
    },
    buttonFilterClose: {
        background: '#fff',
        border: '1px solid #00A4D8',
        color: '#00A4D8',

        '&:hover': {
            background: '#FBFBFB',
        }
    },
    wrapButtonBlue: {
        flex: 1,
        marginLeft: 40,

        '@media(max-width: 550px)': {
            marginLeft: 8
        }
    },
    buttonBlue: {
        width: 185,
        height: 45,
        textTransform: 'none',
        borderRadius: 6,
        background: 'rgba(0,164,216,.1)',
        fontSize: 14,
        color: '#00A4D8',
        transition: 'all .3s',

        '&:hover': {
            background: 'rgba(0,164,216,.15)',
        },

        '@media(max-width: 550px)': {
            width: 'auto'
        }
    },
    wrapButton: {
        textAlign: 'center'
    },
    typeTitle: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 16
    },
    dialogTitle: {
        '& h2': {
            fontWeight: 500,
            fontSize: 36,
        }
    }
}))