import React, { useState }      from 'react';
import DialogAdmin              from '../DialogAdmin/DialogAdmin';
import CardConfirmData          from '../CardConfirmData/CardConfirmData';
import {useAuthState} from      "../../Context/AdminAuthContext/AdminAuthProvider";

const DialogConfirmData = ({ setForciblyFetch }) => {
    const {volunteer, phone, ...other } = useAuthState();
    const [openDialog, setOpenDialog] = useState(Boolean(volunteer) && !other.phone_confirmed);

    return (
        <DialogAdmin
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            widthDialog={556}
            disableBackdropClick
        >
            <CardConfirmData
                volunteer={volunteer}
                phone={phone}
                setOpenDialog={setOpenDialog}
                setForciblyFetch={setForciblyFetch}
            />
        </DialogAdmin>
    )
};

export default DialogConfirmData;