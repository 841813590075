import { makeStyles } from '@material-ui/core'
import React from 'react'

export const CloudRow = ({
    title,
    children
}) => {

    const classes = useStyles()

    return (
        <span className={classes.row}>
            <span className={classes.littleTitle}>{title || 'Нет заголовка'}:&nbsp;</span>
            <span>{children || 'Не указано'}</span>
        </span>
    )
}


const useStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginBottom: '8px'
    },
    littleTitle: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
})