import React, { useState, useEffect }   from 'react';
import { useParams, useHistory,
    useLocation }                       from 'react-router-dom';
import Fade                             from '@material-ui/core/Fade';
import TablesAll                        from '../../Components/TablesAll/TablesAll';
import { postUserTableList }            from '../../API/UsersTableList';
import { toDate }                       from '../../utils/toDate';
import { useStyles }                    from './style';
import Zamok                            from '../../css/svg/Zamok';
import RemoveIcon                       from '../../css/svg/RemoveIcon';

const Users = () => {
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();
    const { idPage = "1" } = useParams();

    const [load, setLoad] = useState(true);
    const [error, setError] = useState(false);
    const [dataTbody, setDataTbody] = useState(null);
    const [nav, setNav] = useState({
        page: idPage ? +idPage : 1,
        selectPage: 10,
    });

    const nameThead = ['id', 'Федеральный округ', 'Регион', 'Муниципальное образование', 'Роль', 'ФИО', 'Электронная почта', 'Телефон', 'Дата и время создания', 'Дата и время последнего входа', '', ''];

    useEffect(() => {
        setError(false);
        setLoad(true);

        setDataTbody(null);

        postUserTableList(idPage, nav.selectPage).then((res) => {
            setDataTbody(res);
            setLoad(false);
        }).catch(e =>{
            setError(e);
            setLoad(false);
        });
    }, [idPage, nav.selectPage]);

    const helperTdrender = (data) => {
        return [data.id, data.omsu ? data.omsu.region.area.name :  data.omsu ? data.omsu.region.name : '-', data.omsu ? data.omsu.name : '-','-','-', data.fio ? data.fio : '-','-','-', toDate(data.inserted_at),toDate(data.last_action),<Zamok />,<RemoveIcon />];
    };

    const handleChangePagination = (event, value) => { //обработчик для пагинации
        history.push(`/admin/users/${value}`);
        setNav({
            ...nav,
            page: value
        });
    };

    const handleChangeSelectPagination = (event) => { //обработчик селекта пагинации
        const url = location.search
            ? `/admin/users${location.search}`
            : `/admin/users`;

        history.push(url);

        setNav({
            ...nav,
            selectPage: event.target.value,
            page: 1,
        });
    };

    return (
        <Fade timeout={800} in>
            <div className={classes.users}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>Реестр пользователей</h3>

                        {/*<Button
                            variant="contained"
                            className={classes.buttonFilter}
                            disableElevation
                            endIcon={<FilterIcon />}
                        >
                            Фильтры
                        </Button>*/}
                    </div>
                </div>

                <TablesAll
                    error={error}
                    otherFunctionOpenModal={() => {}}
                    nameThead={nameThead}
                    load={load}
                    dataTbody={dataTbody}
                    helperTdrender={helperTdrender}
                    handleChangePagination={handleChangePagination}
                    page={+idPage}
                    selectPage={nav.selectPage}
                    handleChangeSelectPagination={handleChangeSelectPagination}
                />
            </div>
        </Fade>
    );
};

export default Users;