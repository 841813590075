import React from "react"
import { reducer } from "./AdminAuthReducer"
import { postLogin, getUser } from "../../API/Auth"
import { setToLocalStorage, removeFromLocalStorage } from "../../utils/localStorage"

const AuthStateContext = React.createContext();
const AuthActionsContext = React.createContext();

export function AdminAuthProvider({ children }) {

    const initialState = {
        id: -1,
        last_name: null,
        first_name: null,
        middle_name: null,
        errors: [],
        is_temp_password: false,
        roles: [],
        token: '',
    };

    const [state, dispatch] = React.useReducer(reducer, initialState);


    const getUserInfo = async () => {
        if(localStorage.getItem('token')) {

            try {
                let res = await getUser();
                if (res.data.id) {
                    dispatch({ type: 'SET_USER_INFO', data: res.data })
                } else {
                    dispatch({ type: 'AUTH_FAILED' })

                }
            } catch{
                dispatch({ type: 'AUTH_FAILED' });
                removeFromLocalStorage('token')
            }
        }
    };

    const loginUser = async (phone, password) => {
        const body = new FormData();

        body.append('login', phone);
        body.append('password', password);
        let res;

        try {
            let res = await postLogin(body);
            if (res.data.token) {
                setToLocalStorage({
                    key: 'token',
                    value: res.data.token
                });
                getUserInfo();
                return res.data
            } else {
                return res.data
            }
        } catch{
            return 0
        }
    };





    const actions = {
        loginUser,
        getUserInfo
    }

    return (
        <AuthStateContext.Provider value={state}>
            <AuthActionsContext.Provider value={actions}>
                {children}
            </AuthActionsContext.Provider>
        </AuthStateContext.Provider>
    )

}





export function useAuthState() {
    return React.useContext(AuthStateContext)
}

export function useAuthActions() {
    return React.useContext(AuthActionsContext)
}