import React from 'react'

function Zamok() {
    return (
        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 6.66683H14.6667C14.8877 6.66683 15.0996 6.75463 15.2559 6.91091C15.4122 7.06719 15.5 7.27915 15.5 7.50016V17.5002C15.5 17.7212 15.4122 17.9331 15.2559 18.0894C15.0996 18.2457 14.8877 18.3335 14.6667 18.3335H1.33333C1.11232 18.3335 0.900358 18.2457 0.744078 18.0894C0.587797 17.9331 0.5 17.7212 0.5 17.5002V7.50016C0.5 7.27915 0.587797 7.06719 0.744078 6.91091C0.900358 6.75463 1.11232 6.66683 1.33333 6.66683H3V5.8335C3 4.50741 3.52678 3.23564 4.46447 2.29796C5.40215 1.36028 6.67392 0.833496 8 0.833496C9.32608 0.833496 10.5979 1.36028 11.5355 2.29796C12.4732 3.23564 13 4.50741 13 5.8335V6.66683ZM7.16667 13.1102V15.0002H8.83333V13.1102C9.15107 12.9267 9.3994 12.6436 9.5398 12.3046C9.6802 11.9656 9.70483 11.5898 9.60988 11.2354C9.51492 10.881 9.30568 10.5679 9.0146 10.3445C8.72353 10.1212 8.36689 10.0001 8 10.0001C7.63311 10.0001 7.27647 10.1212 6.9854 10.3445C6.69432 10.5679 6.48508 10.881 6.39012 11.2354C6.29517 11.5898 6.3198 11.9656 6.4602 12.3046C6.6006 12.6436 6.84893 12.9267 7.16667 13.1102ZM11.3333 6.66683V5.8335C11.3333 4.94944 10.9821 4.10159 10.357 3.47647C9.7319 2.85135 8.88406 2.50016 8 2.50016C7.11594 2.50016 6.2681 2.85135 5.64298 3.47647C5.01786 4.10159 4.66667 4.94944 4.66667 5.8335V6.66683H11.3333Z" fill="#D81A00"/>
        </svg>
    )
}

export default Zamok;