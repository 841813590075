import React, { useState } from 'react'
import { Dialog,TextField, Typography, Button } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useManualState } from '../../Context/ManualContext/ManualProvider'
import { patchUpdateStatus } from '../../API/Citizens'



function CallBackDialog({ open, setOpen, c, pensId, setFullInfo }) {

    const { callBackReasons } = useManualState()

    const [reason, setReason] = useState({})

    const onReasonChange = (e,obj) => {
        setReason(obj)
    }

    const cleanState = () => {
        setReason({})
        setOpen(false)
    }

    const onAccept = async (e) => {
        e.preventDefault()
        let res = await patchUpdateStatus(pensId, {
            call_back_reason: reason.id,
        })
        if (res.data.id) {
            setFullInfo(res.data)
        }
        cleanState()
    }


    return (
        <Dialog
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={c.dialog}
            maxWidth={'xs'}
        >
            <div style={{ padding: '30px' }}>
                <Typography style={{ fontWeight: '500', fontSize: '20px', marginBottom: '24px' }} >
                Вы уверены, что хотите перевести карточку в статус “Повторный созвон”? Действие нельзя будет отменить.
                </Typography>
                <form onSubmit={onAccept} >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Autocomplete
                            id="reason select"
                            options={callBackReasons}
                            getOptionLabel={(option) => option.name || null}
                            onChange={onReasonChange}
                            fullWidth
                            disableClearable
                            openOnFocus
                            autoHighlight
                            blurOnSelect
                            onChange={onReasonChange}
                            renderInput={(params) =>
                                <TextField {...params}
                                    size='small'
                                    label="Выберите причину"
                                    variant="outlined"
                                />}
                        />
                    </div>

                    <Typography style={{ color: '#8A929D' }}  >Это действие нельзя будет отменить</Typography>

                    <Button
                        variant='contained'
                        size='small'
                        color='secondary'
                        className={c.callBackButton}
                        disabled={!reason.name}
                        type='submit'
                    >
                        Повторный созвон
                </Button>
                </form>
            </div>
        </Dialog>
    )
}

export default CallBackDialog
