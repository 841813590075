import React, { useContext, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import disconnected from './disconnected.png'
import { getUser } from 'API/Auth'
import { useHistory } from 'react-router'
import { FilterContext } from 'Context/FilterContext/FilterContext'

export const Disconnected = () => {

    const history = useHistory()
    const { fetchRegions } = useContext(FilterContext)
    const [isFetching, setIsFetching] = useState(false)

    const handleClick = async () => {
        setIsFetching(true)
        const res = await fetchRegions()
        if(!res){
            setIsFetching(false)
        }else{
            history.push('/')
        }
    }

    return (
        <div
            style={{ 
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent:'center',
             }}
        >
            <div
                style={{
                    width: '100%',
                    maxWidth: '518px',
                    height: '335px',
                    boxSizing: 'border-box',
                    background: '#004B92',
                    padding: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 20,
                    marginRight: 4,
                    marginLeft: 4,
                }}
            >
                <img src={disconnected} alt='disconnected' style={{width: '100%'}} />
                <div
                    style={{
                        fontSize: 30,
                        marginTop: 24,
                        marginBottom: 24,
                        fontFamily: "Rubik",
                        textAlign: 'center',
                        color: 'white'
                    }}
                >
                    Сайт временно недоступен, попробуйте позже
                </div>

                <Button
                    size='small'
                    style={{
                        background: 'white',
                        fontFamily: 'Rubik',
                        width: 200,
                        color: '#004B92',
                        fontSize: 14,
                        textTransform: 'none',
                        height: 45
                    }}
                    onClick={handleClick}
                    disabled={isFetching}
                >
                    {isFetching ? <CircularProgress color='#004B92' size={20} /> : 'Попробуйте снова'}
                </Button>

            </div>
        </div>
    )
}
