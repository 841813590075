import { makeStyles } from "@material-ui/core";



export const useFooterStyles = makeStyles(theme => ({
    footerWrapper: {
        width: '100%',
        minHeight: 75,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid rgba(0,0,0,.2)',

        '@media(max-width: 520px)':{
            paddingTop: 16,
            paddingBottom: 8
        }
    },
    footer: {
        color: 'rgb(219 0 27)',
        fontSize: 14,
        height: '100%',
        textDecoration: 'none',
        textTransform: 'none',
        borderBottom: '1px solid transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',
        
        '@media(max-width: 520px)':{
            flexDirection: 'column',
            alignItems: 'center',
        }

    },
    footerLink: {
        display: 'block',
        color: 'rgb(219 0 27)',
        textDecoration: 'none',
        fontWeight: 500,
        textTransform: 'none',
        paddingRight: 16,
        marginBottom: 8,
        '&:hover': {
            borderBottom: '1px solid rgb(219 0 27)'
        },

        '@media(max-width: 520px)':{
            marginBottom: 4
        }
    },
    links:{
        '@media(max-width: 520px)':{
            order: 1,
            alignSelf: 'flex-start'
        }
    },
    copyright:{
        color: 'rgba(0,0,0,.28)',
        '@media(max-width: 520px)':{
            order: 2
        }
    },
}))