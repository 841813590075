export const filterReducer = (state, action) => {

    switch (action.type) {
        case 'FETCH_AREA':
            return {
                ...state,
                area: action.payload
            };

        case 'CLEAR_REGION':
            return {
                ...state,
                regions: []
            };

        case 'FETCH_REGIONS':
            return {
                ...state,
                regions: action.payload
            };

        case 'CLEAR_OMSU':
            return {
                ...state,
                omsu: []
            };

        case 'FETCH_OMSU':
            return {
                ...state,
                omsu: action.payload
            };

        case 'FETCH_VOTE_TYPE':
            return {
                ...state,
                vote_type: action.payload
            };

        case 'FETCH_STATUS_CARD':
            return {
                ...state,
                status_card: action.payload
            };

        case 'FETCH_UIK':
            return {
                ...state,
                uik: action.payload
            };

        case 'FETCH_STATUS_VOLUNTEER':
            return {
                ...state,
                status_volunteer: action.payload
            };

        default:
            return state;
    }
};
