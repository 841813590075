import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    volunteers: {
        paddingBottom: 50
    },
    wrapHeader: {
        paddingTop: 33,
    },
    headers: {
        display: 'flex',
        marginBottom: 42,
    },    
    outlineImportButton: {
        cursor: 'pointer',
        width: 147,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        fontSize: 14,
        marginLeft: 'auto',
        transition: 'all .3s',        
        background: '#fff',
        border: '1px solid #00A4D8',
        color: '#00A4D8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
            filter: 'brightness(97%)',
        },

        '@media(max-width: 550px)':{
            width:'auto'
        }
    },
    importButton:{
        width: 185,
        cursor: 'pointer',
        height: 45,
        textTransform: 'none',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0,164,216,.1)',
        fontSize: 14,
        color: '#00A4D8',
        transition: 'all .3s',

        '&:hover': {
            background: 'rgba(0,164,216,.15)',
        },

        '& > div':{    
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500
        },
        
        '& svg':{    
            marginRight: 8
        },

        '@media(max-width: 550px)':{
            width:'auto',
            minWidth: 57,
            '& svg':{    
                marginRight: 8
            },
        }
    },
    blueButtonsWrapper:{
        display: 'flex  '
    },
    vp_headingPage: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: 36,
        lineHeight: '43px',
        color: theme.black,

        '@media(max-width: 550px)':{fontSize: 22}
    },
    buttonFilter: {
        width: 147,
        height: 45,
        fontFamily: 'Rubik',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: 6,
        background: '#00A4D8',
        border: '1px solid #00A4D8',
        fontSize: 14,
        color: '#fff',
        transition: 'all .3s',
        marginRight: 0,
        marginLeft: 8,

        '&:hover': {
            background: '#00B8F0',
        },

        '@media(max-width: 7700px)':{width: 'auto'}
    },
    buttonFilterClose: {
        background: '#fff',
        border: '1px solid #00A4D8',
        color: '#00A4D8',

        '&:hover': {
            background: '#FBFBFB',
        }
    },
    wrapButtonBlue: {
        margin: '0 0 0 auto'
    },
    buttonBlue: {
        width: 185,
        height: 45,
        textTransform: 'none',
        borderRadius: 6,
        background: 'rgba(0,164,216,.1)',
        fontSize: 14,
        color: '#00A4D8',
        transition: 'all .3s',

        '&:hover': {
            background: 'rgba(0,164,216,.15)',
        },

        '@media(max-width: 7700px)':{width: 'auto'}
    },
}));