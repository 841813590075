import React, { useReducer } from 'react';
import { ScriptContext } from './ScriptsContext';
import { scriptReducer } from './ScriptsContextReducer';
import instance from '../../API/instance';

const ScriptContextState = ({ children }) => {
    const initialState = {
        citizenTypesOptions: []
    };

    const [state, dispatch] = useReducer(scriptReducer, initialState);

    const fetchCitizenTypes = async () => {
        try {
            let res = await instance.get('/api/citizen_types');
            dispatch({ type: 'FETCH_CITIZEN_TYPES', payload: res.data })
        } catch (err) {
            console.log('ОШИБКА', err);
        }
    };

    return (
        <ScriptContext.Provider value={{
            citizenTypesOptions: state.citizenTypesOptions,
            fetchCitizenTypes
        }}>
            {children}
        </ScriptContext.Provider>
    )
};

export default ScriptContextState;