import React from 'react';

const Blocked = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8H17C17.2652 8 17.5196 8.10536 17.7071 8.29289C17.8946 8.48043 18 8.73478 18 9V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8H2V7C2 6.08075 2.18106 5.1705 2.53284 4.32122C2.88463 3.47194 3.40024 2.70026 4.05025 2.05025C4.70026 1.40024 5.47194 0.884626 6.32122 0.532843C7.17049 0.18106 8.08075 0 9 0C9.91925 0 10.8295 0.18106 11.6788 0.532843C12.5281 0.884626 13.2997 1.40024 13.9497 2.05025C14.5998 2.70026 15.1154 3.47194 15.4672 4.32122C15.8189 5.1705 16 6.08075 16 7V8ZM14 8V7C14 5.67392 13.4732 4.40215 12.5355 3.46447C11.5979 2.52678 10.3261 2 9 2C7.67392 2 6.40215 2.52678 5.46447 3.46447C4.52678 4.40215 4 5.67392 4 7V8H14ZM8 12V16H10V12H8Z" fill="#E41D37"/>
        </svg>
    )
};

export default Blocked