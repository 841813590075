import React                            from 'react';
import Table                            from '@material-ui/core/Table';
import TableBody                        from '@material-ui/core/TableBody';
import TableCell                        from '@material-ui/core/TableCell';
import TableContainer                   from '@material-ui/core/TableContainer';
import TableHead                        from '@material-ui/core/TableHead';
import TableRow                         from '@material-ui/core/TableRow';
import Paper                            from '@material-ui/core/Paper';
import TablePagination                  from '../../Components/TablePagination/TablePagination';
import CircularProgress                 from '@material-ui/core/CircularProgress';
import { useStyles }                    from './style';

const TablesAll = ({ nameThead, load, dataTbody, helperTdrender, handleChangePagination, page, selectPage, handleChangeSelectPagination, pushToModal, otherFunctionOpenModal = null, error }) => {
    const classes = useStyles();

    const handleRowClick = (id) => {
        pushToModal(id)
    };

    return (
        <div>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {
                                nameThead.map((cell, indx) => (
                                    <TableCell className={classes.vp_th} align="left" key={indx}>
                                      {cell}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>

                    {
                        load ?
                            <TableBody>
                                <TableRow>
                                    <TableCell align={'center'} colSpan={nameThead.length}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            </TableBody> :
                            <TableBody>
                                {
                                    dataTbody && dataTbody.entries.map(row => {
                                        return (
                                            <TableRow
                                                style={{cursor: 'pointer'}}
                                                key={row.id}
                                                className={classes.vp_row}
                                                onClick={() => otherFunctionOpenModal ? otherFunctionOpenModal(row.id) : handleRowClick(row.id)}
                                            >
                                                {
                                                    helperTdrender(row).map((cell, ind) => (
                                                        <TableCell key={row.id + (ind+1)} className={classes.vp_td}>
                                                            {cell}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                    }
                </Table>
            </TableContainer>

            {
                !load && error
                ? <div className={classes.noData}>
                        Ошибка загрузки данных <br/>
                        <span style={{color: 'red'}}>{error}</span>
                    </div>
                : null
            }

            {
               dataTbody && dataTbody.total_pages > 1 && dataTbody.entries.length ?
                <TablePagination
                    count={dataTbody.total_pages}
                    handleChangePagination={handleChangePagination}
                    total={dataTbody.total_entries}
                    page={page}
                    selectPage={selectPage}
                    handleChangeSelectPagination={handleChangeSelectPagination}
                /> : !load && dataTbody && !dataTbody.entries.length ?
                <div className={classes.noData}>Данных не найдено</div> : null
            }
        </div>
    );
};

export default React.memo(TablesAll);
