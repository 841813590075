import React, { useLayoutEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthPage from './Pages/AuthPage/AuthPage'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/styles';
import AdminPage from './Pages/AdminPage/AdminPage';
import { ManualProvider } from './Context/ManualContext/ManualProvider';
import FilterContextState from './Context/FilterContext/FilterContextState';
import { useAuthActions } from './Context/AdminAuthContext/AdminAuthProvider';
import ScriptContextState from './Context/ScriptsContext/ScriptsContextState';
import { Disconnected } from 'Pages/Disconnected/Disconnected';

function App() {
  
  const { getUserInfo } = useAuthActions();

  useLayoutEffect(() => {
    getUserInfo()
      /* eslint-disable-next-line */
  },[]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#004B92',
      },
      secondary: {
        main: '#DB001B',
      },
      disabled: {
        main: '#AAA9A9'
      },
      default: {
        main: '#00A4D8' // обчный цвет кнопок
      },
    },
    primary: '#004B92',
    secondary: '#DB001B',
    black: '#191A1D',
    darkGray: '#8A929D',
    brightBlue: '#00A4D8', // rgb(0, 164, 216)
    disabled: '#AAA9A9',
    green: '#53BB63',
    purple: '#515990',
    orange: '#E4881D',
    red: '#E41D37',
  });

  const productionPrefix = createGenerateClassName({
    productionPrefix: 'ONF',
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider generateClassName={productionPrefix}>
        <FilterContextState>
          <ManualProvider>
            <ScriptContextState>
              <div className="App">
                {/* <a download href={'/instruction.pdf'}>Скачать файл</a> */}
                <Switch>
                  <Route path='/disconnected'>
                    <Disconnected />
                  </Route>
                  <Route exact path={'/'}><Redirect to={'/admin/citizens'}/></Route>
                  <Route path={['/admin_login', '/admin_reset']} component={AuthPage} />
                  <Route path={'/admin'} component={AdminPage} />
                </Switch>
              </div>
            </ScriptContextState>
          </ManualProvider>
        </FilterContextState>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
