import React from 'react'

function RemoveIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1666 3.99984H17.3333V5.6665H15.6666V16.4998C15.6666 16.7209 15.5788 16.9328 15.4225 17.0891C15.2663 17.2454 15.0543 17.3332 14.8333 17.3332H3.16663C2.94561 17.3332 2.73365 17.2454 2.57737 17.0891C2.42109 16.9328 2.33329 16.7209 2.33329 16.4998V5.6665H0.666626V3.99984H4.83329V1.49984C4.83329 1.27882 4.92109 1.06686 5.07737 0.910582C5.23365 0.754301 5.44561 0.666504 5.66663 0.666504H12.3333C12.5543 0.666504 12.7663 0.754301 12.9225 0.910582C13.0788 1.06686 13.1666 1.27882 13.1666 1.49984V3.99984ZM6.49996 8.1665V13.1665H8.16663V8.1665H6.49996ZM9.83329 8.1665V13.1665H11.5V8.1665H9.83329ZM6.49996 2.33317V3.99984H11.5V2.33317H6.49996Z" fill="#00A4D8"/>
        </svg>
    )
}

export default RemoveIcon;