

import React from 'react'
import { IconButton, List, ListItem, ListItemText, SwipeableDrawer } from '@material-ui/core'
import clsx from 'clsx'
import { toRole } from 'utils/toRole'
import { useState } from 'react'
import AdminStar from 'css/svg/AdminStar'
import { Divider } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import { Drawer } from '@material-ui/core'

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
};
const anchor = 'right'

export const MobileNavbar = ({
    classes,links,other,
    volunteer,fio,logout
}) => {

    const [open, setOpen] = useState(false)
    const history = useHistory()

    const toggleDrawer = (anchor, open) => (event) => {    
        setOpen(open)
      };

    const renderLinks = () => {
        switch(toRole(other.roles)) {
            case 'admin':
                return links;

            case 'volunteer':
                return links.filter(link => link.volunteerViews);

            case 'curator':
                return links.filter(link => link.curatorViews);

            default:
                return links;
        }
    };

    const list = (anchor) => (
        <div
          className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
            {renderLinks().map((item, index) => (
              <ListItem onClick={() => history.push(item.path)} button key={item.title}>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </div>
      );

    return (
        <div className={classes.mobileNavbar}  >
            <div className={classes.mobileFio}>
                {`${volunteer ? capitalize(volunteer.name) : ''} ${volunteer ? capitalize(volunteer.surname) : ''} ${!volunteer ? fio || 'Нет фио' : ''}`}

                <div className={`${classes.vpRole} ${other.roles.length > 1 ? classes.cursorPointer : ''}`}>
                    <span className={classes.containerRole} >
                        {other.roles[0].name}
                        {other.roles[0].system_name === 'admin' ? <span className={classes.wrapIcon}><AdminStar /> </span>: null }
                    </span>
                </div>  
            </div>

        <React.Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)}>
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer
                anchor={anchor}
                open={open}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
            >
                {list(anchor)}

                <Divider />

                <ListItem onClick={logout} button key={'выйти'}>
                    <ListItemText primary={'Выйти'} />
                </ListItem>
            </SwipeableDrawer>
            </React.Fragment>
            
        </div>
    )
}
