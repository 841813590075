import React                    from 'react';
import TextField                from '@material-ui/core/TextField';
import MaskedInput              from 'react-text-mask';
import { useStyles }            from './style';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            mask={props.mask}
            placeholder={props.placeholdermask}
            showMask
        />
    );
}

const FieldFilter = ({label, multiline = false, onBlur, name, value, type = 'text', maskInput = null, placeholdermask = null, placeholder = null, disabled, redBorder, noLabel, maxlength,rounded}) => {
    const classes = useStyles({redBorder, noLabel, rounded});

    let inputProps = {
        disableUnderline: true, //пропадает нижняя линия
        margin: 'dense', //становиться более низким
        classes: {
            root: classes.vp_filedRoot,
            focused: classes.vp_focused,
            disabled: classes.vp_disabled
        }
    };

   if(maskInput) {
        inputProps = {
            ...inputProps,
            inputComponent: TextMaskCustom,
        }
    }

    return (
        <>
            <TextField
                multiline={multiline}
                disabled={disabled}
                id={name}
                type={type}
                defaultValue={value}
                onBlur={onBlur}
                name={name}
                margin="dense"
                label={label}
                variant="filled"
                InputProps={inputProps}
                placeholder={placeholder}
                inputProps={{
                    maxLength: maxlength ? maxlength : null,
                    mask:  maskInput,
                    placeholdermask: placeholdermask
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.vp_labelRoot,
                        shrink: classes.vp_shrink
                    }
                }}
                classes={{
                    root: classes.mainRoot
                }}
            />
        </>
    )
};

export default FieldFilter;