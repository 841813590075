import React from 'react'
import { FormControlLabel, Typography, Fade } from '@material-ui/core'
import StyledCheckbox from '../../../Common/Checkbox'

function ReasonSelect({ success, list, handleResultChange, open, isEnd, chosen, setChosen }) {

    
    const onReasonChange = (id) => {
        setChosen(id)
        handleResultChange(id)
    }

    return (
        <>
            <Fade timeout={800} in={open}>
                <div style={{
                    height: open ? 'auto' : '0',
                    margin: open ? '22px 0 32px' : '0'

                }} >
                    <Typography style={{ fontWeight: '500', fontSize: '20px' }} >
                        {success
                            ? 'Выберите тип голосования'
                            : 'Выберите причину'
                        }
                    </Typography>
                    <form>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {list && (
                                list.map((result, ind) =>
                                    <FormControlLabel
                                        key={ind}
                                        control={
                                            <StyledCheckbox
                                                disabled={isEnd}
                                                onChange={() => onReasonChange(result.id)}
                                                name={result.name}
                                                checked={chosen === result.id}
                                                color="primary"
                                            />
                                        }
                                        label={result.name}
                                    />)
                            )}
                        </div>
                    </form>
                </div>
            </Fade>
        </>
    )
}

export default ReasonSelect
