import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/fonts/stylesheet.css';
import './css/Main.css';
import { BrowserRouter } from 'react-router-dom'
import { AdminAuthProvider } from './Context/AdminAuthContext/AdminAuthProvider';

ReactDOM.render(
    <BrowserRouter>
      <AdminAuthProvider>
        <App />
        </AdminAuthProvider>
    </BrowserRouter>,
  document.getElementById('root')
);
