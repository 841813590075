import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    vpAutocompleteRoot: {
        '& .MuiFormControl-root': {
            margin: 0
        }
    },
    vp_filedRoot: {
        background: props => props.gray ? 'rgba(76,82,123,.1)' : '#fff!important',
        border: '1px solid #e8e8e8',
        borderRadius: 5,
        cursor: props => props.readonly ? 'pointer' : 'text',
        paddingTop: props => (props.readonly || props.noLabel) ? '0!important' : null,
        paddingLeft: props => (props.readonly || props.noLabel) ? '0!important' : null,

        '&:hover': {
            background: props => props.gray ? 'rgba(76,82,123,.1)!important' : '#fff',
        },

        '& input': {
            boxSizing: props => (props.readonly || props.noLabel) && 'border-box',
            height: props => (props.readonly || props.noLabel) && 47,
            cursor: props => props.readonly ? 'pointer' : 'text',
            paddingLeft: props => (props.readonly || props.noLabel) && '12px!important',
            paddingTop: props => (props.readonly && !props.noLabel) && '23px!important',

            '&::selection': {
                background: 'transparent'
            },

             '&::-webkit-input-placeholder': {
                 color: '#333',
                 opacity: 1,
                 position: 'relative',
                 top: 1,
             },

            '&:focus::-webkit-input-placeholder': {
                color: 'lightgray',
            },

            '&::-moz-placeholder': {
                color: '#333',
                opacity: 1,
                position: 'relative',
                top: 1,
            },

            '&:focus::-moz-placeholder': {
                color: 'lightgray',
            },

            '&:-moz-placeholder': {
                color: '#333',
                opacity: 1,
                position: 'relative',
                top: 1,
            },

            '&:focus:-moz-placeholder': {
                color: 'lightgray',
            },

            '&:-ms-input-placeholder': {
                color: '#333',
                opacity: 1,
                position: 'relative',
                top: 1,
            },

            '&:focus:-ms-input-placeholder': {
                color: 'lightgray',
            },
        }
    },
    vp_labelRootTwoLine: {
        color: '#8A929D',
        fontSize: 14,
        marginTop: -5,
        width: '85%'
    },
    vp_labelRoot: {
        color: '#8A929D!important',
        fontSize: 16,
        paddingTop: 1
    },
    vp_shrink: {
        color: '#8A929D!important'
    },
    vp_focused: {
        backgroundColor: props => props.gray ? 'rgba(76,82,123,.1)!important' : '#fff!important',
        border: props => props.gray ? '1px solid rgba(76,82,123,.1)' : `1px solid #8A929D`
    },
    vp_popper: {
        '& li': {
            color: 'tomato',
            fontSize: 15,
            textShadow: 'none',
            transition: 'all .25s',

            '&:hover': {
                background: '#BDCFFD'
            }
        }
    },
    vp_errorFiled: {
        border: '1px solid tomato'
    },
    wait: {
        height: 50,
        background: 'rgba(76, 82, 123, 0.1)',
        borderRadius: 5,
        paddingLeft: 12,
        display: 'flex',
        alignItems: 'center',
        color: 'gray',
        fontSize: 15,

        '& span': {
            color: 'gray',
            fontSize: 15,
            marginLeft: 8
        }
    },
    rootCheckbox: {
        position: 'static',

        '&:before': {
            content: "''",
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        }
    },
    vp_listbox: {
        '& li': {
            position: 'relative'
        }
    },
}));