import React, { useState } from 'react'
import { InputBase, Typography } from '@material-ui/core'
import { addCitizenComment } from '../../API/Citizens'
import { Accordion as ExpansionPanel,  AccordionDetails  as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toDate } from '../../utils/toDate';

function Commentary({ c, comments, getFullInfo, id }) {

    const [commentText, setCommentText] = useState('');

    const handleClick = async (e) => {
        if (e.keyCode === 13) {
            try {
                await addCitizenComment(id, commentText);
                setCommentText('');
                getFullInfo()
            } catch (err) {
                throw err;
            }
        }
    };

    return (
        <section className={c.commentarySection}>
            <div style={{ padding: '0 40px' }}>
                <ExpansionPanel>

                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ paddingLeft: '0' }}
                    >
                        <Typography className={c.subTitle} style={{ margin: '0' }} >
                            Комментарии
                        </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div style={{
                            display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 40px',
                            position: 'relative', left: '-56px', width: 'calc(100% + 32px)'
                        }}>
                            <InputBase
                                placeholder='Введите комментарий...'
                                inputProps={{ 'aria-label': 'naked' }}
                                value={commentText}
                                multiline
                                fullWidth
                                onKeyDown={handleClick}
                                style={{ paddingRight: '10px' }}
                                onChange={(e) => setCommentText(e.target.value)}
                            />
                        </div>
                        <div style={{ width: 'calc(100% + 112px)', height: '1px', background: '#EDEDED', position: 'relative', left: '-56px' }} ></div>
                        <div className={c.history} style={{ marginTop: '32px' }}>
                            {comments.length
                                ? comments.map((hist, ind) => (
                                    <div className={c.itemContainer} key={ind}>

                                        <div className={c.userInfo}>

                                            <div>
                                                {`${hist.user ? hist.user.fio || 'Нет имени' : '...'}`}
                                            </div>
                                            <div className={c.fieldTitle}>
                                                {toDate(hist.inserted_at)}
                                            </div>

                                        </div>
                                        {/* Тут палочка */}
                                        <div className={c.dotContainer} >
                                            <div className={c.dot}>
                                            </div>
                                            {ind === comments.length - 1
                                                ? null
                                                :
                                                <div className={c.dotline}></div>
                                            }
                                        </div>
                                        {/* Палочка */}

                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                            <span style={{ wordBreak: 'break-word', maxWidth: '560px' }} >{hist.text}</span>
                                        </div>

                                    </div>
                                ))
                                : <Typography style={{ textAlign: 'center' }} >Нет данных для отображения</Typography>}

                        </div>

                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </section>
    )
}

export default Commentary
