import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom'
import { useStyles } from './styles';
import Login from '../../Components/Login/Login';

const AuthPage = () => {

    const c = useStyles()

    return (
        <>
            <div></div>
            <Container maxWidth='lg' className={c.root}>
                <Grid
                    container
                    className={c.root}
                    alignItems="center"
                    justify="center"
                    direction='column'
                >

                    <div className={c.main}>
                        {/*  <img src={logo} className={c.image} width='131' height='176' alt={"logo"} /> */}

                        <Switch>
                            <Route path='/admin_login' component={Login} />
                        </Switch>
                    </div>

                </Grid>
            </Container>
            
        </>
    )
};

export default AuthPage;