export const scriptReducer = (state, action) => {

    switch (action.type) {
        case 'FETCH_CITIZEN_TYPES':
            return {
                ...state,
                citizenTypesOptions: action.payload
            };

        default:
            return state;
    }
};
