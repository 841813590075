import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Blocked from '../../css/svg/Blocked';
import Active from '../../css/svg/Active';
import Moderation from '../../css/svg/Moderation';
import { changeStatus } from '../../API/Volonteer';
import { useStyles } from './style'

function CardTitleVoluenteer({ data, dataTbody, setDataTbody, setData }) {

    const classes = useStyles();

    const helpStatus = {
        1: { title: 'Активен', color: '#53BB63' },
        2: { title: 'На модерации', color: '#515990' },
        //3: {title: 'Отклонен', color: '#AAA9A9'},
        3: { title: 'Заблокирован', color: '#E41D37' },
        4: { title: 'Заблокирован', color: '#E41D37' },
    };

    const editStatus = (newStatus) => async () => { // поправить поставить try-catch когда заработает запрос на активный

        setData({ // реактивно меняем статус и соответственно кнопки в карточке
            ...data,
            fullInfo: {
                ...data.fullInfo,
                status: {
                    ...data.fullInfo.status,
                    id: newStatus
                }
            }
        });

        //реактивно  меняем статус в таблице
        const newEntries = [...dataTbody.entries];
        const findVolonter = newEntries.find(vol => vol.id === data.fullInfo.id);
        findVolonter.status.name = helpStatus[newStatus].title;
        findVolonter.status.id = newStatus;

        setDataTbody({
            ...dataTbody,
            entries: newEntries,
        });

        // отправляем запрос
        const result = await changeStatus(data.fullInfo.id, newStatus);
    };

    const buttonRender = (idStatus) => {
        switch (idStatus) {
            case 1:
                return (
                    <IconButton onClick={editStatus(4)}>
                        <Blocked />
                    </IconButton>
                );
            case 2:
                return (
                    <>
                        <IconButton onClick={editStatus(1)}>
                            <Active />
                        </IconButton>

                        <IconButton onClick={editStatus(4)}>
                            <Blocked />
                        </IconButton>
                    </>
                );

            case 3:
                return (
                    <IconButton onClick={editStatus(2)}>
                        <Moderation />
                    </IconButton>
                );

            case 4:
                return (
                    <IconButton onClick={editStatus(2)}>
                        <Moderation />
                    </IconButton>
                )

            default:
                return (
                    <IconButton onClick={editStatus(4)}>
                        <Blocked />
                    </IconButton>
                )
        }
    };

    return (
        <div className={classes.titleRow}>
            <div className={classes.title}>
                Карточка оператора № {data.fullInfo.id}
            </div>

            <div className={classes.wrapRightBlock}>
                <p className={classes.wrapStatusTitle}>Статус:
                    <span
                        className={classes.statusTitle}
                        style={{
                            color: helpStatus[data.fullInfo.status.id].color,
                            fontWeight: 'bold'
                        }}
                    > {helpStatus[data.fullInfo.status.id].title} </span>
                </p>

                <div className={classes.title}>
                    {`${data.fullInfo.surname ? data.fullInfo.surname : ''} ${data.fullInfo.name ? data.fullInfo.name[0].toUpperCase() + '.' : ''} ${data.fullInfo.patronymic ? data.fullInfo.patronymic[0].toUpperCase() + '.' : ''}`}
                </div>

                <div className={classes.buttonStatusWrapper}>
                    {buttonRender(data.fullInfo.status.id)}
                </div>
            </div>
        </div>
    )
}

export default CardTitleVoluenteer;
