import React from 'react';
import { Redirect } from 'react-router-dom';
import './loader.css';

const LoaderForEnterAdminPage = () => {

    if(!localStorage.getItem('token')) {
        return <Redirect to={"/admin_login"} />
    }

    return (
        <div className='wrapLoader'>
            <div className="lds-default">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
};

export default LoaderForEnterAdminPage;