import React from 'react'
import { Typography } from '@material-ui/core'
import { toDate } from '../../utils/toDate'

function History({ c, fullInfo, history, voteType }) {

    return (
        <div className={c.history} style={{ marginTop: '32px' }}>

            <Typography className={c.subTitle} style={{ marginTop: 0 }} >
                Ход решения
            </Typography>

            {history
                ? history.map((hist, ind) => (
                    <div className={c.itemContainer} key={ind}>

                        <div className={c.userInfo}>

                            <div>
                                {`${hist.volunteer ? hist.volunteer.surname || 'Нет имени' : '...'} ${hist.volunteer ? hist.volunteer.name || '' : ''} ${hist.volunteer.patronymic ? hist.volunteer.patronymic[0] : ''}`}
                            </div>
                            <div className={c.fieldTitle}>
                                {toDate(hist.inserted_at)}
                            </div>

                        </div>
                        {/* Тут палочка */}
                        <div className={c.dotContainer} >
                            <div className={c.dot}>
                            </div>
                            {ind === history.length - 1
                                ? null
                                :
                                <div className={c.dotline}></div>
                            }
                        </div>
                        {/* Палочка */}

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>{hist.type && (
                                <>
                                    <span style={{ fontWeight: 'bold' }}>Событие: &nbsp;</span>
                                    <span>{hist.type.name}</span>
                                </>
                            )}</span>
                            <span>{hist.call_result && (
                                <>
                                    <span style={{ fontWeight: 'bold' }}>Результат: &nbsp;</span>
                                    <span>{hist.call_result.name}</span>
                                </>
                            )}</span>
                            <span>{hist.fail_reason && (
                                <>
                                    <span style={{ fontWeight: 'bold' }}>Причина: &nbsp;</span>
                                    <span>{hist.fail_reason.name}</span>
                                </>
                            )}</span>
                            <span>{hist.call_back_reason && (
                                <>
                                    <span style={{ fontWeight: 'bold' }}>Причина: &nbsp;</span>
                                    <span>{hist.call_back_reason.name}</span>
                                </>
                            )}</span>
                        </div>

                    </div>
                ))
                : null}

        </div>
    )
}


export default History
