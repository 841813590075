import React, {
    useState, useEffect,
    useContext
} from 'react';
import {
    useParams, useHistory,
    useLocation
} from 'react-router-dom';
import queryString from 'query-string';
import {
    postVolonteerList,
    fetchStatusVolunteer,
    fetchFileCsvVolunteers
} from '../../API/Volonteer';
import Fade from '@material-ui/core/Fade';
import TablesAll from '../../Components/TablesAll/TablesAll';
import DialogAdmin from '../../Components/DialogAdmin/DialogAdmin';
import FilterBlockVolunteers from '../../Components/FilterBlocks/FilterBlockVolunteers';
import VolonteerCard from '../../Components/VolonteerCard/VolonteerCard';
import Button from '@material-ui/core/Button';
import FilterIcon from '../../css/svg/FilterIcon';
import AnimateLoader from '../../css/svg/AnimateLoader';
import Unload from '../../css/svg/Unload';
import { FilterContext } from '../../Context/FilterContext/FilterContext';
import { useStyles } from './style';
import { useAuthState } from '../../Context/AdminAuthContext/AdminAuthProvider';
import { toRoleCurator } from '../../utils/toRoleCurator';
import { toRole } from '../../utils/toRole';
import { useMediaQuery } from '@material-ui/core';
import { toast } from 'react-toastify';
import instance from 'API/instance';
import { errorToast } from 'utils/ErrorToast';
import { downloadAsCsv, downloadAsFile } from 'utils/fileManipulations';

const Volunteers = () => {
    const classes = useStyles();
    const { fetchOmsu, fetchRegions, omsuOptions, areaOptions, regionsOptions, volunteerStatusOptions, fetchStatusVolunteer } = useContext(FilterContext);
    const { volunteer, ...other } = useAuthState();

    const handleImport = async (e, endpoint, withFallback) => {
        try {
            toast.info("Загружаем файл");
            let file = e.target.files[0]
            let body = new FormData()
            body.append('file', file)
            const { data } = await instance.post('/api/volunteers/' + endpoint, body) // import
            if (withFallback) {

                downloadAsCsv(data)
            } else {
                downloadAsFile(data.file)
            }
            toast.info('Успешно импортировано')
        } catch (e) {
            console.error(e)
            errorToast(`${e?.response?.data || 'Ошибка'}`)
        }
    }

    const history = useHistory();
    const location = useLocation();
    const { idPage = "1" } = useParams();

    const [openDialog, setOpenDialog] = useState(false); //поменять на false
    const [idCard, setIdCard] = useState(null);
    const [error, setError] = useState(false);

    const [load, setLoad] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [dataTbody, setDataTbody] = useState(null);
    const [openCollapse, setOpenCollapse] = useState(true);
    const [nav, setNav] = useState({
        page: idPage ? +idPage : 1,
        selectPage: 10,
    });
    const [filterState, setFilterState] = useState({ //coстояние для селектов и инпутов филтра
        omsu: null,
        region: null,
        status: null,
        phone: '',
        fio: '',
        ext_id: ''
    });

    const nameThead = ['id', 'Регион', 'УИК', 'ИНН', 'КПП', 'ФИО', 'Организация', 'Номер телефона', 'Статус активности', 'Кол-во выполненных заявок'];

    useEffect(() => { //установка значений в селект при первой загрузке с квери параметрами
        setFilterState({
            ...filterState,
            region: helperStateFilter("filters[region_id]", regionsOptions),
            phone: queryString.parse(location.search)["filters[phone]"] || '',
            fio: queryString.parse(location.search)["filters[fio]"] || '',
            status: volunteerStatusOptions.length ? helperStateFilter("filters[status]", volunteerStatusOptions) : null,
            ext_id: queryString.parse(location.search)["filters[ext_id]"] || '',
        });

        if (toRoleCurator(other.roles) === 'curator_region') { // если у нас региональный куратор, то загружаем его омсу
            omsuLoad(volunteer.region.id)
        }
    }, []);

    useEffect(() => { // загрузка статусов
        if (!volunteerStatusOptions.length) {
            fetchStatusVolunteer();
        }
    }, [volunteerStatusOptions]);

    useEffect(() => { // первая отрисовка таблицы, и при изменении пагинации и фильтров
        setError(false);
        setLoad(true);

        let queryStringFilters = queryString.parse(location.search);

        setDataTbody(null);

        postVolonteerList(idPage, nav.selectPage, queryStringFilters).then((res) => {
            setDataTbody(res);
            setLoad(false);
        }).catch(e => {
            setError(e);
            setLoad(false);
        })
    }, [idPage, nav.selectPage, location.search]);

    useEffect(() => { // при изменении округа меняем omsu
        if (filterState.region) {
            fetchOmsu({ "filters[region_id]": filterState.region.id }).then((res) => {
                const omsuFind = helperStateFilter("filters[omsu_id]", res);
                setFilterState({
                    ...filterState,
                    omsu: omsuFind ? omsuFind : null
                })
            })
        }
    }, [filterState.region]);

    const omsuLoad = (id) => {
        fetchOmsu({ "filters[region_id]": id });
    };

    const helperStateFilter = (nameFilter, filterOptions) => { //при наличии query-параметра находим в списке селекта нужный элемент
        const objFilters = queryString.parse(location.search);
        if (Object.keys(objFilters).length && nameFilter in objFilters) {
            return filterOptions.find(elem => elem.id.toString() === objFilters[nameFilter].toString());
        }
        return null;
    };

    const helperTdrender = (data) => {
        let name = `${data.surname ? data.surname : ''} ${data.name ? data.name : ''} ${data.patronymic ? data.patronymic : ''}`;
        let organization = data.organization && Object.keys(data.organization).length ? data.organization.title : '-';

        return [data.id, data?.region?.name || '-', data?.uik_numbers?.join(',\n') || '-', data?.inns?.join(',\n') || '-', data?.kpps?.join(',\n') || '-', name, organization, data.phone || '-', data.status.name, 'Тут будет кол-во заявок'];
    };

    const handleChangePagination = (event, value) => { //обработчик для пагинации
        const url = location.search
            ? `/admin/volunteers/${value}${location.search}`
            : `/admin/volunteers/${value}`;

        history.push(url);

        setNav({
            ...nav,
            page: value
        });
    };

    const handleChangeSelectPagination = (event) => { //обработчик селекта пагинации
        const url = location.search
            ? `/admin/volunteers${location.search}`
            : `/admin/volunteers`;

        history.push(url);

        setNav({
            ...nav,
            selectPage: event.target.value,
            page: 1,
        });
    };

    const handleValueSelect = (name) => (event, val) => { //обработчик для селектов фильтра
        setFilterState({
            ...filterState,
            [name]: val,
        })
    };

    const handleBlur = (event) => { //обработчки на инпуты в фильтрах
        setFilterState({
            ...filterState,
            [event.target.name]: event.target.value
        })
    };

    const paramsData = () => {
        let paramsObject = {};
        if (filterState.region) paramsObject["filters[region_id]"] = filterState.region.id;
        if (filterState.omsu) paramsObject["filters[omsu_id]"] = filterState.omsu.id;
        if (filterState.phone) paramsObject["filters[phone]"] = filterState.phone;
        if (filterState.fio) paramsObject["filters[fio]"] = filterState.fio;
        if (filterState.status) paramsObject["filters[status]"] = filterState.status.id;
        if (filterState.ext_id) paramsObject["filters[ext_id]"] = +filterState.ext_id;

        return paramsObject;
    };

    const saveAsCsv = async () => { //сохраняем данные в формате csv

        let queryStringFilters = queryString.parse(location.search);

        let formData = new FormData();
        queryStringFilters["filters[region_id]"] && formData.append("filters[region_id]", filterState.region.id);
        queryStringFilters["filters[omsu_id]"] && formData.append("filters[omsu_id]", filterState.omsu.id);
        queryStringFilters["filters[phone]"] && formData.append("filters[phone]", filterState.phone);
        queryStringFilters["filters[fio]"] && formData.append("filters[fio]", filterState.fio);
        queryStringFilters["filters[status]"] && formData.append("filters[status]", filterState.status.id);
        queryStringFilters["filters[ext_id]"] && formData.append("filters[ext_id]", +filterState.ext_id);

        setButtonLoading(true);

        try {
            const { path } = await fetchFileCsvVolunteers(formData);
            const a = document.createElement('a');
            a.href = path;
            a.download = path.split('/').slice(-1);

            a.click()
        } catch (e) {
            console.log('Ошибка загрузки файла', e)
        } finally {
            setButtonLoading(false);
        }
    };

    const resetFilter = () => {
        setFilterState({
            ...filterState,
            region: null,
            omsu: null,
            status: null,
            phone: '',
            fio: '',
            ext_id: ''
        });

        history.push('/admin/volunteers');
    };

    const openCardDialog = (id) => {
        setIdCard(id);
        setOpenDialog(true);
    };

    const isSmallScreen = useMediaQuery('(max-width: 770px)')

    return (
        <Fade timeout={800} in>
            <div className={classes.volunteers}>
                <div className={classes.wrapHeader}>
                    <div className={classes.headers}>
                        <h3 className={classes.vp_headingPage}>Реестр операторов</h3>

                        <div className={classes.wrapButtonBlue}>
                            {
                                toRole(other.roles) === 'admin' &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: 8 }} >
                                        <input type='file' accept='.csv' style={{ position: 'absolute', opacity: 0 }} onChange={(e) => handleImport(e, 'import', true)} id="regImport" />
                                        <label htmlFor="regImport" /* className={classes.wrapButtonBlue} */>
                                            <div
                                                className={classes.outlineImportButton}
                                            >
                                                Регистрация
                                            </div>
                                        </label>
                                    </div>
                                    <div style={{ marginRight: 8 }} >
                                        <input type='file' accept='.csv' style={{ position: 'absolute', opacity: 0 }} onChange={(e) => handleImport(e, 'update_by_csv')} id="changeImport" />
                                        <label htmlFor="changeImport" /* className={classes.wrapButtonBlue} */>
                                            <div
                                                className={classes.outlineImportButton}
                                            >
                                                Замена
                                            </div>
                                        </label>
                                    </div>
                                    <div style={{ marginRight: 8 }} >
                                        <input type='file' accept='.csv' style={{ position: 'absolute', opacity: 0 }} onChange={(e) => handleImport(e, 'block')} id="blockImport" />
                                        <label htmlFor="blockImport" /* className={classes.wrapButtonBlue} */>
                                            <div
                                                className={classes.outlineImportButton}
                                            >
                                                Блокировка
                                            </div>
                                        </label>
                                    </div>
                                    <div className={classes.wrapButtonBlue}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.buttonBlue}
                                            disableElevation
                                            startIcon={isSmallScreen ? null : buttonLoading ? <AnimateLoader /> : <Unload />}
                                            onClick={saveAsCsv}
                                        >
                                            {isSmallScreen ? buttonLoading ? <AnimateLoader /> : <Unload /> : buttonLoading ? 'Подождите...' : 'Выгрузить в .csv'}
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>

                        <Button
                            variant="contained"
                            className={`${classes.buttonFilter} ${!openCollapse ? classes.buttonFilterClose : ''}`}
                            disableElevation
                            endIcon={isSmallScreen ? null : <FilterIcon openCollapse={openCollapse} />}
                            onClick={() => setOpenCollapse(!openCollapse)}
                        >
                            {isSmallScreen ? <FilterIcon openCollapse={openCollapse} /> : ('Фильтры')}
                        </Button>
                    </div>
                </div>

                <FilterBlockVolunteers
                    other={other}
                    regionsOptions={regionsOptions}
                    omsuOptions={omsuOptions}
                    volunteerStatusOptions={volunteerStatusOptions}
                    handleValueSelect={handleValueSelect}
                    handleBlur={handleBlur}
                    openCollapse={openCollapse}
                    filterState={filterState}
                    resetFilter={resetFilter}
                    paramsData={paramsData}
                />

                <TablesAll
                    error={error}
                    nameThead={nameThead}
                    load={load}
                    dataTbody={dataTbody}
                    helperTdrender={helperTdrender}
                    handleChangePagination={handleChangePagination}
                    page={+idPage}
                    selectPage={nav.selectPage}
                    handleChangeSelectPagination={handleChangeSelectPagination}
                    otherFunctionOpenModal={openCardDialog}
                />

                <DialogAdmin
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    widthDialog={978}
                    setIdCard={setIdCard}
                >
                    <VolonteerCard
                        id={idCard}
                        dataTbody={dataTbody}
                        setDataTbody={setDataTbody}
                        areaOptions={areaOptions}
                        regionsOptions={regionsOptions}
                        omsuOptions={omsuOptions}
                        fetchOmsu={fetchOmsu}
                        fetchRegions={fetchRegions}
                    />
                </DialogAdmin>
            </div>
        </Fade>
    )
};

export default Volunteers;